import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useNavigate, useParams } from "react-router-dom";
// import NavigationPrompt from "react-router-navigation-prompt";
// import { Prompt } from "react-router";
import "./CustomerPage.css";
import { images } from "../../Constants";
import {
  CustomerProfile,
  CustomerDetails,
  CustomerActivity,
  ModalApplyBP,
} from "../../Components";
import {
  editCustomerAllDetails,
  getAllCommonFiles,
  getAllFilesType,
  getSelectedCompany,
  getSingleCustomer,
  getUser,
  sendDocumentsEmail,
} from "../../Services";
import { split_string } from "../../Utils";

const CustomerPage = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrormessage] = useState(false);
  const [bp_message, setBp_message] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [allFileTypes, setAllFileTypes] = useState();
  const [allCommonFile, setAllCommonFile] = useState();
  const [reload, setReload] = useState();
  const [toastShow, setToastShow] = useState(false);
  const sessionUser = getUser();
  const [applyBpModal, setApplyBpModal] = useState(false);
  const user_token = sessionUser["token"];
  const header = {
    Authorization: "token " + user_token,
    // "Content-Type": "application/json",
  };
  const customer_id = params["cust_id"];
  const customerFiles = customer?.customer_files ?? [];
  const company = getSelectedCompany();
  const company_id = company ? company[0]["id"] : 0;

  const fetchSingleCustomer = async () => {
    setIsLoading(true);
    const customer = await getSingleCustomer(customer_id, company_id);
    if (customer) {
      setCustomer(customer);
      const allFileT = await getAllFilesType(company_id);
      const allCFile = await getAllCommonFiles(company_id);
      // console.log(allCFile);
      setAllFileTypes(allFileT);
      setAllCommonFile(allCFile);
      setIsLoading(false);
      const job_num = customer && customer.customer_job[0]?.job_number;
      // console.log(job_num);
      setChanges({ ...changes, job_number: job_num });
    } else {
      console.log("errorrr");
      navigate("/page-not-found");
    }
  };
  useEffect(() => {
    fetchSingleCustomer();
  }, [reload]);
  const [changes, setChanges] = useState({
    customer_id: params["cust_id"],
    company: company_id,
    user: sessionUser["id"],
  });
  const handleChange = (e, detail) => {
    // console.log("change", e.target, detail);
    setChanges({
      ...changes,
      [detail]: e.target.value,
    });
  };
  const handleDropdownChange = (detail, user_id) => {
    // console.log("change", e.target, detail);
    setChanges({
      ...changes,
      [split_string(detail.replace("_id", ""))]: user_id,
    });
  };
  const handleCheckboxChange = (detail, checked) => {
    console.log("change", detail, checked);
    setChanges({
      ...changes,
      [detail]: checked,
    });
  };
  const is_processed =
    customer && customer.customer_job.length > 0
      ? customer.customer_job[0]["is_processed"]
      : false;
  const is_finalized =
    customer && customer.customer_job.length > 0
      ? customer.customer_job[0]["finalized_by_id"]
      : false;
  const finalizeData = async () => {
    setIsSaving(true);
    const saved = await editCustomerAllDetails(changes, "True");
    const job_num = customer && customer.customer_job[0].job_number;
    console.log(job_num);
    setChanges({
      customer_id: params["cust_id"],
      company: company_id,
      user: sessionUser["id"],
      job_number: job_num,
    });
    if (saved === 200) {
      setErrormessage("");
      setSuccessMessage("All customer info are finalized.");
    } else {
      setSuccessMessage("");
      setErrormessage("Something went worng. Please try again later!");
    }
    setIsSaving(false);
  };
  const saveChanges = async () => {
    setIsSaving(true);
    const saved = await editCustomerAllDetails(changes, "False");
    const job_num = customer && customer.customer_job[0].job_number;
    setToastShow(true);
    setChanges({
      customer_id: params["cust_id"],
      company: company_id,
      user: sessionUser["id"],
      job_number: job_num,
    });
    if (saved === 200) {
      setToastShow(true);
    } else {
      setSuccessMessage("");
      setErrormessage("Please try again later!");
    }
    setIsSaving(false);
  };
  const applyBuildingPermit = async () => {
    // console.log(customer.customer_job[0].deposit_paid);
    if (customer.customer_job[0].deposit_paid) {
      // console.log("Deposit paid");
      setApplyBpModal(true);
    } else {
      // console.log("deposit not paid");
      // for test only
      // setApplyBpModal(true);
      setBp_message(
        "To apply BP, deposit must be paid by customer. If it's paid please tick 'Deposit Paid' option below."
      );
    }
  };

  const sendDocuments = async () => {
    setIsProcessing(true);
    const job_num = customer && customer.customer_job[0].job_number;

    // const job_id = customer && customer.customer_job[0].id;
    const sent = await sendDocumentsEmail({
      customer_id: customer_id,
      company: company_id,
      user: sessionUser["id"],
      job_id: job_num,
    });
    if (sent === 200) {
      setErrormessage("");
      setSuccessMessage("Processing complete.");
    } else {
      setSuccessMessage("");
      setErrormessage("Please try again later!  ");
    }

    setIsProcessing(false);
    // fetchSingleCustomer();
  };
  setTimeout(() => {
    setSuccessMessage("");
    setErrormessage("");
    setBp_message("");
  }, 10000);
  return (
    <div className="customer__details_indv d-flex flex-column">
      <ToastContainer className="p-3" position="bottom-end">
        <Toast
          onClose={() => setToastShow(false)}
          show={toastShow}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={true}>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto text-success">Changes saved!</strong>
            <small>now</small>
          </Toast.Header>
          <Toast.Body className="text-success">
            Customer data has been saved!!!
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {sessionUser["is_installer"] && (
        <div
          className=" d-flex align-self-end p-1 mb-0"
          style={{ color: "red", height: 55 }}
        >
          {Object.keys(changes).length > 4 ? (
            <Toast>
              <Toast.Header closeButton={false}>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">You have changed some data</strong>
                <small>Save it.</small>
              </Toast.Header>
            </Toast>
          ) : null}
          <span style={{ textOverflow: "ellipsis", width: "400px" }}>
            {bp_message}
          </span>
          <button
            className="btn btn-info m-1 text-white"
            title="Click to apply for building permit"
            onClick={applyBuildingPermit}
            disabled={
              (customer && customer?.customer_job[0]?.bp_applied) || isLoading
            }
          >
            {customer?.customer_job[0]?.bp_applied
              ? "BP Done"
              : isSaving
              ? "Sending BP..."
              : "Building Permit"}
          </button>
          <button
            className="btn btn-primary m-1"
            title="Click to save customer's data"
            onClick={saveChanges}
            disabled={Object.keys(changes).length > 4 ? false : true}
          >
            {isSaving ? "Saving..." : "Save Changes"}
          </button>
        </div>
      )}
      {!isLoading ? (
        <div className=" d-flex flex-row">
          <div className=" customer__details_left">
            <CustomerProfile
              customer={customer}
              allFileTypes={allFileTypes}
              commonFiles={allCommonFile}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>
          <div className="customer__details_mid">
            <CustomerDetails
              customer={customer}
              allFileTypes={allFileTypes}
              commonFiles={allCommonFile}
              handleChange={handleChange}
              handleDropdownChange={handleDropdownChange}
              changes={changes}
            />
          </div>
          <div className="customer__details_right">
            <CustomerActivity
              customer={customer}
              allFileTypes={allFileTypes}
              commonFiles={allCommonFile}
              reload={setReload}
              fetchSingleCustomer={fetchSingleCustomer}
              customer_id={customer_id}
            />
          </div>
        </div>
      ) : (
        <div className="loading__img_div">
          {" "}
          <img
            className="loading__img"
            src={images.loadingCircle}
            width="100px"
            height="100px"
          ></img>
          <div>...Loading Details</div>
        </div>
      )}
      {sessionUser["is_installer"] && (
        <div className=" d-flex align-self-end p-2 ">
          {successMessage ? (
            <p className="text-success m-2">{successMessage} </p>
          ) : (
            <p className="text-danger m-2">{errorMessage} </p>
          )}
          {is_finalized ? (
            <Toast
              className="w50"
              style={{ backgroundColor: "#eee", width: 600 }}
            >
              <Toast.Header closeButton={false}>
                {is_processed ? (
                  <strong className="me-auto text-success">
                    Processing complete. All documents has been sent to
                    respective receivers.
                  </strong>
                ) : (
                  "Finalized. Now you can proceed to send email"
                )}
              </Toast.Header>
            </Toast>
          ) : (
            ""
          )}
          <button
            className="btn btn-primary m-1"
            title="Click to finalize customer's data"
            onClick={finalizeData}
            disabled={isLoading}
          >
            {isSaving ? "Saving..." : "Finalize data"}
          </button>
          <button
            className="btn btn-success m-1"
            title="Click to finalize customer's data"
            onClick={sendDocuments}
            disabled={isLoading}
          >
            {isProcessing ? "processing..." : "Proceed"}
          </button>
        </div>
      )}
      <ModalApplyBP
        openModal={applyBpModal}
        ohShow={() => setApplyBpModal(true)}
        onHide={() => setApplyBpModal(false)}
        files={customerFiles}
        customer_id={customer_id}
        customer={customer}
      />
    </div>
  );
};

export default CustomerPage;
