import "./App.css";
import { useState } from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Layout } from "./Components";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  Dashboard,
  Missing,
  LoginPage,
  Customer,
  CustomerPage,
  ForgotPassword,
  ResetPasswordPage,
  ChangePasswordPage,
  HomePage,
  RegisterPage,
  InstallerPage,
  SettingPage,
  SuperAdminPage,
  NmiForm,
  ActivateUserCompany,
  SignDocumentFromLink,
} from "./Pages";
import Footer from "./Components/Footer/Footer";
import {
  PublicRoute,
  PrivateRoute,
  ProtectedRoute,
  InstallerRoute,
} from "./Components/Route/";
import { getUser } from "./Services";
import InstallerHomePage from "./Pages/InstallerHomePage/InstallerHomePage";
import RegisterNewUser from "./Pages/RegisterNewUser/RegisterNewUser";
import PdfSign from "./Pages/PdfSign/PdfSign";

function App() {
  const sessionUser = getUser();
  // console.log(sessionUser, "appppp");
  const location = useLocation();
  // console.log(location.pathname);
  return (
    <>
      {location.pathname === "/super-admin" ? (
        <Routes>
          <Route path="/super-admin" element={<SuperAdminPage />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            <>
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/password-reset-request"
                element={<ResetPasswordPage />}
              />
              <Route
                path="/password-reset-inst"
                element={<ResetPasswordPage />}
              />
              <Route path="/nmi-form" element={<NmiForm />} />{" "}
              <Route path="/register-new-user" element={<RegisterNewUser />} />
              <Route
                path="/activate-account-company"
                element={<ActivateUserCompany />}
              />
              <Route path="/sign-document" element={<SignDocumentFromLink />} />
              <Route path="/sign-pdf" element={<PdfSign />} />
              <Route element={<PublicRoute />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="/home" element={<InstallerHomePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/my-profile/:cust_id" element={<CustomerPage />} />
                <Route
                  path="/change-password"
                  element={<ChangePasswordPage />}
                />
              </Route>
              <Route element={<ProtectedRoute />}>
                {/* <Route path="/customer" element={<Customer />} /> */}

                {/* <Route path="/add-edit-filetype" element={<Customer />} /> */}
                {/* <Route
                path="/customer-profile/:cust_id/"
                element={<CustomerPage />}
              /> */}
              </Route>
              <Route element={<InstallerRoute />}>
                <Route path="/installer" element={<InstallerPage />} />
                <Route path="/customer" element={<Customer />} />
                <Route path="/settings" element={<SettingPage />} />
                <Route
                  path="/customer-profile/:cust_id/"
                  element={<CustomerPage />}
                />
              </Route>
            </>
            <Route path="*" element={<Missing />} />
          </Routes>
        </Layout>
      )}
      <Footer />
    </>
  );
}

export default App;
