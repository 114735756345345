import axios from "axios";
import {
  allRetailerFormsAPI,
  allRetailerFormsIAPI,
  allRetailersAPI,
  allRetailersIAPI,
  editDeleteRetailerFormAPI,
  editDeleteRetailersAPI,
} from "../Constants";
import { setUserToken } from "./CustomerAPI";

export const getAllRetailers = async (company_id) => {
  const [header, user] = setUserToken();
  const is_superuser = user["is_superuser"] ?? false;

  try {
    const fileResponse = await axios.get(
      is_superuser ? allRetailersAPI : allRetailersIAPI,

      {
        headers: header,
      }
    );
    if (fileResponse.status === 200) {
      return fileResponse.data;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return 500;
    } else {
      return 400;
    }
  }
};

export const createRetailer = async (retailerDetails) => {
  const [header, sessionUser] = setUserToken();
  try {
    const RResponse = await axios.post(allRetailersAPI, retailerDetails, {
      headers: header,
    });
    if (RResponse.status === 201) {
      // console.log("fileResponse", fileResponse);
      return "success";
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    }
    if (error.response.status === 409) {
      return "Name already exists";
    } else {
      return "Something went wrong";
    }
  }
};

export const editRetailer = async (retailerDetails) => {
  const [header, sessionUser] = setUserToken();
  try {
    const RResponse = await axios.patch(
      editDeleteRetailersAPI + retailerDetails.id + "/",
      retailerDetails,
      {
        headers: header,
      }
    );
    if (RResponse.status === 200) {
      return "success";
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror!!";
    } else {
      return "Something went wrong!!";
    }
  }
};

export const deleteRetailer = async (id) => {
  const [header, sessionUser] = setUserToken();
  try {
    const rResponse = await axios.delete(editDeleteRetailersAPI + id + "/", {
      headers: header,
    });
    if (rResponse.status === 204) {
      return "success";
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    } else {
      return "Something went wrong";
    }
  }
};

export const getAllRetailerForms = async (retailer_id) => {
  const [header, user] = setUserToken();
  const is_superuser = user["is_superuser"] ?? false;
  try {
    const fileResponse = await axios.get(
      (is_superuser ? allRetailerFormsAPI : allRetailerFormsIAPI) +
        retailer_id +
        "/",

      {
        headers: header,
      }
    );
    if (fileResponse.status === 200) {
      return fileResponse.data;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return 500;
    } else {
      return 400;
    }
  }
};

export const createRetailerForm = async (retailerDetails) => {
  const [header, sessionUser] = setUserToken();
  try {
    const RResponse = await axios.post(
      allRetailerFormsAPI + retailerDetails.retailer + "/",
      retailerDetails,
      {
        headers: header,
      }
    );
    if (RResponse.status === 201) {
      // console.log("fileResponse", fileResponse);
      return "success";
    }
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return "Internal server errror";
    }
    if (error.response.status === 409) {
      return "Name already exists";
    } else {
      return "Something went wrong";
    }
  }
};

export const editRetailerForm = async (retailerDetails) => {
  const [header, sessionUser] = setUserToken();
  try {
    const RResponse = await axios.patch(
      editDeleteRetailerFormAPI + retailerDetails.id + "/",
      retailerDetails,
      {
        headers: header,
      }
    );
    if (RResponse.status === 200) {
      return "success";
    }
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return "Internal server errror!!";
    } else {
      return "Something went wrong!!";
    }
  }
};

export const deleteRetailerForm = async (id) => {
  const [header, sessionUser] = setUserToken();
  try {
    const rResponse = await axios.delete(editDeleteRetailerFormAPI + id + "/", {
      headers: header,
    });
    if (rResponse.status === 204) {
      return "success";
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    } else {
      return "Something went wrong";
    }
  }
};
