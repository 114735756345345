import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import "bootstrap/dist/css/bootstrap.css";
import { ResetPasswordAuth } from "../../Services/";
import { Link, useSearchParams } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const ResetPasswordPage = () => {
  const [details, setDetails] = useState({
    newPassword: "",
    rePass: "",
  });
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [rePasswordShown, setRePasswordShown] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const showHidePassword = (pass) => {
    if (pass === "new") {
      setNewPasswordShown(!newPasswordShown);
    }
    if (pass === "re") {
      setRePasswordShown(!rePasswordShown);
    }
  };

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  useEffect(() => {
    seterrorMessage("");
    setsuccessMessage("");
  }, [details.newPassword, details.rePass]);

  const submitHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const pk = searchParams.get("resetid");
    const token = searchParams.get("token");
    // console.log("homepage", pk, token);

    if (details.rePass === details.newPassword) {
      // ResetPasswordAuth
      await ResetPasswordAuth(details, token, pk).then((result) => {
        if (result.status !== 200) {
          if (result.status === 400) {
            console.log("datachange password", result.data);
            seterrorMessage(result.data.message);
          } else {
            seterrorMessage(result.data.detail);
            setsuccessMessage("");
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log("else", result);
          setsuccessMessage("Password successfully changed!");
          seterrorMessage("");
        }
      });
    } else {
      seterrorMessage("Password did not match!");
    }

    setIsLoading(false);
  };

  return (
    <div className="login__form__main">
      <div className="login__form">
        <form className="app__form" onSubmit={submitHandler}>
          <h2>SET NEW PASSWORD</h2>
          {errorMessage !== "" ? (
            <span className="error__message">{errorMessage}</span>
          ) : (
            ""
          )}
          {successMessage !== "" ? (
            <span className="success__message">{successMessage}</span>
          ) : (
            ""
          )}
          <div className="form-group">
            <label htmlFor="New Password">New Password:</label>
            <input
              type={newPasswordShown ? "text" : "password"}
              name="password"
              id="password"
              required
              onChange={(e) =>
                setDetails({ ...details, newPassword: e.target.value })
              }
              value={details.newPassword}
            />
            <span className="eye__span" onClick={() => showHidePassword("new")}>
              {newPasswordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
            <label htmlFor="Re-Type Password">Re-Type Password:</label>
            <input
              type={rePasswordShown ? "text" : "password"}
              name="retypepassword"
              id="retypepassword"
              required
              onChange={(e) =>
                setDetails({ ...details, rePass: e.target.value })
              }
            />
            <span className="eye__span" onClick={() => showHidePassword("re")}>
              {rePasswordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
          </div>
          <div className="form-group">
            <input
              type="Submit"
              name="Request"
              id="submit"
              defaultValue={isLoading ? "Loading..." : "Reset Password"}
            />
          </div>{" "}
          <div>
            <Link className="another__link" to="/">
              Login
            </Link>
          </div>
        </form>

        <div />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
