import bg from "../Assets/bg.png";
import overlaybg from "../Assets/overlaybg.png";
import DarwinSolar from "../Assets/DarwinSolar.png";
import dsFooter from "../Assets/DarwinSolar.png";
import loadingCircle from "../Assets/loadingCircle.gif";
import mobile_app from "../Assets/mobile_app.png";
import e4logo from "../Assets/e4.png";
import search_icon from "../Assets/search_icon.png";
import signatureLogo from "../Assets/signatureLogo.PNG";
import signHere from "../Assets/sign_here.png";

export default {
  bg,
  overlaybg,
  DarwinSolar,
  dsFooter,
  loadingCircle,
  mobile_app,
  e4logo,
  search_icon,
  signatureLogo,
  signHere,
};
