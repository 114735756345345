import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getUser, setCompanySession, setIsAdminSession } from "../../Services";
import "./InstallerHomePage.css";

const InstallerHomePage = () => {
  const navigate = useNavigate();

  const sessionUser = getUser();
  const companyList = sessionUser.company;
  const handleChange = async (e) => {
    console.log("changed", e.target.value, e.target.innerHtml);
    console.log(companyList);

    const selectedCompany = companyList.filter((a) => a.id == e.target.value);

    setCompanySession(selectedCompany);
    console.log(sessionUser["admin"], selectedCompany[0]["company_name"]);
    console.log(
      sessionUser["admin"].includes(selectedCompany[0]["company_name"])
    );
    const is_admin = sessionUser["admin"].includes(
      selectedCompany[0]["company_name"]
    );
    //set if user is admin
    setIsAdminSession(is_admin);
    navigate("/customer");
  };

  return (
    <div className="installer__homepage">
      <div className="company__dropdwon form-control">
        <select className="form-control" onChange={handleChange}>
          <option value="0">--Select Company--</option>
          {companyList.map((x, y) => (
            <option key={x.id} value={x.id}>
              {x.company_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default InstallerHomePage;
