import { useState, useRef } from "react";
import SignaturePad from "react-signature-canvas";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";

const WebScript = () => {
  const sigCanvas = useRef();
  const [imgUrl, setImgUrl] = useState(null);
  const herukuCors = "https://cors-anywhere.herokuapp.com/";
  const fileUrl =
    "https://dsbuck.blob.core.windows.net/newcontainer/testpage.pdf";
  const filledFile = `${herukuCors}https://dsbuck.blob.core.windows.net/newcontainer/testpageFlat.pdf`;
  const url = `${herukuCors + fileUrl}`;

  const modifyPdf = async () => {
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const form = pdfDoc.getForm();

    pdfDoc.getPages().forEach((page) => {
      const date_signed = form.getTextField("fullname");
      console.log(page);
      console.log(page.context);
      console.log(date_signed);
    });

    const date_signed = form.getTextField("fullname");
    // const client_signature = form.getTextField("client_signature");
    console.log(date_signed);
    console.log(date_signed.ref);
    const dt_s = pdfDoc.findPageForAnnotationRef(date_signed.ref);
    // console.log(dt_s);
    const widg = date_signed.acroField.getWidgets();
    // console.log(widg);

    const filledpdf = await fetch(filledFile).then((res) => res.arrayBuffer());
    const fpdoc = await PDFDocument.load(filledpdf);
    const page1 = fpdoc.getPages();
    const fied = fpdoc.getForm();

    // fied.forEach((element) => {
    //   console.log(element);
    // });

    // const client_signature = form.getTextField("client_signature");
    console.log(fied.getTextField("fullname"));
    const fn = fied.getTextField("fullname");
    fn.setText("abghgdhfcd");

    // const fieldPageNum = widg.forEach((w) => {
    //   console.log(w);
    //   let rectangle = w.getRectangle();
    //   let page = pdfDoc.getPages().find((p) => p.ref === w.P());
    //   let pageIndex = pdfDoc.getPages().findIndex((p) => p.ref === w.P());
    //   return rectangle, page, pageIndex;
    // });
    let pageIndex = pdfDoc.getPages().findIndex((p) => p.ref === widg[0].P());
    console.log(pageIndex);

    page1[pageIndex].drawText("Written Test", widg[0].Rect().asRectangle());

    // dt_s.drawText("date signed", widg[0].Rect().asRectangle());

    // for (var field of pdfDoc.getForm().getFields()) {
    //   // Get all acroform fields.
    //   console.log(field.getName());
    //   const date_signed = form.getTextField("date_signed");
    //   var page = pdfDoc.findPageForAnnotationRef(field.ref); // Get field's page object;
    //   console.log(page);
    //   for (var widget of field.acroField.getWidgets()) {
    //     // Get all of the field widgets (which include it's locations)
    //     // page.drawImage(image, widget.Rect().asRectangle()); // Using the page object and widget's rectangle object to draw the image in a specific location and page
    //     page.drawText("pramesh shrestha", widget.Rect().asRectangle());
    //   }
    // }

    // const pdfBytes = await pdfDoc.save();
    const pdb = await fpdoc.save();
    const file = new Blob([pdb], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    //to view pdf in browser
    window.open(fileURL);
  };
  return (
    <div>
      pramesh
      <button onClick={modifyPdf}>See pdf</button>
      {/* <object
        data="https://dsbuck.blob.core.windows.net/newcontainer/al.pdf"
        width="800"
        height="500"
      ></object> */}
    </div>
  );
};

export default WebScript;
