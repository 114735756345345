import { CustomerTable } from "../../Components/Customer";
import { GlobalFilter } from "../../Components/Customer/globalFilter";
import "./Customer.css";
import { useState, useEffect, useMemo } from "react";
import {
  getCompanyCustomers,
  getIsAdminSession,
  getSelectedCompany,
  getUser,
} from "../../Services";
import { images } from "../../Constants";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { Link, useNavigate } from "react-router-dom";

const Customer = () => {
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const sessionUser = getUser();
  const selectedCompany = getSelectedCompany();
  // console.log(selectedCompany);
  const is_admin = getIsAdminSession("is_admin");

  const fetchCustomers = async () => {
    setIsLoading(true);
    const customerData = await getCompanyCustomers(
      selectedCompany[0]["id"] ?? {}
    );
    if (customerData === 500 || customerData === 401) {
      setCustomerList([]);
    } else {
      setCustomerList(customerData);
    }
    //  setCustomerList(customerData);
    setIsLoading(false);
  };

  const productData = useMemo(() => [...customerList], [customerList]);
  const productsColumns = useMemo(
    () =>
      customerList[0]
        ? Object.keys(customerList[0]).map((key, index) => {
            // console.log(key, "here");
            if ((key === "email") | (key === "street"))
              return {
                Header: key,
                accessor: key,
                Cell: ({ value, row }) => (
                  <div className="text__email__address">{value}</div>
                ),
              };
            if ((key === "Firstname") | (key === "Lastname"))
              return {
                Header: key,
                accessor: key,
                Cell: ({ value, row }) => (
                  <div className="text__wrap">
                    <Link
                      className="another__link "
                      to={{
                        pathname: `/customer-profile/${row.original.id}/`,
                        state: { customerList },
                      }}
                    >
                      {value}
                    </Link>
                  </div>
                ),
              };

            return {
              Header: key,
              accessor: key,
              Cell: ({ value, row }) => (
                <div className="">
                  {row.details}
                  {value}
                </div>
              ),
            };
          })
        : [],
    [customerList]
  );

  //this hook is for extra column not available in data column 13/02/2022 -Pramesh

  //   const tableHooks = (hooks) => {
  //     hooks.visibleColumns.push(
  //       (columns) => [
  //         ...columns,
  //         {
  //           id: "Preview",
  //           Header: "Preview",
  //           Cell: ({ row }) => (
  //             <Link
  //               to={{
  //                 pathname: `/customer-profile/${row.values.id}`,
  //                 search: "?sort=name",
  //                 hash: "#the-hash",
  //                 state: "true",
  //               }}
  //             >
  //               Preview
  //             </Link>
  //           ),
  //         },
  //       ]
  //       // <button onClick={()=>alert("Edit:"+row.values.price)}>Edit</button>
  //     );
  //   };
  const tableInstance = useTable(
    { columns: productsColumns, data: productData },
    useGlobalFilter,
    // tableHooks,
    useSortBy,
    usePagination
  );
  const { preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  useEffect(() => {
    fetchCustomers();
  }, []);
  return (
    // <div className='customer__details'><Search details={details}/></div>

    <div className="main__div">
      {!isLoading ? (
        <>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
          <div className="customer__details">
            <CustomerTable tableInstance={tableInstance} />
          </div>
        </>
      ) : (
        <div className="loading__img_div">
          {" "}
          <img
            className="loading__img"
            src={images.loadingCircle}
            width="100px"
            height="100px"
          ></img>
          <div>...Loading Customer List</div>
        </div>
      )}
    </div>
  );
};

export default Customer;
