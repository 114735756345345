import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./LoginPage.css";
import { Login } from "../../Services/";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const LoginPage = () => {
  const location = useLocation();
  // console.log(location.state["userType"]);
  const userType =
    location.state != null ? location.state["userType"] : "INSTALLER"; //to check if user is customer or installer
  const name = userType;
  const navigate = useNavigate();
  const [details, setDetails] = useState({ email: "", password: "" });
  const [errorMessage, seterrorMessage] = useState("");
  // const [successMessage, setsuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const showHidePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);
  useEffect(() => {
    seterrorMessage("");
  }, [details.email, details.password]);

  // function validateForm() {
  //   return details.email.length > 0 && details.password.length > 0;
  // }

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    seterrorMessage(null);
    await Login(details, userType).then((res) => {
      if (res["status"] !== 200) {
        seterrorMessage(res["result"]["Error"]);
        setIsLoading(false);
      } else {
        // console.log(res["result"]["is_active"]);
        if (res["result"]["is_active"]) {
          navigate("/dashboard");
        } else {
          seterrorMessage(
            "You account is not active! Please check your email for activation link."
          );
          setIsLoading(false);
        }

        // const is_admin = (res && res["result"]["is_admin"]) ?? false;
        // res["result"]["is_installer"]
        //   ? navigate("/home")
        //   : is_admin
        //   ? navigate("/customer")
        //   : navigate("/my-profile/" + res["result"]["id"]);
      }
    });
  };

  // const handleChange = (e) => {
  //   setDetails({ ...details, [e.target.name]: e.target.value });
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("auth")) navigate.push("/");
  // }, []);

  // const formSubmitter = (e) => {
  //   e.preventDefault();
  //   setsuccessMessage("");
  //   if (!emailValidator(details.email))
  //     return seterrorMessage("Please enter valid email id");

  //   if (!passwordValidator(details.password))
  //     return seterrorMessage(
  //       "Password should have minimum 8 character with the combination of uppercase, lowercase, numbers and specialcharaters"
  //     );
  //   // setsuccessMessage('Successfully Validated');
  //   if (details.email !== "admin@a.com" || details.password !== "Password@1")
  //     return seterrorMessage("Invalid email or password");

  //   navigate.push("/");
  //   localStorage.setItem("auth", true);
  // };

  return (
    <div className="login__form__main">
      <div className="login__form">
        <form className="app__form" onSubmit={submitHandler}>
          <h2>{name} LOGIN</h2>
          {errorMessage !== "" ? (
            <p className="error__message">{errorMessage}</p>
          ) : (
            ""
          )}
          <div className="form-group">
            <label htmlFor="Email">Email:</label>
            <input
              type="text"
              name="email"
              id="email"
              required
              onChange={(e) =>
                setDetails({ ...details, email: e.target.value })
              }
              value={details.name}
            />

            <label htmlFor="Password">Password:</label>
            <input
              className="wrapper"
              type={passwordShown ? "text" : "password"}
              name="password"
              id="password"
              required
              onChange={(e) =>
                setDetails({ ...details, password: e.target.value })
              }
              value={details.password}
            />
            <span className="eye__span" onClick={showHidePassword}>
              {passwordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
          </div>
          <div className="form-group">
            <input
              type="Submit"
              name="Login"
              id="submit"
              defaultValue={isLoading ? "Loading..." : "Login"}
            />
          </div>{" "}
          <div className="">
            <Link
              className="another__link"
              to="/forgot-password"
              state={{ user: userType }}
            >
              Forgot Password
            </Link>
          </div>
        </form>

        <div />
      </div>
    </div>
  );
};

export default LoginPage;
