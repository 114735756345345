import React from "react";
import { useState } from "react";
import { FaBars } from "react-icons/fa";
import SettingCompany from "../SettingCompany/SettingCompany";
import SettingUsers from "../SettingUsers/SettingUsers";
import "./SettingDash.css";

const SettingDash = ({ toggleState }) => {
  // const [toggleState, setToggleState] = useState(1);

  // const toggleTab = (index) => {
  //   setToggleState(index);
  // };
  return (
    <div className="container">
      <div className="content-tabs">
        <div
          className={
            toggleState === "General" ? "content  active-content" : "content"
          }
        >
          <h2>General Settings</h2>
          <hr />
          <p>General settings</p>
        </div>
        <div
          className={
            toggleState === "Users" ? "content  active-content" : "content"
          }
        >
          <h2>Users</h2>
          <hr />
          <SettingUsers toggleState={toggleState} />
        </div>
        <div
          className={
            toggleState === "Company" ? "content  active-content" : "content"
          }
        >
          <SettingCompany />
        </div>

        <div
          className={
            toggleState === "Billing" ? "content  active-content" : "content"
          }
        >
          <h2>Billing</h2>
          <hr />
          <p>Check billing status, invoices, set up payment method.</p>
        </div>
        <div
          className={
            toggleState === "Integration"
              ? "content  active-content"
              : "content"
          }
        >
          <h2>Integration</h2>
          <hr />
          <p>Integrate with other apps to make your life easier.</p>
        </div>
      </div>
    </div>
  );
};

export default SettingDash;
