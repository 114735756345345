import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ServerError } from "../../Components";

import { getUser, setCompanySession, setIsAdminSession } from "../../Services";

import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();

  const sessionUser = getUser();
  const companyList = sessionUser.company;
  const handleChange = async (e) => {
    const selectedCompany = companyList.filter((a) => a.id == e.target.value);
    setCompanySession(selectedCompany);
    if (selectedCompany.length > 0) {
      // console.log(sessionUser["admin"] ?? "no admin");
      const is_admin = sessionUser["admin"]
        ? sessionUser["admin"].includes(selectedCompany[0]["company_name"])
        : false;
      //set if user is admin
      setIsAdminSession(is_admin);
      sessionUser["is_installer"]
        ? navigate("/customer")
        : navigate("/my-profile/" + sessionUser["id"]);
    }
  };

  return sessionUser === 500 ? (
    <ServerError />
  ) : (
    <div className="installer__homepage">
      <div className="company__dropdwon form-control">
        <select className="form-control" onChange={handleChange}>
          <option value="0">--Select Company--</option>
          {companyList?.map((x, y) => (
            <option key={x.id} value={x.id}>
              {x.company_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Dashboard;
