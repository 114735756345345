import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillSetting } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { images, mediaUrl } from "../../Constants";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown } from "react-bootstrap";
import AddFileType from "../ModalFileType/ModalFileType";
import "./Navbar.css";
import {
  getIsAdminSession,
  getLoggedInUser,
  getSelectedCompany,
  getUser,
  removeAllSession,
  setCompanySession,
  setIsAdminSession,
  setUserSession,
} from "../../Services/";
import CommonFile from "../ModalCommonFile/ModalCommonFile";
import { FaAngleDown, FaImage } from "react-icons/fa";
import ModalAddRetailerForms from "../ModalAddRetailerForms/ModalAddRetailerForms";
import ModalAddRetailers from "../ModalAddRetailers/ModalAddRetailers";
// import { RemoveUserSession } from '../../Services/AuthContext';

const Navbar = ({}) => {
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  // const [is_admin, setIsAdmin] = useState(false);
  //start add-edit file type - modal
  const [showModal, setShowModal] = useState(false);
  const [showCommonFileModal, setShowCommonFileModal] = useState(false);
  const [showRetailerModal, setShowRetailerModal] = useState(false);
  const [showRetailerFormModal, setShowRetailerFormModal] = useState(false);

  const sessionUser = getUser();
  const companyList = sessionUser ? sessionUser.company : [];
  const company = getSelectedCompany();
  const company_name =
    company != null
      ? company.length > 0
        ? company[0]["company_name"]
        : ""
      : "";
  const checkAdminChanged = async () => {
    const loggedInUser = await getLoggedInUser(sessionUser["id"]);
    // console.log(loggedInUser);
    setUserSession(loggedInUser);
    const admin = company
      ? sessionUser["admin"].includes(company[0]["company_name"])
      : false;
    //set if user is admin
    // console.log("admin check", admin);
    setIsAdminSession(admin);
    // setIsAdmin(admin);
  };
  sessionUser != null && sessionUser["is_installer"]
    ? checkAdminChanged()
    : getUser();
  const is_admin = getIsAdminSession("is_admin");
  // setIsAdmin(temp_admin);
  // console.log("is admin navbar", is_admin);

  const titlee = "cust";
  const logoutHandler = () => {
    removeUserSession();
    setToggleMenu(false);
  };

  const removeUserSession = () => {
    // sessionStorage.removeItem("user");
    removeAllSession();
    navigate("/", { replace: true });
  };
  const menuToggleHandler = () => {
    setToggleMenu(false);
  };
  const companyLogo =
    company != null
      ? company.length > 0
        ? company[0]["company_logo"]
        : ""
      : "";
  // console.log(sessionUser);
  const handleChange = async (c_id) => {
    const selectedCompany = companyList.filter((a) => a.id == c_id);
    setCompanySession(selectedCompany);
    console.log(selectedCompany);

    const admin = sessionUser["admin"].includes(
      selectedCompany[0]["company_name"]
    );
    //set if user is admin
    setIsAdminSession(admin);
    // const currentUrl = window.location.href;
    console.log(sessionUser, admin);
    const currentUrl = window.location.pathname;
    console.log(currentUrl === "/customer");
    currentUrl === "/customer"
      ? window.location.reload()
      : navigate("/customer");
  };
  // console.log(sessionUser);
  return sessionUser === 500 ? (
    <div className="app__main__navbar text-white">
      <div className="m-4">
        Server error! Please try again later :({" "}
        <span className="app_navbar__Link__bigscreen" onClick={logoutHandler}>
          Logout
        </span>
      </div>
    </div>
  ) : (
    <>
      <div className="app__main__navbar">
        <nav className="app__navbar">
          <div className="app__main__logo ">
            {companyList?.length === 0 ? (
              <div className="app__navbar-logo e4">
                <img src={images.e4logo} alt="Darwin Solar"></img>

                {/* test height weidth of page */}
                {/* W: {window.innerWidth} - H: {window.innerHeight} */}
              </div>
            ) : (
              <div className="app__company__dropdown">
                <Dropdown className="">
                  <Dropdown.Toggle
                    variant="none"
                    id="dropdown-basic"
                    className=" app__navbar-logo"
                  >
                    <div className="">
                      {companyLogo ? (
                        <img
                          src={mediaUrl + companyLogo}
                          alt={company_name}
                        ></img>
                      ) : (
                        <img src={images.e4logo} alt="Darwin Solar"></img>
                      )}
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {companyList?.map((x, y) => (
                      <Dropdown.Item
                        key={y}
                        eventKey={x.company_name}
                        onClick={() => handleChange(x.id)}
                        defaultValue={x.company_name}
                        className="company__dropdown"
                      >
                        {x.company_logo != "" ? (
                          <img
                            src={mediaUrl + x.company_logo}
                            alt={x.company_name}
                          ></img>
                        ) : (
                          <span className="nav__noimage">
                            {" "}
                            <img src={images.e4logo} alt={x.company_name}></img>
                          </span>
                        )}{" "}
                        {x.company_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {/* test height weidth of page */}
            {/* W: {window.innerWidth} - H: {window.innerHeight} */}
          </div>
          {
            sessionUser != null ? (
              <>
                <ul className="app__navbar-links">
                  <li>
                    <Link className="app_navbar__Link__bigscreen" to="">
                      {company_name}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="app_navbar__Link__bigscreen"
                      to="/dashboard"
                    >
                      Dashboard
                    </Link>
                  </li>
                  {sessionUser["is_customer"] && (
                    <li className="p__opensans">
                      <Link
                        className="app_navbar__Link__bigscreen"
                        to={"/my-profile/" + sessionUser.id}
                      >
                        Profile
                      </Link>
                    </li>
                  )}

                  {is_admin && (
                    <>
                      <li className="p__opensans">
                        <Link
                          className="app_navbar__Link__bigscreen"
                          to="/customer"
                        >
                          Customer
                        </Link>
                      </li>
                      <li className="p__opensans">
                        <Link
                          className="app_navbar__Link__bigscreen"
                          to="/installer"
                        >
                          Jobs
                        </Link>
                      </li>
                    </>
                  )}
                  {/* start dropdown top big screen */}
                  <Dropdown className="app_navbar__setting">
                    <Dropdown.Toggle variant="none" id="dropdown-basic">
                      <div className="user__head">
                        <div className="user__head__circle">
                          {sessionUser && sessionUser["first_name"][0]}
                          {sessionUser["last_name"] !== null
                            ? sessionUser["last_name"][0]
                            : ""}
                        </div>
                        <div className="row user__name">
                          <div className="col">
                            {sessionUser["first_name"]}{" "}
                            {sessionUser["last_name"]}
                          </div>
                          <div className="col arrow down">
                            <FaAngleDown />
                          </div>
                        </div>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item className="dp__name__cicle">
                        {sessionUser["email"]}
                      </Dropdown.Item>
                      {sessionUser["is_superuser"] && (
                        <>
                          <Dropdown.Item
                            onClick={() => setShowRetailerModal(true)}
                          >
                            <span>Add Retailers</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setShowRetailerFormModal(true)}
                          >
                            <span>Add Retailer's Forms</span>
                          </Dropdown.Item>
                        </>
                      )}

                      {is_admin && (
                        <>
                          <Dropdown.Item href="/settings">
                            Account Settings
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setShowModal(true)}>
                            <span>Add/Edit File type</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setShowCommonFileModal(true)}
                          >
                            <span>Add/Edit Common File</span>
                          </Dropdown.Item>
                          <li className="p__opensans"></li>
                        </>
                      )}
                      <Dropdown.Item
                        className="another__link"
                        href="/change-password"
                      >
                        <span>Change Password</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="" onClick={logoutHandler}>
                        <span className="another__link">Logout</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* end dropdown big screen */}
                </ul>

                <div className="app__navbar-smallscreen">
                  <GiHamburgerMenu
                    className="app__navbar-hamburger"
                    color="#fff"
                    fontSize={27}
                    onClick={() => setToggleMenu(true)}
                  />
                  {toggleMenu && (
                    <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
                      <MdClose
                        fontSize={27}
                        className="overlay__close"
                        onClick={() => setToggleMenu(false)}
                      />
                      <ul className="app__navbar-smallscreen_links">
                        <li>
                          <Link className="app_navbar__Link" to="">
                            {company_name}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="app_navbar__Link"
                            to="/dashboard"
                            onClick={menuToggleHandler}
                          >
                            Dashboard
                          </Link>
                        </li>
                        {is_admin && (
                          <>
                            <li className="p__opensans">
                              <Link
                                className="app_navbar__Link"
                                to="/customer"
                                onClick={menuToggleHandler}
                              >
                                Customer
                              </Link>
                            </li>
                            <li className="p__opensans">
                              <Link
                                className="app_navbar__Link"
                                to="/installer"
                                onClick={menuToggleHandler}
                              >
                                Jobs
                              </Link>
                            </li>
                          </>
                        )}
                        <li>
                          {/* start dropdown top small screen */}
                          <Dropdown className="app_navbar__setting">
                            <Dropdown.Toggle variant="none" id="dropdown-basic">
                              <div className="user__head">
                                <div className="user__head__circle">
                                  {sessionUser["first_name"][0]}
                                  {sessionUser["last_name"] !== null
                                    ? sessionUser["last_name"][0]
                                    : ""}
                                </div>
                                <div className="row user__name">
                                  <div className="col">
                                    {sessionUser["first_name"]}{" "}
                                    {sessionUser["last_name"]}
                                  </div>
                                  <div className="col arrow down">
                                    <FaAngleDown />
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item className="dp__name__cicle">
                                {sessionUser["email"]}
                              </Dropdown.Item>

                              {is_admin && (
                                <>
                                  <Dropdown.Item
                                    href="/settings"
                                    onClick={menuToggleHandler}
                                  >
                                    Account Settings
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <span onClick={() => setShowModal(true)}>
                                      Add/Edit File type
                                    </span>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <span
                                      onClick={() =>
                                        setShowCommonFileModal(true)
                                      }
                                    >
                                      Add/Edit Common File
                                    </span>
                                  </Dropdown.Item>
                                </>
                              )}
                              <Dropdown.Item
                                className="another__link"
                                href="/change-password"
                              >
                                <span>Change Password</span>
                              </Dropdown.Item>
                              <Dropdown.Item href="" onClick={logoutHandler}>
                                <span className="another__link">Logout</span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          {/* end dropdown small screen */}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </>
            ) : (
              //start if not logged in
              <>
                <ul className="app__navbar-links">
                  <li>
                    <Link
                      className="app_navbar__Link__bigscreen"
                      to="/login"
                      state={{ userType: "CUSTOMER" }}
                    >
                      Customer Login
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="app_navbar__Link__bigscreen"
                      to="/login"
                      state={{ userType: "INSTALLER" }}
                    >
                      Installer Login
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="app_navbar__Link__bigscreen"
                      to="/register"
                      state={{ userType: "INSTALLER" }}
                      onClick={menuToggleHandler}
                    >
                      Register
                    </Link>
                  </li>
                </ul>
                <div className="app__navbar-smallscreen">
                  <GiHamburgerMenu
                    className="app__navbar-hamburger"
                    color="#fff"
                    fontSize={27}
                    onClick={() => setToggleMenu(true)}
                  />
                </div>
                {toggleMenu && (
                  <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
                    <MdClose
                      fontSize={27}
                      className="overlay__close"
                      onClick={() => setToggleMenu(false)}
                    />
                    <ul className="app__navbar-smallscreen_links">
                      <li className="p__opensans">
                        <Link
                          className="app_navbar__Link"
                          to="/login"
                          state={{ userType: "CUSTOMER" }}
                          onClick={menuToggleHandler}
                        >
                          Customer Login
                        </Link>
                      </li>
                      <li className="p__opensans">
                        <Link
                          className="app_navbar__Link"
                          to="/login"
                          state={{ userType: "INSTALLER" }}
                          onClick={menuToggleHandler}
                        >
                          Installer Login
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          className="app_navbar__Link"
                          to="/register"
                          state={{ userType: "INSTALLER" }}
                          onClick={menuToggleHandler}
                        >
                          Register
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            )

            // end if not logged in
          }
        </nav>
        <AddFileType
          showModal={showModal}
          ohShow={() => setShowModal(true)}
          onHide={() => setShowModal(false)}
        />
        <CommonFile
          showCommonFileModal={showCommonFileModal}
          ohShow={() => setShowCommonFileModal(true)}
          onHide={() => setShowCommonFileModal(false)}
        />
        <ModalAddRetailers
          showModalR={showRetailerModal}
          ohShow={() => setShowRetailerModal(true)}
          onHide={() => setShowRetailerModal(false)}
        />
        <ModalAddRetailerForms
          showModalRF={showRetailerFormModal}
          ohShow={() => setShowRetailerFormModal(true)}
          onHide={() => setShowRetailerFormModal(false)}
        />
      </div>
    </>
  );
};

export default Navbar;
