export const getUser = (from) => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};
export const setUserSession = (user) => {
  sessionStorage.setItem("user", JSON.stringify(user));
  return true;
};
export const removeAllSession = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("is_admin");
  sessionStorage.removeItem("company");
  return true;
};
export const setCompanySession = (company) => {
  sessionStorage.setItem("company", JSON.stringify(company));
  return true;
};
export const getSelectedCompany = (from) => {
  const companyStr = sessionStorage.getItem("company");
  if (companyStr) return JSON.parse(companyStr);
  else return null;
};
export const setIsAdminSession = (bool) => {
  sessionStorage.setItem("is_admin", JSON.stringify(bool));
  return bool;
};
export const getIsAdminSession = (from) => {
  const is_admin = sessionStorage.getItem("is_admin");
  if (is_admin) return JSON.parse(is_admin);
  else return null;
};
