import React from "react";
import "./Header.css";
import { images } from "../../../../Constants";

const Header = () => {
  return (
    <div
      className="homepage__header homepage__wrapper homepage__padding"
      id="home"
    >
      <div className="homepage__wrapper_info">
        <span>Chase the new style</span>
        <h1 className="homepage__header-h1">The key to Success</h1>
        Welcome to Darwin solar portal.
      </div>
      <div className="homepage__wrapper_img">
        <img src={images.mobile_app} alt="header " />
      </div>
    </div>
  );
};

export default Header;
