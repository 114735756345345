import React from "react";
import { useState, useRef, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import "./PdfSign.css";
import { PDFDocument } from "pdf-lib";
import { isCompositeComponent } from "react-dom/test-utils";
import DrawSign from "./DrawSign";
import WebScript from "./WebScript";
// import AdobeDC from "https://documentservices.adobe.com/view-sdk/viewer.js";
const PdfSign = () => {
  const sigCanvas = useRef();
  const [imgUrl, setImgUrl] = useState(null);
  // const download = async () => {
  //   await fetch("https://unpkg.com/downloadjs@1.4.7/download.js").then(
  //     (res) => res
  //   );
  // };

  const fields = async () => {
    const containerUrl =
      "https://dsbuck.blob.core.windows.net/newcontainer/al.pdf";
    const herukuCors = "https://cors-anywhere.herokuapp.com/";
    const formUrl = herukuCors + containerUrl;
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);

    const form = pdfDoc.getForm();
    // console.log(form.getFields());
    const fields = form.getFields();
    fields.forEach((field) => {
      console.log(field.getName());
    });
    const date_signed = form.getTextField("date_signed");
    const client_signature = form.getTextField("client_signature");

    const sgn = await pdfDoc.embedPng(imgUrl);
    client_signature.setImage(sgn);

    const date = new Date();
    const datetime_signed = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    // console.log(datetime_signed);
    date_signed.setText(datetime_signed);
    form.flatten();
    const pdfBytes = await pdfDoc.save();
    const file = new Blob([pdfBytes], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);
    //to view pdf in browser
    window.open(fileURL);
    //to download pdf
    // let alink = document.createElement("a");
    // alink.href = fileURL;
    // alink.download = "SamplePDF.pdf";
    // alink.click();
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setImgUrl(null);
  };
  const saveSignature = () => {
    // console.log(sigCanvas.current.isEmpty());
    if (sigCanvas.current.isEmpty()) {
      console.log("Please sign first");
    } else {
      // console.log(sigCanvas.current.getCanvas().toDataURL("image/png"));
      setImgUrl(sigCanvas.current.getCanvas().toDataURL("image/png"));
    }
  };
  const saveDoc = () => {
    fields();
  };
  return (
    <div>
      <div>PdfSign</div>
      {/* <DrawSign /> */}
      <WebScript />
      <div>
        {/* https://dsbuck.blob.core.windows.net/e4container/companies/Darwin Solar/DSD451224/CustomerFiles/20221124154532977007Authorization_Letter2_Tydjlib.pdf */}
        {/* <Helmet>
          <script src="https://documentservices.adobe.com/view-sdk/viewer.js"></script>
        </Helmet> */}
        <div id="sig-pad"></div>
        <SignaturePad
          ref={sigCanvas}
          canvasProps={{ className: "signatureCanvas" }}
        />
        <button className="btn btn-primary" onClick={saveSignature}>
          Save
        </button>
        <button className="btn btn-danger" onClick={clearSignature}>
          Clear
        </button>
        <button onClick={saveDoc}>saveDoc</button>

        {imgUrl ? (
          <img
            src={imgUrl}
            alt="New Signature"
            style={{
              display: "block",
              margin: "0, auto",
              border: "1px solid black",
              width: "150px",
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PdfSign;
