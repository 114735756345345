import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { getSelectedCompany } from "../../../../Services";
import {
  CompanyEdit,
  getCompanyAddress,
  getCompanyDetails,
} from "../../../../Services/CompanyAPI";
import "./SettingCompany.css";

const SettingCompany = () => {
  const [companyDetails, setCompanyDetails] = useState();
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [companyEditDetails, setCompanyEditDetails] = useState({
    company_name: "",
    company_logo: "",
    company_address: "",
    billing_address: "",
    unit: "",
    street: "",
    city: "",
    country: "",
    postcode: "",
    phone: "",
    state: "",
    company_statues: "",
    is_active: "",
    company_phone: "",
  });
  const [companyAddress, setCompanyAddress] = useState();
  const [billingAddress, setBillingAddress] = useState();
  const [tempBilling, setTempBilling] = useState();
  const companyLogoRef = useRef();

  const company = getSelectedCompany()[0];
  const fetchCompanyDetails = async () => {
    const company_address = await getCompanyAddress(company.id);
    // console.log(company_address);
    if (company_address !== 500 || company_address !== 500) {
      const billing = company_address.filter(
        (x, index) => x.address_type == "billing"
      );
      const address = company_address.filter(
        (x, index) => x.address_type == "company_address"
      );
      // console.log(billing);
      // console.log(address);
      setCompanyAddress(address[0]);
      setBillingAddress(billing[0]);
      setTempBilling(billing[0]);
    }
    const company_details = await getCompanyDetails(company.id);
    if (company_details !== 500 || company_details !== 500) {
      setCompanyDetails(company_details[0]);
    }
  };
  // console.log(companyDetails);
  // console.log(companyAddress);
  // console.log(billingAddress);
  const deactivateHandler = () => {
    if (
      window.confirm(
        "Are you sure you want to deactivate " +
          companyDetails.company_name +
          "?"
      ) == true
    ) {
      setCompanyDetails({ ...companyDetails, is_active: false });
    } else {
    }
  };
  const editHandler = async (event) => {
    event.preventDefault();
    let fd = new FormData();
    if (selectedFile) {
      // console.log(selectedFile);
      fd.append("company_name", companyDetails.company_name);
      fd.append("company_logo", selectedFile, selectedFile.name);
      fd.append("company_add", JSON.stringify(companyAddress));
      fd.append("billing_add", JSON.stringify(billingAddress));
      fd.append("company_phone", companyDetails.company_phone);
      fd.append("is_active", companyDetails.is_active);
      fd.append("hasFile", true);
      console.log("selected file");
    } else {
      fd = {
        ...companyDetails,
        hasFile: false,
        billing_add: billingAddress,
        company_add: companyAddress,
      };
    }

    const editResult = await CompanyEdit(fd, company.id);
    // console.log(editResult);
    if (editResult.status != 200) {
      if (editResult.status === 500) {
        seterrorMessage("Internal server error");
        setsuccessMessage("");
      } else {
        seterrorMessage("Something went wrong");
        setsuccessMessage("");
      }
    } else {
      setsuccessMessage("Successfully updated!");
      seterrorMessage("");
      fetchCompanyDetails();
      companyLogoRef.current.value = null;
      setSelectedFile(null);
    }
    setTimeout(() => {
      seterrorMessage("");
      setsuccessMessage("");
    }, 10000);
  };
  const billAddressSameAscompanyAddress = (event) => {
    const checked = event.target.checked;
    setIsSameAddress(event.target.checked);
    checked
      ? setBillingAddress({
          ...billingAddress,
          unit: companyAddress?.unit,
          street: companyAddress?.street,
          city: companyAddress?.city,
          state: companyAddress?.state,
          postcode: companyAddress?.postcode,
          country: companyAddress?.country,
        })
      : setBillingAddress(tempBilling);
  };
  useEffect(() => {
    company && fetchCompanyDetails();
  }, []);

  return (
    <div className="">
      <div className="row">
        <div className="col-4">
          <h2>Company Details</h2>
        </div>
        <div className="col">
          {errorMessage !== "" ? (
            <h4 className="error__message">{errorMessage}</h4>
          ) : (
            ""
          )}
          {successMessage !== "" ? (
            <h4 className="success__message">{successMessage}</h4>
          ) : (
            ""
          )}
        </div>
      </div>

      {companyDetails ? (
        <div>
          <form onSubmit={editHandler}>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingName"
                    placeholder="company"
                    defaultValue={companyDetails.company_name}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        company_name: e.target.value,
                      })
                    }
                    required
                  />
                  <label htmlFor="floatingInput">Company</label>
                </div>
              </div>
              <div className="col-4">
                <div className="form-floating mb-3">
                  <input
                    type="file"
                    className="form-control upload__img__choose"
                    id="floatingLogo"
                    placeholder="logo"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                    ref={companyLogoRef}
                  />
                  <label className="company__logo_label" htmlFor="floatingLogo">
                    Company Logo
                  </label>
                </div>
              </div>
              <div className="col-2">
                {" "}
                <img
                  className="company__logo"
                  src={companyDetails.company_logo}
                ></img>
              </div>
            </div>
            {/* start company address */}
            Company Address
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingUnit"
                    placeholder="Unit"
                    defaultValue={companyAddress?.unit}
                    onChange={(e) =>
                      setCompanyAddress({
                        ...companyAddress,
                        unit: e.target.value,
                      })
                    }
                    required
                  />
                  <label htmlFor="floatingUnit">Unit</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3 col">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingStreet"
                    placeholder="Street"
                    defaultValue={companyAddress?.street}
                    onChange={(e) =>
                      setCompanyAddress({
                        ...companyAddress,
                        street: e.target.value,
                      })
                    }
                    required
                  />
                  <label htmlFor="floatingStreet">Street</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingCity"
                    placeholder="City"
                    defaultValue={companyAddress?.city}
                    onChange={(e) =>
                      setCompanyAddress({
                        ...companyAddress,
                        city: e.target.value,
                      })
                    }
                    required
                  />
                  <label htmlFor="floatingCity">City</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingState"
                    placeholder="State"
                    defaultValue={companyAddress?.state}
                    onChange={(e) =>
                      setCompanyAddress({
                        ...companyAddress,
                        state: e.target.value,
                      })
                    }
                    required
                  />
                  <label htmlFor="floatingState">State</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingCountry"
                    placeholder="Country"
                    defaultValue={companyAddress?.country}
                    onChange={(e) =>
                      setCompanyAddress({
                        ...companyAddress,
                        country: e.target.value,
                      })
                    }
                    required
                  />
                  <label htmlFor="floatingCountry">Country</label>
                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-3 col">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingPostcode"
                    placeholder="Postcode"
                    defaultValue={companyAddress?.postcode}
                    onChange={(e) =>
                      setCompanyAddress({
                        ...companyAddress,
                        postcode: e.target.value,
                      })
                    }
                    required
                  />
                  <label htmlFor="floatingPostcode">Postcode</label>
                </div>
              </div>
            </div>
            {/* end company address */}
            {/* start billing address */}
            <div className="d-flex flex-row">
              <div className=" p-2">Billing Address -</div>
              <div className=" p-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  defaultValue={isSameAddress}
                  onChange={billAddressSameAscompanyAddress}
                />{" "}
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Same as Company Address
                </label>
              </div>
            </div>
            {isSameAddress ? (
              <div></div>
            ) : (
              <>
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingBUnit"
                        placeholder="Unit"
                        defaultValue={billingAddress?.unit}
                        onChange={(e) =>
                          setBillingAddress({
                            ...billingAddress,
                            unit: e.target.value,
                          })
                        }
                        required
                      />
                      <label htmlFor="floatingBUnit">Unit</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 col">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingBStreet"
                        placeholder="Street"
                        defaultValue={billingAddress?.street}
                        onChange={(e) =>
                          setBillingAddress({
                            ...billingAddress,
                            street: e.target.value,
                          })
                        }
                        required
                      />
                      <label htmlFor="floatingBStreet">Street</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingBCity"
                        placeholder="City"
                        defaultValue={billingAddress?.city}
                        onChange={(e) =>
                          setBillingAddress({
                            ...billingAddress,
                            city: e.target.value,
                          })
                        }
                        required
                      />
                      <label htmlFor="floatingBCity">City</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingBstate"
                        placeholder="State"
                        defaultValue={billingAddress?.state}
                        onChange={(e) =>
                          setBillingAddress({
                            ...billingAddress,
                            state: e.target.value,
                          })
                        }
                        required
                      />
                      <label htmlFor="floatingBstate">State</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingBCountry"
                        placeholder="Country"
                        defaultValue={billingAddress?.country}
                        onChange={(e) =>
                          setBillingAddress({
                            ...billingAddress,
                            country: e.target.value,
                          })
                        }
                        required
                      />
                      <label htmlFor="floatingBCountry">Country</label>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-floating mb-3 col">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingBPostcode"
                        placeholder="Postcode"
                        defaultValue={billingAddress?.postcode}
                        onChange={(e) =>
                          setBillingAddress({
                            ...billingAddress,
                            postcode: e.target.value,
                          })
                        }
                        required
                      />
                      <label htmlFor="floatingBPostcode">Postcode</label>
                    </div>
                  </div>
                </div>
                <div className="form-floating mb-3 col">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingBPostcode"
                    placeholder="Phone"
                    defaultValue={companyDetails.company_phone}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        company_phone: e.target.value,
                      })
                    }
                    required
                  />
                  <label htmlFor="floatingPhone">Phone</label>
                </div>
              </>
            )}
            {/* end billing address */}
            <div className="row">
              <div className="col">
                <button
                  type="submit"
                  className="btn btn-primary"
                  // onClick={editHandler}
                >
                  Save
                </button>
              </div>{" "}
              <div className="col d-flex flex-row-reverse">
                <button className="btn btn-danger" onClick={deactivateHandler}>
                  Deactivate
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div>...loading</div>
      )}
    </div>
  );
};

export default SettingCompany;
