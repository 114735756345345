import { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { mediaUrl } from "../../Constants";
import {
  getIsAdminSession,
  getUser,
  getSelectedCompany,
  createCommonFile,
  deleteCommonFile,
  editCommonFile,
  getAllCompanyCommonFiles,
} from "../../Services";
import ButtonUpload from "../ButtonUpload/ButtonUpload";
import "./ModalCommonFile.css";

function CommonFile({ showCommonFileModal, ohShow, onHide }) {
  const [allCommonFile, setAllFileTypes] = useState([]);
  const [fileName, setFileName] = useState();
  const [CommonFileDetails, setCommonFileDetails] = useState({
    id: "",
    file_name: "",
    file_details: "",
    model_number: "",
    file: "",
  });
  const clearCommonFileDetails = () => {
    setCommonFileDetails({
      id: "",
      file_name: "",
      file_details: "",
      model_number: "",
      file: "",
    });
    fileUploadRef.current.value = "";
  };
  const fileUploadRef = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUser = getUser();
  const company = getSelectedCompany();
  const is_admin = getIsAdminSession();
  // console.log(company);
  const company_id = is_admin
    ? company && company.length !== 0
      ? company[0]["id"]
      : 0
    : 0;
  const fetchAllCommonFiles = async () => {
    const allFiles = await getAllCompanyCommonFiles(company_id);
    // console.log(allFiles);
    if (allFiles === 500 || allFiles === 400) {
    } else {
      setAllFileTypes(allFiles);
    }
  };
  const fileSelectHandler = (e) => {
    // console.log(e.target.files);
    setSelectedFile(e.target.files[0]);
  };
  setTimeout(() => setSuccessMessage(""), 10000);
  setTimeout(() => seterrorMessage(""), 10000);
  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const fd = new FormData();
    console.log(selectedFile);
    if (selectedFile) {
      fd.append("id", "");
      fd.append("file_name", CommonFileDetails.file_name);
      fd.append("file_details", CommonFileDetails.file_details);
      fd.append("model_number", CommonFileDetails.model_number);
      fd.append("file", selectedFile, selectedFile.name);
      fd.append("company", company_id);
      console.log(fd);
    }

    let result;
    if (CommonFileDetails.id === "") {
      //code for adding new file category
      console.log(CommonFileDetails.file_name, "filetype");
      result = await createCommonFile(fd, company_id);
      console.log("added", result);
      if (result === "success") {
        setSuccessMessage("File Uploaded");
      }
      clearCommonFileDetails();
      setIsLoading(false);
    } else {
      //code for editing existing file category
      console.log(selectedFile);
      let fd = new FormData();
      if (selectedFile != null) {
        fd.append("id", CommonFileDetails.id);
        fd.append("file_name", CommonFileDetails.file_name);
        fd.append("file_details", CommonFileDetails.file_details);
        fd.append("model_number", CommonFileDetails.model_number);
        fd.append("file", selectedFile, selectedFile.name);
        fd.append("company", company_id);
        fd.append("hasFile", true);
        console.log("selected file");
      } else {
        console.log("else");
        fd = { ...CommonFileDetails, hasFile: false };
      }
      result = await editCommonFile(fd, company_id);
      console.log("edited", result);
      if (result === "success") {
        clearCommonFileDetails();
      }
      setIsLoading(false);
    }
    if (result === "success") {
      setSuccessMessage("Item Saved!");
      fetchAllCommonFiles();
    } else {
      seterrorMessage(result);
    }
    console.log("test");
  };

  const deleteFile = async (id) => {
    if (id === "") {
      seterrorMessage("No item selected!!");
    } else {
      if (window.confirm("Delete " + CommonFileDetails.file_name + "?")) {
        const deleted = await deleteCommonFile(id);
        if (deleted === "success") {
          clearCommonFileDetails();
          setSuccessMessage("Item deleted1");
          fetchAllCommonFiles();
          setSuccessMessage("Item deleted");
        } else {
          seterrorMessage(deleted);
        }
      } else {
        console.log("not deleted");
      }
    }
  };
  const viewFile = (file_path) => {
    // const fileExt = file_path ? file_path.split(".").pop() : "";
    console.log(file_path);
    window.open(mediaUrl + file_path, "_blank");
  };
  useEffect(() => {
    seterrorMessage("");
    setSuccessMessage("");
  }, [CommonFileDetails.file_name, CommonFileDetails.file_details]);

  useEffect(() => {
    is_admin && fetchAllCommonFiles();
  }, [showCommonFileModal]);
  const isEven = (index) => index % 2 === 0;
  // console.log(allFileTypes);

  return (
    <>
      {/* <Button variant="primary" onClick={showModal}>
        Launch demo modal
      </Button> */}

      <Modal
        className="modal__file"
        size="lg"
        show={showCommonFileModal}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Common file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal__div row">
            <div className="col-6 ">File Type (Click to edit or delete)</div>
            <div className="col-6 ">Details</div>
          </div>
          <div className="row">
            <div className="col-6 ">
              <ol>
                {showCommonFileModal &&
                  allCommonFile?.map((d, index) => (
                    <li
                      key={d.id}
                      onClick={() => {
                        setCommonFileDetails({
                          id: d.id,
                          file_name: d.file_name,
                          file_details: d.file_details,
                          model_number: d.model_number ?? "",
                          file: d.file ?? "",
                        });
                        setFileName(d.file_name);
                      }}
                      style={{ cursor: "pointer" }}
                      className={isEven(index) ? "bg__even" : "bg__odd"}
                    >
                      {d.file_name ?? ""}{" "}
                    </li>
                  ))}
              </ol>
            </div>
            <div className="col-6 ">
              <form onSubmit={submitHandler}>
                <label>
                  File Name:
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={
                      CommonFileDetails.file_name == null
                        ? ""
                        : CommonFileDetails.file_name
                    }
                    required
                    onChange={(e) =>
                      setCommonFileDetails({
                        ...CommonFileDetails,
                        file_name: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Description:
                  <input
                    className="form-control"
                    type="text"
                    name="desc"
                    value={
                      CommonFileDetails.file_details == null
                        ? ""
                        : CommonFileDetails.file_details
                    }
                    required
                    onChange={(e) =>
                      setCommonFileDetails({
                        ...CommonFileDetails,
                        file_details: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Model Number:
                  <input
                    className="form-control"
                    type="text"
                    name="desc"
                    value={CommonFileDetails.model_number}
                    required
                    onChange={(e) =>
                      setCommonFileDetails({
                        ...CommonFileDetails,
                        model_number: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  File:{" "}
                  {CommonFileDetails.file ? (
                    <span
                      className="file__name"
                      onClick={() => viewFile(CommonFileDetails.file)}
                    >
                      {fileName}
                    </span>
                  ) : (
                    <span className="file__name">No file</span>
                  )}
                  <input
                    className="form-control"
                    ref={fileUploadRef}
                    type="file"
                    id="common-file"
                    name="common-file"
                    required={CommonFileDetails.id === "" ? "required" : ""}
                    //
                    onChange={fileSelectHandler}
                  />
                </label>
                {/* <ButtonUpload /> */}

                <div className="top__button">
                  <Button
                    type="Submit"
                    variant="primary"
                    // onClick={() => addEditFileType(CommonFileDetails)}
                  >
                    {(CommonFileDetails.id && "Save") || "Add new"}
                  </Button>
                  <Button
                    variant="danger"
                    disabled={CommonFileDetails.id ? false : true}
                    onClick={() => deleteFile(CommonFileDetails.id)}
                  >
                    Delete
                  </Button>
                </div>
              </form>
              <div
                className={errorMessage ? "error__message" : "success__message"}
              >
                {errorMessage ? errorMessage : successMessage}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer__button">
            <Button variant="primary" onClick={() => clearCommonFileDetails()}>
              {(CommonFileDetails.id && "Clear") || "Add new"}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                clearCommonFileDetails();
                onHide();
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommonFile;
