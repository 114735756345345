import React from "react";
import {
  FaFileInvoice,
  FaHome,
  FaPlug,
  FaSuitcase,
  FaUsers,
} from "react-icons/fa";

export const SideBarData = [
  {
    title: "General",
    icon: <FaHome />,
    link: "/home",
  },
  {
    title: "Users",
    icon: <FaUsers />,
    link: "/home",
  },
  {
    title: "Company",
    icon: <FaSuitcase />,
    link: "/home",
  },
  {
    title: "Billing",
    icon: <FaFileInvoice />,
    link: "/home",
  },
  {
    title: "Integration",
    icon: <FaPlug />,
    link: "/home",
  },
];
