import axios from "axios";
import {
  allFilesTypeInstallerApi,
  commonfile,
  companyCustomerList,
  companyJobList,
  companyUserList,
  retrieveFileFieldAPI,
  saveUploadeFileFieldAPI,
} from "../Constants";
import { setUserToken } from "./CustomerAPI";

export const getCompanyCustomers = async (company_id) => {
  const [header] = setUserToken();
  try {
    const response = await axios.get(companyCustomerList + company_id + "/", {
      headers: header,
    });
    if (response.status === 200) {
      const customerData = response.data;
      // console.log(customerData);
      return customerData;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return 500;
    } else {
      return 401;
    }
  }
};
export const getCompanyUsers = async (company_id) => {
  const [header] = setUserToken();
  try {
    const response = await axios.get(companyUserList + company_id + "/", {
      headers: header,
    });
    if (response.status === 200) {
      const userData = response.data;
      // console.log(customerData);
      return userData;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return 500;
    } else {
      return 401;
    }
  }
};
export const getCompanyJobList = async (company_id) => {
  const [header] = setUserToken();
  try {
    const response = await axios.get(companyJobList + company_id + "/", {
      headers: header,
    });
    if (response.status === 200) {
      const companyJobs = response.data;
      // console.log(companyJobs);
      return companyJobs;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return 500;
    } else {
      return 401;
    }
  }
};
export const getAllFilesTypeInstaller = async (company_id) => {
  const [header] = setUserToken();
  // const company_id = sessionUser["company_id"];

  try {
    const fileResponse = await axios.get(
      allFilesTypeInstallerApi + company_id + "/",
      {
        headers: header,
      }
    );
    if (fileResponse.status === 200) {
      return fileResponse.data;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return "Internal server errror";
    } else {
      return "Something went wrong";
    }
  }
};
export const createFileType = async (fileTypeDetails, company_id) => {
  const [header] = setUserToken();
  try {
    const fileResponse = await axios.post(
      allFilesTypeInstallerApi + company_id + "/",
      fileTypeDetails,
      {
        headers: header,
      }
    );
    if (fileResponse.status === 200) {
      // console.log("fileResponse", fileResponse);
      return fileResponse;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    }
    if (error.response.status === 409) {
      return "Name already exists";
    } else {
      return "Something went wrong";
    }
  }
};
export const editFileType = async (fileTypeDetails, company_id) => {
  const [header] = setUserToken();
  // const fd = { ...fileTypeDetails, company: company_id };
  // fileTypeDetails.push({ company: company_id });
  try {
    const fileResponse = await axios.patch(
      allFilesTypeInstallerApi + company_id + "/",
      fileTypeDetails,
      {
        headers: header,
      }
    );
    if (fileResponse.status === 200) {
      return fileResponse;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return "Internal server errror!!";
    } else {
      return "Something went wrong!!";
    }
  }
};

export const deletefileType = async (id) => {
  const [header] = setUserToken();
  try {
    const fileResponse = await axios.delete(
      allFilesTypeInstallerApi + id + "/",
      {
        headers: header,
      }
    );
    if (fileResponse.status === 204) {
      return "success";
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    } else {
      return "Something went wrong";
    }
  }
};
export const getAllCompanyCommonFiles = async (company_id) => {
  const [header] = setUserToken();

  try {
    const fileResponse = await axios.get(commonfile + company_id + "/", {
      headers: header,
    });
    if (fileResponse.status === 200) {
      return fileResponse.data;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return 500;
    } else {
      return 400;
    }
  }
};
export const createCommonFile = async (fileDetails, company_id) => {
  const [header] = setUserToken();
  console.log(company_id);
  try {
    const fileResponse = await axios.post(
      commonfile + company_id + "/",
      fileDetails,
      {
        headers: header,
      }
    );
    if (fileResponse.status === 200) {
      // console.log("fileResponse", fileResponse);
      return "success";
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    }
    if (error.response.status === 409) {
      return "Name already exists";
    } else {
      return "Something went wrong";
    }
  }
};
export const editCommonFile = async (fd, company_id) => {
  const [header] = setUserToken();
  try {
    const fileResponse = await axios.patch(commonfile + company_id + "/", fd, {
      headers: header,
    });
    if (fileResponse.status === 200) {
      return "success";
    }
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return "Internal server errror!!";
    } else if (error.response.status === 400) {
      return "Please refresh and try again";
    } else {
      return "Something went wrong!!";
    }
  }
};

export const deleteCommonFile = async (id) => {
  const [header] = setUserToken();
  try {
    const fileResponse = await axios.delete(commonfile + id + "/", {
      headers: header,
    });
    if (fileResponse.status === 204) {
      return "success";
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    } else {
      return "Something went wrong";
    }
  }
};

export const retrieveFields = async (company_id, file_id) => {
  const [header] = setUserToken();
  try {
    const fieldResponse = await axios.get(
      retrieveFileFieldAPI + company_id + "/" + file_id + "/",
      { headers: header }
    );
    if (fieldResponse.status === 200) {
      return fieldResponse;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    } else if (error.response.status === 404) {
      return "File not found!";
    } else {
      return "Something went wrong";
    }
  }
};

export const saveUploadedField = async (
  targetList,
  databaseTable,
  fileType
) => {
  const data = {
    targetList: targetList,
    databaseTable: databaseTable,
    fileType: fileType,
  };
  const [header] = setUserToken();
  try {
    const fileResponse = await axios.post(saveUploadeFileFieldAPI, data, {
      headers: header,
    });
    if (fileResponse.status === 200) {
      return fileResponse;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    } else {
      return "Something went wrong";
    }
  }
};
