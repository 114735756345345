import React, { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RegisterUserInvitation } from "../../Services/CompanyAPI";
import { Buffer } from "buffer";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const RegisterNewUser = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const invitationToken = searchParams.get("token");
  const encodePk = searchParams.get("compy");
  const encodeEmail = searchParams.get("em");
  const decodedEmail = Buffer.from(encodeEmail, "base64").toString("ascii");
  //   console.log(invitationToken, encodePk, decodedEmail);
  const data = { token: invitationToken, encodePk: encodePk };
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [rePasswordShown, setRePasswordShown] = useState(false);
  const [details, setDetails] = useState({
    encodePk: encodePk,
    token: invitationToken,
    email: decodedEmail,
    first_name: "",
    last_name: "",
    password: "",
    repassword: "",
    unit: "",
    street: "",
    city: "",
    country: "",
    postcode: "",
    phone: "",
    state: "",
  });
  const showHidePassword = (pass) => {
    if (pass === "new") {
      setNewPasswordShown(!newPasswordShown);
    }
    if (pass === "re") {
      setRePasswordShown(!rePasswordShown);
    }
  };
  const regUser = async (event) => {
    event.preventDefault();
    console.log(details);

    if (details.repassword === details.password) {
      // ResetPasswordAuth
      await RegisterUserInvitation(details).then((result) => {
        if (result.status !== 200) {
          if (result.status === 400) {
            console.log("datachange password", result.data.password);
            seterrorMessage(result.data.password);
          } else {
            seterrorMessage(result.data);
            setsuccessMessage("");
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log("else", result);
          setsuccessMessage(result.data);
          seterrorMessage("");
        }
      });
    } else {
      seterrorMessage("Password did not match!");
    }
  };

  useEffect(() => {
    seterrorMessage("");
    setsuccessMessage("");
  }, [details]);

  return (
    <div className="mt-2">
      <div className="row mb-3">
        <div className="col-3">Register</div>
        <div className="col-9">
          {" "}
          {errorMessage !== "" ? (
            <span className="error__message">{errorMessage}</span>
          ) : (
            ""
          )}
          {successMessage !== "" ? (
            <span className="success__message">{successMessage}</span>
          ) : (
            ""
          )}
        </div>
      </div>

      <form onSubmit={regUser}>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingEmail"
            placeholder="Email"
            defaultValue={decodedEmail}
            readOnly
          />
          <label htmlFor="floatingEmail">Email</label>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type={newPasswordShown ? "text" : "password"}
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                required
                onChange={(e) =>
                  setDetails({ ...details, password: e.target.value })
                }
              />
              <span
                className="eye__span"
                onClick={() => showHidePassword("new")}
              >
                {newPasswordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
              </span>
              <label htmlFor="floatingPassword">Password</label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type={rePasswordShown ? "text" : "password"}
                className="form-control"
                id="floatingRePassword"
                placeholder="RePassword"
                required
                onChange={(e) =>
                  setDetails({ ...details, repassword: e.target.value })
                }
              />
              <span
                className="eye__span"
                onClick={() => showHidePassword("re")}
              >
                {rePasswordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
              </span>
              <label htmlFor="floatingRePassword">Re Password</label>
            </div>
          </div>
        </div>
        <div className="row mt-1 mb-3">
          <div className="col">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="floatingName"
                placeholder="Firstname"
                required
                onChange={(e) =>
                  setDetails({ ...details, first_name: e.target.value })
                }
              />
              <label htmlFor="floatingInput">Firstname</label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating ">
              <input
                type="text"
                className="form-control"
                id="floatingLastname"
                placeholder="Lastname"
                required
                onChange={(e) =>
                  setDetails({ ...details, last_name: e.target.value })
                }
              />
              <label className="" htmlFor="floatingLastname">
                Lastname
              </label>
            </div>
          </div>
        </div>
        {/* start company address */}
        Address
        <div className="row">
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingUnit"
                placeholder="Unit"
                required
                onChange={(e) =>
                  setDetails({ ...details, unit: e.target.value })
                }
              />
              <label htmlFor="floatingUnit">Unit</label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating mb-3 col">
              <input
                type="text"
                className="form-control"
                id="floatingStreet"
                placeholder="Street"
                required
                onChange={(e) =>
                  setDetails({ ...details, street: e.target.value })
                }
              />
              <label htmlFor="floatingStreet">Street</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingCity"
                placeholder="City"
                required
                onChange={(e) =>
                  setDetails({ ...details, city: e.target.value })
                }
              />
              <label htmlFor="floatingCity">City</label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingState"
                placeholder="State"
                required
                onChange={(e) =>
                  setDetails({ ...details, state: e.target.value })
                }
              />
              <label htmlFor="floatingState">State</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingCountry"
                placeholder="Country"
                required
                onChange={(e) =>
                  setDetails({ ...details, country: e.target.value })
                }
              />
              <label htmlFor="floatingCountry">Country</label>
            </div>
          </div>
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="number"
                className="form-control"
                id="floatingPostcode"
                placeholder="Postcode"
                required
                onChange={(e) =>
                  setDetails({ ...details, postcode: e.target.value })
                }
              />
              <label htmlFor="floatingPostcode">Postcode</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-floating mb-3 col">
              <input
                type="number"
                className="form-control"
                id="floatingPhone"
                placeholder="Phone"
                required
                onChange={(e) =>
                  setDetails({ ...details, phone: e.target.value })
                }
              />
              <label htmlFor="floatingPhone">Phone</label>
            </div>
          </div>
          <div className="col form-floating">
            <button className="btn btn-primary form-control" type="submit">
              Save
            </button>
          </div>
        </div>
        {/* end company address */}
        {/* <button className="btn btn-primary mb-3" type="submit">
          Save
        </button> */}
      </form>
    </div>
  );
};

export default RegisterNewUser;
