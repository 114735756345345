import React from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import "./ModalAddRetailerForms.css";
import {
  createRetailerForm,
  deleteRetailerForm,
  editRetailerForm,
  getAllRetailerForms,
  getAllRetailers,
  getUser,
} from "../../Services";

const ModalAddRetailerForms = ({ showModalRF, ohShow, onHide }) => {
  const [retailFormList, setRetailFormList] = useState([]);
  const [allRetailers, setAllRetailers] = useState([]);
  const [retailSelection, setRetailSelection] = useState({});
  const [retailFormDetails, setRetailFormDetails] = useState({
    id: "",
    file_type: "",
    file_details: "",
    to_email: "",
    retailer: "",
  });
  const clearRetailerForm = (dont_clear_all) => {
    if (dont_clear_all !== "dont_clear_all") {
      setRetailSelection([]);
      setRetailFormList([]);
    }
    setRetailFormDetails({
      id: "",
      file_type: "",
      file_details: "",
      to_email: "",
      retailer: retailSelection.id,
    });
  };
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUser = getUser();
  const isEven = (index) => index % 2 === 0;
  const user = getUser();
  const is_superuser = user ? user["is_superuser"] ?? false : false;

  const fetchAllRetailerForms = async (retailer_id) => {
    const allRetailForms = await getAllRetailerForms(retailer_id);
    // console.log(allFiles);
    if (allRetailForms === 500 || allRetailForms === 400) {
    } else {
      setRetailFormList(allRetailForms);
    }
  };
  const fetchAllRetailers = async () => {
    const allR = await getAllRetailers();
    if (allR === 500 || allR === 400) {
    } else {
      setAllRetailers(allR);
    }
  };
  setTimeout(() => {
    seterrorMessage("");
    setSuccessMessage("");
  }, 10000);
  const handleDropdownChange = (retailer) => {
    // console.log("change", e.target, detail);
    setRetailSelection(retailer);
    fetchAllRetailerForms(retailer.id);
    setRetailFormDetails({
      id: "",
      file_type: "",
      file_details: "",
      to_email: "",
      retailer: retailer.id,
    });
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let result;
    if (retailFormDetails.id === "") {
      //code for adding new file category
      result = await createRetailerForm(retailFormDetails);
      // console.log("added", result);
      setIsLoading(false);
    } else {
      //code for editing existing file category
      result = await editRetailerForm(retailFormDetails);
      console.log("edited", result);
      setIsLoading(false);
    }
    if (result === "success") {
      setSuccessMessage("Item Saved!");
      fetchAllRetailerForms(retailSelection.id);
      clearRetailerForm("dont_clear_all");
    } else {
      seterrorMessage(result);
    }
  };
  const deleteFileType = async (id) => {
    if (id === "") {
      seterrorMessage("No item selected!!");
    } else {
      if (window.confirm("Delete " + retailFormDetails.file_type + "?")) {
        const deleted = await deleteRetailerForm(id);
        if (deleted === "success") {
          clearRetailerForm("dont_clear_all");
          setSuccessMessage("Item deleted1");
          fetchAllRetailerForms(retailSelection.id);
          setSuccessMessage("Item deleted");
        } else {
          seterrorMessage(deleted);
        }
      } else {
        console.log("not deleted");
      }
    }
  };
  useEffect(() => {
    is_superuser && fetchAllRetailers();
  }, [showModalRF]);

  return (
    <>
      <Modal
        className="modal__file"
        size="lg"
        show={showModalRF}
        onHide={() => {
          onHide();
          clearRetailerForm();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit file type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <label>
              Energy Retailer
              <div className=" m-0 dd__main ">
                <Dropdown className="border">
                  <Dropdown.Toggle
                    variant="none"
                    id="dropdown-basic"
                    className=""
                  >
                    <div className="dd__selected">
                      {retailSelection.retailer_name
                        ? retailSelection.retailer_name
                        : "--Choose One--"}
                      <FaCaretDown size={25} />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ overflow: "scroll", height: 300, width: 200 }}
                  >
                    {allRetailers.map((x, y) => (
                      <Dropdown.Item
                        key={y}
                        eventKey={x.retailer_name}
                        onClick={() => {
                          handleDropdownChange(x);
                        }}
                        defaultValue={x.id}
                        className=""
                      >
                        {x.retailer_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </label>
          </div>
          <div className="modal__div row">
            <div className="col-6 ">File Type (Click to edit or delete)</div>
            <div className="col-6 ">Details</div>
          </div>
          <div className="row">
            <div className="col-6 ">
              {retailFormList.length > 0 ? (
                <ol>
                  {showModalRF &&
                    retailFormList?.map((d, index) => (
                      <li
                        key={d.id}
                        onClick={() =>
                          setRetailFormDetails({
                            id: d.id,
                            file_type: d.file_type,
                            file_details: d.file_details,
                            retailer: d.retailer,
                            to_email: d.to_email ?? "",
                          })
                        }
                        style={{ cursor: "pointer" }}
                        className={isEven(index) ? "bg__even" : "bg__odd"}
                      >
                        {d.file_type}{" "}
                      </li>
                    ))}
                </ol>
              ) : (
                <p className="mt-5 text-danger">
                  Nothing found. Please select Energy Retailer
                  <br />
                  Or add new.
                </p>
              )}
            </div>
            <div className="col-6 ">
              <form onSubmit={submitHandler}>
                <label>
                  File Name:
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    defaultValue={retailFormDetails.file_type}
                    required
                    readOnly={retailSelection.id ? false : true}
                    onChange={(e) =>
                      setRetailFormDetails({
                        ...retailFormDetails,
                        file_type: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Description:
                  <input
                    className="form-control"
                    type="text"
                    name="desc"
                    defaultValue={retailFormDetails.file_details}
                    required
                    readOnly={retailSelection.id ? false : true}
                    onChange={(e) =>
                      setRetailFormDetails({
                        ...retailFormDetails,
                        file_details: e.target.value,
                      })
                    }
                  />
                </label>

                <label>
                  To email (Edit form retailer form.)
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    defaultValue={retailFormDetails.to_email}
                    readOnly={retailSelection.id ? false : true}
                    required
                    onChange={(e) =>
                      setRetailFormDetails({
                        ...retailFormDetails,
                        to_email: e.target.value,
                      })
                    }
                  />
                </label>
                <div className="top__button">
                  <Button
                    type="Submit"
                    variant="primary"
                    disabled={retailSelection.id ? false : true}
                    // onClick={() => addEditFileType(retailFormDetails)}
                  >
                    {(retailFormDetails.id && "Save") || "Add new"}
                  </Button>
                  <Button
                    variant="danger"
                    disabled={retailFormDetails.id ? false : true}
                    onClick={() => deleteFileType(retailFormDetails.id)}
                  >
                    Delete
                  </Button>
                </div>
              </form>
              <div
                className={errorMessage ? "error__message" : "success__message"}
              >
                {errorMessage ? errorMessage : successMessage}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer__button">
            <Button
              variant="primary"
              onClick={() => clearRetailerForm("dont_clear_all")}
            >
              Clear
              {/* {(retailFormDetails && "Clear") || "Add new"} */}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                clearRetailerForm();
                onHide();
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAddRetailerForms;
