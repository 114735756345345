// import "../Customer/Customer.css";
import { useState, useEffect, useMemo } from "react";
import { getCompanyJobList, getSelectedCompany } from "../../Services";
import { images } from "../../Constants";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { Link, useNavigate } from "react-router-dom";
import { JobTable } from "./JobTable";
import { GlobalFilter } from "./JobTable/globalFilter";

const InstallerPage = () => {
  const [companyJobList, setCompanyJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const selectedCompany = getSelectedCompany();

  const fetchCompanyJobs = async () => {
    // console.log("testing");
    setIsLoading(true);
    const companyJobs = await getCompanyJobList(selectedCompany[0]["id"]);
    // console.log(companyJobs["jobs"], companyJobs["company_cust"], companyJobs);
    // setCompanyJobList(companyJobs["jobs"]);
    if (companyJobs === 500 || companyJobs === 401) {
      setCompanyJobList([]);
    } else {
      // console.log("value");
      setCompanyJobList(companyJobs["jobs"]);
    }

    setIsLoading(false);
  };

  const companyJobsMemo = useMemo(() => [...companyJobList], [companyJobList]);
  // console.log(companyJobList);
  const companyJobColumns = useMemo(
    () =>
      companyJobList[0]
        ? Object.keys(companyJobList[0]).map((key, index) => {
            // console.log(key, "here");
            if ((key === "city") | (key === "street"))
              return {
                Header: key,
                accessor: key,
                Cell: ({ value, row }) => (
                  <div className="text__email__address">{value}</div>
                ),
              };
            if (key === "job_number")
              return {
                Header: key,
                accessor: key,
                Cell: ({ value, row }) => (
                  <div className="job_number__stick">{value}</div>
                ),
              };
            if ((key === "company") | (key === "job_number"))
              return {
                Header: key,
                accessor: key,
                Cell: ({ value, row }) => (
                  <div className="text__wrap">
                    <Link
                      className="another__link "
                      to={{
                        // pathname: `/customer-profile/${row.original.id}/`,
                        state: { companyJobList },
                      }}
                    >
                      {value}
                    </Link>
                  </div>
                ),
              };

            return {
              Header: key,
              accessor: key,
              Cell: ({ value, row }) => (
                <div className="">
                  {row.details}
                  {value}
                </div>
              ),
            };
          })
        : [],
    [companyJobList]
  );

  //this hook is for extra column not available in data column 13/02/2022 -Pramesh

  //   const tableHooks = (hooks) => {
  //     hooks.visibleColumns.push(
  //       (columns) => [
  //         ...columns,
  //         {
  //           id: "Preview",
  //           Header: "Preview",
  //           Cell: ({ row }) => (
  //             <Link
  //               to={{
  //                 pathname: `/customer-profile/${row.values.id}`,
  //                 search: "?sort=name",
  //                 hash: "#the-hash",
  //                 state: "true",
  //               }}
  //             >
  //               Preview
  //             </Link>
  //           ),
  //         },
  //       ]
  //       // <button onClick={()=>alert("Edit:"+row.values.price)}>Edit</button>
  //     );
  //   };
  const tableInstance = useTable(
    { columns: companyJobColumns, data: companyJobsMemo },
    useGlobalFilter,
    // tableHooks,
    useSortBy,
    usePagination
  );
  const { preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  useEffect(() => {
    fetchCompanyJobs();
  }, []);
  return (
    // <div className='customer__details'><Search details={details}/></div>

    <div className="main__div">
      {!isLoading ? (
        <>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={state.globalFilter}
          />
          <div className="customer__details">
            <JobTable tableInstance={tableInstance} />
          </div>
        </>
      ) : (
        <div className="loading__img_div">
          {" "}
          <img
            className="loading__img"
            src={images.loadingCircle}
            width="100px"
            height="100px"
          ></img>
          <div>...Loading Customer List</div>
        </div>
      )}
    </div>
  );
};

export default InstallerPage;
