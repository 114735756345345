import { useLocation, Navigate, Outlet } from "react-router-dom";
import { Dashboard, HomePage, LoginPage, RegisterPage } from "../../Pages";

import { getUser } from "../../Services";

export const PublicRoute = () => {
  const sessionUser = getUser("Public Route");
  const location = useLocation();
  // console.log("public route", location.pathname, sessionUser);
  return sessionUser ? (
    <Dashboard />
  ) : location.pathname === "/login" ? (
    <LoginPage />
  ) : location.pathname === "/register" ? (
    <RegisterPage />
  ) : (
    <HomePage />
  );
};

export const PrivateRoute = () => {
  const sessionUser = getUser("private route");
  const location = useLocation();
  // console.log("requireauth private", sessionUser);
  return sessionUser ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export const ProtectedRoute = () => {
  const sessionUser = getUser("require auth");
  console.log("requireauth protected", sessionUser);
  const location = useLocation();
  return sessionUser && sessionUser["is_staff"] ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
export const InstallerRoute = () => {
  const sessionUser = getUser("require auth");
  const location = useLocation();
  // console.log(
  //   "installer route",
  //   sessionUser["is_installer"],
  //   sessionUser["admin"]
  // );
  return sessionUser["is_installer"] ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
