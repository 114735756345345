import "./CustomerActivity.css";
import { FaAngleRight, FaAngleDown, FaRegEdit, FaSave } from "react-icons/fa";
import { useRef, useState, useCallback } from "react";
import { FaCaretDown } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { filesUpload, mediaUrl, TestPDF } from "../../Constants";
import axios from "axios";
import { ButtonUpload, Message, Progress } from "../../Components";
import {
  editCustomerAllDetails,
  getAllRetailerForms,
  getAllRetailers,
  getIsAdminSession,
  getSelectedCompany,
  getUser,
} from "../../Services";
import FileViewerModal from "../FileViewer/FileViewer";
import ImageViewer from "react-simple-image-viewer";
import { images } from "../../Constants";
import ModalApplyPV from "../ModalApplyPV/ModalApplyPV";
import { useEffect } from "react";
import ModalSendForSign from "../ModalSendForSign/ModalSendForSign";

const CustomerActivity = ({
  isLoading,
  customer,
  allFileTypes,
  customer_id,
  fetchSingleCustomer,
}) => {
  const [showDropdownSerial, setShowDropdownSerial] = useState(false);
  const [showDropdownInverter, setShowDropdownInverter] = useState(false);
  const [showDropdownInstallation, setShowDropdownInstallation] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [message, setUploadMessage] = useState("Uploaded");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [editFile, setEditFile] = useState({});
  const [modalFile, setModalFile] = useState();
  const [shown, setShown] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [applyPvModal, setApplyPvModal] = useState(false);
  const [retailSelection, setRetailSelection] = useState({});
  const [retailFormList, setRetailFormList] = useState([]);
  const [allRetailers, setAllRetailers] = useState([]);
  const [requestSentMessage, setRequestSentMessage] = useState("");
  const [requestNSentMessage, setRequestNSentMessage] = useState("");
  const [isInstallationViewerOpen, setIsInstallationViewerOpen] =
    useState(false);
  const [changeDDLRetailer, setChangeDDLRetailer] = useState(false);
  const [isInverterViewerOpen, setIsInverterViewerOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [sendForSign, setSendForSign] = useState(false);
  const [fileDetails, setFileDetails] = useState({});

  const sessionUser = getUser();
  // console.log(sessionUser);
  // const is_admin = (sessionUser && sessionUser["is_admin"]) ?? false;
  const is_admin = getIsAdminSession("is_admin");
  const isEven = (index) => index % 2 === 0;

  const customerFiles = customer.customer_files ?? [];
  const split_string = (title) => title.split("_").join(" ");
  // const customerName = (
  //   (customer.Firstname == null ? "" : customer.Firstname) +
  //   (customer.Lastname == null ? "" : customer.Lastname)
  // ).replace(/ /g, "");

  const customerJob = customer.customer_job[0] || {};
  const header = {
    Authorization: "token " + sessionUser.token,
    // "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
  };
  let hasF_id = [];
  // let hasF = []
  customerFiles.forEach((element) => {
    hasF_id.push(element.file_type_id);
  });
  // console.log("all files", allFileTypes, "hasf", hasF_id);
  const hasNF = allFileTypes.filter((n) => !hasF_id.includes(n.id));
  const hasF = allFileTypes.filter((n) => hasF_id.includes(n.id));
  const hasRF = retailFormList.filter((n) => hasF_id.includes(n.id));
  const hasNRF = retailFormList.filter((n) => !hasF_id.includes(n.id));

  // console.log("hasnf", hasNF, "hasf", hasF);
  let inverterList = [];
  let installationList = [];
  let panelSerialList = [];
  const selectedCompany = getSelectedCompany();
  const openImageViewer = useCallback((index, where) => {
    setCurrentImage(index);
    if (where == "inverter") {
      setIsInverterViewerOpen(true);
    }
    if (where == "installation") {
      setIsInstallationViewerOpen(true);
    }
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsInstallationViewerOpen(false);
    setIsInverterViewerOpen(false);
  };
  // console.log(customerJob);
  const upoladHandler = async (divId, oldSpanId) => {
    // console.log("upload handler", divId, oldSpanId);
    setIsUploading(true);
    const fd = new FormData();
    if (selectedFile) {
      fd.append("file", selectedFile, selectedFile.name);
      fd.append("customer_id", customer.id);
      fd.append("job_number", customerJob.job_number);
      fd.append("name", selectedFile.name);
      fd.append("file_typ", editFile.filetype);
      fd.append("file_type_id", editFile.file_type_id);
      fd.append("company", selectedCompany[0]["id"]);

      // const data = { file: selectedFile, customer_id: 155, job_number: "DSD000" };
      try {
        const response = await axios.post(filesUpload, fd, {
          headers: header,
          // onUploadProgress: (progressEvent) => {
          //   console.log(
          //     "Upload progress:" +
          //       parseInt((progressEvent.loaded / progressEvent.total) * 100) +
          //       "%"
          //   );
          //   setUploadPercentage(
          //     parseInt(
          //       Math.round(progressEvent.loaded / progressEvent.total) * 100
          //     )
          //   );
          //   // clear percentage
          //   setTimeout(() => setUploadPercentage(0), 10000);
          // },
        });
        // console.log("resp", response.status);
        if (response.status === 200) {
          // const { success, filePath, uploadedFileName } = response.data;
          setUploadedFile(response.data);
          setUploadMessage("Uploaded");
          setSelectedFile();
          let file__name = document.getElementById(divId);
          let newSpan = document.createElement("Span");
          // newSpan.setAttribute("onclick", "alert('blah');");
          newSpan.onclick = function () {
            viewFile(mediaUrl + response.data.filePath);
          };
          newSpan.appendChild(
            document.createTextNode(response.data.uploadedFileName)
          );
          document.getElementById(oldSpanId)?.remove();
          newSpan.setAttribute("id", oldSpanId);
          file__name.appendChild(newSpan);
          // fetchSingleCustomer();
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          if (error.response.status === 500) {
            setUploadMessage("Internal server error");
          } else {
            setUploadMessage("File not uploaded");
          }
        } else {
          setUploadMessage("Network Error");
        }
      }
    } else {
      setUploadMessage("No file selected");
    }
    setTimeout(() => setUploadMessage(""), 10000);
    setIsUploading(false);
  };
  const editClickHandler = (filetype, file_type_id) => {
    // console.log(filetype, file_type_id);
    if (editFile?.filetype == filetype) {
      setEditFile({});
    } else {
      setEditFile({
        ...editFile,
        filetype: filetype,
        file_type_id: file_type_id,
      });
    }
    setSelectedFile();
    setUploadMessage("");
  };

  const viewFile = (file_path, filename) => {
    const fileExt = file_path ? file_path.split(".").pop() : "";
    //removed fileviewer
    // if (fileExt == "pdf") {
    window.open(mediaUrl + file_path, "_blank");
    // } else {
    //   setModalFile({ file_path, filename });
    //   setShown(true);
    // }
  };
  const applyPV = () => {
    setApplyPvModal(true);
  };
  const sendForSignature = (file_type_id, file, filename) => {
    console.log(file_type_id);
    setFileDetails({
      file_type_id: file_type_id,
      file: mediaUrl + file,
      filename: filename,
    });
    console.log("send for signature");
    setSendForSign(true);
  };
  const testEmail = async () => {
    const testResult = await axios.post(TestPDF, {
      email: customer.email,
    });
    console.log(testResult);
  };

  const fetchAllRetailers = async () => {
    const allR = await getAllRetailers();
    if (allR === 500 || allR === 400) {
    } else {
      setAllRetailers(allR);
    }
  };
  const fetchAllRetailerForms = async (retailer_id) => {
    // console.log(retailer_id);
    const allRetailForms = await getAllRetailerForms(retailer_id);
    // console.log(allRetailForms);
    if (allRetailForms === 500 || allRetailForms === 400) {
    } else {
      setRetailFormList(allRetailForms);
    }
  };
  const handleDropdownChange = (retailer) => {
    // console.log("change", e.target, detail);
    setRetailSelection(retailer);
  };
  const saveRetailer = async () => {
    if (
      window.confirm(
        "This change will delete all the files related to old Energy retailer. This can not be UNDONE."
      )
    ) {
      console.log(retailSelection); //save
      const saved = await editCustomerAllDetails(
        {
          job_number: customerJob["job_number"],
          energy_retailer: retailSelection.id,
          old_retailer: customerJob["energy_retailer_id"],
          save_retailer: "True",
        },
        "False"
      );

      // fetchAllRetailerForms(retailSelection.id);
      fetchSingleCustomer();
      setChangeDDLRetailer(false);
    }
  };
  // console.log(retailFormList);
  const changeRetailerRequest = async () => {
    console.log(customerJob);
    console.log("function");
    const saved = await editCustomerAllDetails(
      { job_number: customerJob["job_number"], retailer_request: "True" },
      "False"
    );
    console.log(saved);
    if (saved == 200) {
      setRequestSentMessage("Request Sent");
    } else {
      setRequestSentMessage("Not Sent");
    }
    setTimeout(() => {
      setRequestSentMessage("");
      setRequestNSentMessage("");
    }, 1000);
  };

  useEffect(() => {
    fetchAllRetailers();
    customerJob["energy_retailer_id"] &&
      fetchAllRetailerForms(customerJob["energy_retailer_id"]);
  }, []);
  // console.log(is_admin, customerJob["retailer_request"]);
  return (
    <>
      <ModalApplyPV
        openModal={applyPvModal}
        ohShow={() => setApplyPvModal(true)}
        onHide={() => setApplyPvModal(false)}
        files={customerFiles}
        customer_id={customer_id}
        customer={customer}
      />
      <ModalSendForSign
        openModal={sendForSign}
        ohShow={() => setSendForSign(true)}
        onHide={() => setSendForSign(false)}
        fileDetails={fileDetails}
        customer_id={customer_id}
        customer={customer}
        user_id={sessionUser.id}
      />
      {isLoading ? (
        <div>Content Loading...</div>
      ) : (
        <div className="main__div">
          <FileViewerModal
            file={modalFile}
            shown={shown}
            onHide={() => setShown(false)}
          />{" "}
          {/* {uploadPercentage ? <Progress percentage={uploadPercentage} /> : null} */}
          <div className="headings__all row">
            {/* <button className="btn btn-primary" onClick={testEmail}>
              Test pdf
            </button> */}

            <div className="col d-flex justify-content-between ">
              <div>Files</div>
              {sessionUser["is_installer"] ? (
                <div className="bg-success text-light p-1">
                  {customerJob["pv_applied"] ? (
                    "PV Done"
                  ) : (
                    <button
                      className="btn btn-success text-light"
                      onClick={customerJob["pv_applied"] ? () => {} : applyPV}
                    >
                      {customerJob["pv_applied"] ? "PV Applied" : "Apply PV"}
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div>
            <span className="headings__all d-flex justify-content-between">
              Energy Retailer
              {is_admin && customerJob["retailer_request"] && (
                <em className="text-danger" style={{ textEmphasis: "filled" }}>
                  Change Requested
                </em>
              )}
            </span>
            {/* energy retailer dropdown starts */}
            {is_admin &&
              (customerJob?.retailer_request || changeDDLRetailer) && (
                <>
                  <div className="d-flex justify-content-between">
                    <Dropdown className="border">
                      <Dropdown.Toggle
                        variant="none"
                        id="dropdown-basic"
                        className=""
                      >
                        <div className="dd__selected">
                          {retailSelection.retailer_name
                            ? retailSelection.retailer_name
                            : "--Choose One--"}
                          <FaCaretDown size={25} />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{ overflow: "scroll", height: 300, width: 200 }}
                      >
                        {" "}
                        <div className="d-flex">
                          <img id="searchIcon" src={images.search_icon}></img>
                          <input
                            className="border-bottom"
                            type="text"
                            placeholder="Search.."
                            onChange={(e) => setSearchKey(e.target.value)}
                          />
                        </div>
                        {allRetailers
                          .filter((x) =>
                            x.retailer_name
                              .toLowerCase()
                              .includes(searchKey.toLowerCase())
                          )
                          .map((x, y) => (
                            <Dropdown.Item
                              key={y}
                              eventKey={x.retailer_name}
                              onClick={() => {
                                handleDropdownChange(x);
                              }}
                              defaultValue={x.id}
                              className=""
                            >
                              {x.retailer_name}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className="btn" onClick={() => saveRetailer()}>
                      Save
                    </span>
                  </div>
                </>
              )}
            {/* energy retailer dropdown ends */}
            {/* if job has energy retailer id */}
            <div className="d-flex justify-content-between">
              {customerJob["energy_retailer_id"] ? (
                <div>
                  {
                    <span className="text-info text-uppercase ">
                      {allRetailers &&
                        allRetailers.filter(
                          (a) => a.id == customerJob["energy_retailer_id"]
                        )[0]?.retailer_name}{" "}
                    </span>
                  }{" "}
                </div>
              ) : (
                <span>No retailer selected</span>
              )}{" "}
              {/* {requestSent ? "Request Sent" : "Not Sent"} */}
              <em
                className="text-primary"
                role="button"
                onClick={() =>
                  is_admin
                    ? setChangeDDLRetailer(!changeDDLRetailer)
                    : changeRetailerRequest()
                }
              >
                {requestNSentMessage || requestSentMessage ? (
                  <span>
                    {requestNSentMessage}
                    {requestSentMessage}
                  </span>
                ) : (
                  customerJob["energy_retailer_id"] &&
                  (is_admin
                    ? changeDDLRetailer
                      ? "Cancel"
                      : "Change"
                    : "Change Request")
                )}
              </em>
            </div>
          </div>
          {customerFiles.map((obj, i) => {
            return (
              <div key={i} className="customer__title__details">
                <span className="title">{obj.file_type}</span>

                <div className="title__details">
                  <div className="file__name" id={obj.id + "hasF"}>
                    <span
                      id={obj.id + "hasFs"}
                      onClick={() => viewFile(obj.file, obj.file_name)}
                    >
                      {obj.file_name}
                    </span>
                  </div>
                  {sessionUser["is_installer"] ? (
                    <div className="file__name__buttons">
                      {editFile.filetype == obj.file_type ? (
                        <>
                          <label className="input__label" htmlFor="formFileSm">
                            {selectedFile
                              ? selectedFile.name
                              : uploadedFile
                              ? uploadedFile.name
                              : ""}
                          </label>
                          {isUploading ? (
                            <img
                              className="uploading__img"
                              src={images.loadingCircle}
                              width="20px"
                              height="20px"
                            ></img>
                          ) : message ? (
                            <span
                              className={
                                message == "Uploaded"
                                  ? "uploaded"
                                  : "notUploaded"
                              }
                            >
                              {message}
                            </span>
                          ) : (
                            <ButtonUpload
                              setSelected_file={setSelectedFile}
                              selectedFile={selectedFile}
                              uploadedFile={uploadedFile}
                              upoladHandle={() =>
                                upoladHandler(obj.id + "hasF", obj.id + "hasFs")
                              }
                              sendForSignature={() =>
                                sendForSignature(
                                  obj.id,
                                  obj.file,
                                  obj.file_name
                                )
                              }
                            />
                          )}
                        </>
                      ) : null}
                      <button data-toggle="tooltip" title="Show/Hide buttons">
                        <FaRegEdit
                          onClick={
                            isUploading
                              ? null
                              : () =>
                                  editClickHandler(
                                    obj.file_type,
                                    obj.file_type_id
                                  )
                          }
                          className="button__upload__file"
                        />
                      </button>
                    </div>
                  ) : (
                    <div className="file__name__buttons"></div>
                  )}
                </div>
              </div>
            );
          })}
          {/* retailer form list not having uploaded file starts */}
          {hasNRF.map((obj, i) => {
            return (
              <div key={i} className="customer__title__details">
                <span className="title">{obj.file_type}</span>

                <div className="title__details">
                  <div className="file__name" id={obj.id + "hasF"}>
                    <span
                      id={obj.id + "hasFs"}
                      // onClick={() => viewFile(obj.file, obj.file_name)}
                    >
                      ---
                      {obj.file_name}
                    </span>
                  </div>
                  {sessionUser["is_installer"] ? (
                    <div className="file__name__buttons">
                      {editFile.filetype == obj.file_type ? (
                        <>
                          <label className="input__label" htmlFor="formFileSm">
                            {selectedFile
                              ? selectedFile.name
                              : uploadedFile
                              ? uploadedFile.name
                              : ""}
                          </label>
                          {isUploading ? (
                            <img
                              className="uploading__img"
                              src={images.loadingCircle}
                              width="20px"
                              height="20px"
                            ></img>
                          ) : message ? (
                            <span
                              className={
                                message == "Uploaded"
                                  ? "uploaded"
                                  : "notUploaded"
                              }
                            >
                              {message}
                            </span>
                          ) : (
                            <ButtonUpload
                              setSelected_file={setSelectedFile}
                              selectedFile={selectedFile}
                              uploadedFile={uploadedFile}
                              upoladHandle={() =>
                                upoladHandler(obj.id + "hasF", obj.id + "hasFs")
                              }
                            />
                          )}
                        </>
                      ) : null}
                      <button data-toggle="tooltip" title="Show/Hide buttons">
                        <FaRegEdit
                          onClick={
                            isUploading
                              ? null
                              : () => editClickHandler(obj.file_type, obj.id)
                          }
                          className="button__upload__file"
                        />
                      </button>
                    </div>
                  ) : (
                    <div className="file__name__buttons"></div>
                  )}
                </div>
              </div>
            );
          })}
          {/*retailer form list not having uploaded forms ends */}
          {hasNF.map((obj, i) => {
            return (
              <div key={obj.id} className="customer__title__details">
                <span className="title">{obj.file_type}</span>

                <div className="upload__details">
                  <div className="file__name" id={obj.id + "hasNF"}>
                    <span
                      id={obj.id + "hasNFs"}
                      // onClick={() => viewFile(obj.file)}
                    >
                      ---
                    </span>
                  </div>
                  {sessionUser["is_installer"] ? (
                    <div className="file__name__buttons">
                      {editFile.filetype == obj.file_type ? (
                        <>
                          <label
                            className="custom-file-label input__label"
                            htmlFor="formFileSm"
                          >
                            {"  "}
                            {selectedFile
                              ? selectedFile.name
                              : uploadedFile
                              ? uploadedFile.name
                              : ""}
                          </label>
                          {isUploading ? (
                            <img
                              className="uploading__img"
                              src={images.loadingCircle}
                              width="20px"
                              height="20px"
                            ></img>
                          ) : message ? (
                            <span
                              className={
                                message == "Uploaded"
                                  ? "uploaded"
                                  : "notUploaded"
                              }
                            >
                              {message}
                            </span>
                          ) : (
                            <ButtonUpload
                              setSelected_file={setSelectedFile}
                              selectedFile={selectedFile}
                              uploadedFile={uploadedFile}
                              upoladHandle={() =>
                                upoladHandler(
                                  obj.id + "hasNF",
                                  obj.id + "hasNFs"
                                )
                              }
                            />
                          )}
                        </>
                      ) : null}
                      <button data-toggle="tooltip" title="Show/Hide buttons">
                        <FaRegEdit
                          onClick={
                            isUploading
                              ? null
                              : () => editClickHandler(obj.file_type, obj.id)
                          }
                          className="button__upload__file"
                        />
                      </button>
                    </div>
                  ) : (
                    <div className="file__name__buttons"></div>
                  )}
                </div>
              </div>
            );
            // <div key={obj.id}>{obj.file_type}</div>;
          })}
          {/* Panel serial nubers */}
          <div>
            <div
              className="customer__title__details dropdown__class"
              onClick={() => setShowDropdownSerial(!showDropdownSerial)}
            >
              <span className="title">Panel Serial Numbers:</span>
              <span>
                {showDropdownSerial ? <FaAngleDown /> : <FaAngleRight />}
              </span>
            </div>

            {showDropdownSerial && customerJob.job_panels_serials && (
              <div className="serial__details">
                {customerJob.job_panels_serials.map((panel, idx) => {
                  panelSerialList.push(panel.image);
                  return (
                    <div
                      key={idx}
                      className={
                        isEven(idx) ? "bg__serial__even" : "bg__serial__odd"
                      }
                    >
                      <span>{panel.panel_serial_number}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {/* Inverter Photos*/}
          <div>
            <div
              className="customer__title__details dropdown__class"
              onClick={() => setShowDropdownInverter(!showDropdownInverter)}
            >
              <span className="title">Inverter Photos:</span>{" "}
              <span>
                {showDropdownInverter ? <FaAngleDown /> : <FaAngleRight />}
              </span>
            </div>

            {showDropdownInverter && customerJob.job_inverter && (
              <div className="serial__details">
                {customerJob.job_inverter.map((inverter, idx) => {
                  inverterList.push(inverter.image);
                  return (
                    <div
                      key={idx}
                      className={
                        isEven(idx) ? "bg__serial__even" : "bg__serial__odd"
                      }
                    >
                      <img
                        className="image__dr0pdown"
                        src={inverter.image}
                        onClick={() => openImageViewer(idx, "inverter")}
                      />
                      {isInverterViewerOpen && (
                        <div className="image__viewer">
                          <ImageViewer
                            src={inverterList}
                            currentIndex={currentImage}
                            disableScroll={true}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {/* Inatallation Photos*/}
          <div>
            <div
              className="customer__title__details dropdown__class"
              onClick={() =>
                setShowDropdownInstallation(!showDropdownInstallation)
              }
            >
              <span className="title">Installation Photos:</span>{" "}
              <span>
                {showDropdownInstallation ? <FaAngleDown /> : <FaAngleRight />}
              </span>
            </div>
            {showDropdownInstallation && customerJob.job_photos && (
              <div className="serial__details">
                {customerJob.job_photos.map((jobPhotos, idx) => {
                  installationList.push(jobPhotos.image);
                  // console.log("installation lsit", installationList);
                  return (
                    <div
                      key={idx}
                      className={
                        isEven(idx) ? "bg__serial__even" : "bg__serial__odd"
                      }
                    >
                      <img
                        className="image__dr0pdown"
                        src={jobPhotos.image}
                        onClick={() => openImageViewer(idx, "installation")}

                        // onClick={() =>
                        //   viewFile(jobPhotos.image, "Installation Photos")
                        // }
                      />
                      {isInstallationViewerOpen && (
                        <div className="image__viewer">
                          <ImageViewer
                            src={installationList}
                            currentIndex={currentImage}
                            disableScroll={false}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {Object.keys(customerJob).map((detail, i) => {
            return detail == "back_panel" ||
              detail == "front_of_property" ||
              detail == "installer_image" ? (
              <div key={i} className="customer__title__details">
                <span className="title">{split_string(detail)}</span>
                <div className="title__details">
                  {customerJob[detail] ? customerJob[detail] : "---"}
                </div>
              </div>
            ) : null;
          })}
        </div>
      )}
    </>
  );
};

export default CustomerActivity;
