import axios from "axios";
import React from "react";
import { updateNmi } from "../../Constants";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

const NmiForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [nmi, setNmi] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = searchParams.get("token");
  const em = searchParams.get("em");

  const submitNmi = async (event) => {
    event.preventDefault();
    try {
      const result = await axios.patch(updateNmi, {
        em: em,
        token: token,
        nmi: nmi,
      });
      if (result.status !== 200) {
        seterrorMessage(result.data);
        setsuccessMessage("");
      } else {
        seterrorMessage("");
        setsuccessMessage(result.data);
      }
      setTimeout(() => {
        seterrorMessage("");
        setsuccessMessage("");
      }, 10000);
    } catch (error) {
      seterrorMessage(
        "Something went wrong. Please try again or contact your service provider."
      );
      setsuccessMessage("");
    }
  };
  return (
    <div className="p-1 m-5">
      <div
        className="m-5"
        style={{
          height: 350,
          width: 400,
          alignItems: "center",
        }}
      >
        <p className={errorMessage ? "text-danger" : "text-success"}>
          {errorMessage}
          {successMessage}
        </p>
        Please fill you NMI (National Meter Identifier) below*:
        <form
          onSubmit={submitNmi}
          className="border p-2"
          style={{
            backgroundColor: "rgba(0, 0, 255, 0.1)",
          }}
        >
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingNmi"
              placeholder="NMI"
              required
              onChange={(e) => setNmi(e.target.value)}
            />
            <label htmlFor="floatingNmi">NMI</label>
          </div>
          <input className="btn btn-primary" type="submit" />
        </form>
        <div
          className="text-muted mt-1"
          style={{
            fontSize: 11,
          }}
        >
          *If link is broken or expired, please contact your service provider or
          login with your email and password to update your NMI.
        </div>
      </div>
    </div>
  );
};

export default NmiForm;
