import React from "react";
import { useState } from "react";
import SettingDash from "./Components/SettingDash/SettingDash";
import SideBar from "./Components/Sidebar/SideBar";
import "./SettingPage.css";

const SettingPage = () => {
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [toggleState, setToggleState] = useState("General");
  const toggleTab = (name) => {
    setToggleState(name);
  };
  return (
    <div className="setting__main h-100">
      <div className="home">
        <SideBar toggleTab={toggleTab} toggleState={toggleState} />
      </div>
      <div className="dash">
        <SettingDash toggleState={toggleState} />
      </div>
      {/* <Tabs /> */}
    </div>
  );
};

export default SettingPage;
