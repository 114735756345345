import { useRef } from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { mediaUrl } from "../../Constants";
import {
  createFileType,
  deletefileType,
  editFileType,
  getIsAdminSession,
  getSelectedCompany,
  getAllFilesType,
  retrieveFields,
} from "../../Services";
import ModalChooseField from "../ModalChooseField/ModalChooseField";
import "./ModalFileType.css";

function AddFileType({ showModal, ohShow, onHide }) {
  const [allFileTypes, setAllFileTypes] = useState([]);
  const [showChooseFieldsModal, setChooseFieldsModal] = useState(false);
  const [fileDetails, setFileDetails] = useState({
    id: "",
    file_type: "",
    file_details: "",
    to_email: "",
    file: "",
  });
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [fieldDetails, setFieldDetails] = useState();
  const fileUploadRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const company = getSelectedCompany();
  const is_admin = getIsAdminSession();
  // // console.log(company);
  // console.log(fileUploadRef);
  const fetchAllFilesTypes = async () => {
    const allFiles = await getAllFilesType(company[0]["id"]);
    // console.log(allFiles);
    if (allFiles === 500 || allFiles === 400) {
    } else {
      setAllFileTypes(allFiles);
    }
  };
  const viewFile = (file_path) => {
    // const fileExt = file_path ? file_path.split(".").pop() : "";
    console.log(file_path);
    window.open(mediaUrl + file_path, "_blank");
  };

  const fileSelectHandler = (e) => {
    // console.log(e.target.files);
    setSelectedFile(e.target.files[0]);
  };
  // console.log(selectedFile?.name);
  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let fd = new FormData();
    // console.log(selectedFile);
    if (selectedFile) {
      fd.append("id", "");
      fd.append("file_type", fileDetails.file_type);
      fd.append("file_details", fileDetails.file_details);
      fd.append("to_email", fileDetails.to_email);
      fd.append("company", company[0]["id"]);
      fd.append("file", selectedFile, selectedFile.name);
    }

    let result;
    if (fileDetails.id === "") {
      //code for adding new file category
      // console.log(fileDetails.file_type, "filetype");
      result = await createFileType(fd, company[0]["id"]);
      console.log("added", result);
      // setFileDetails({ id: "", file_type: "", file_details: "", to_email: "" });

      setIsLoading(false);
    } else {
      //code for editing existing file category
      // console.log("edit", fileDetails, allFileTypes);
      // fd.append("id", fileDetails.id);
      let fd = new FormData();
      if (selectedFile) {
        fd.append("id", fileDetails.id);
        fd.append("file_type", fileDetails.file_type);
        fd.append("file_details", fileDetails.file_details);
        fd.append("to_email", fileDetails.to_email);
        fd.append("company", company[0]["id"]);
        fd.append("file", selectedFile, selectedFile.name);
        fd.append("hasFile", true);
      } else {
        fd = { ...fileDetails, hasFile: false };
      }

      result = await editFileType(fd, company[0]["id"]);
      // console.log("edited", result);
      setIsLoading(false);
    }
    if (result.status === 200) {
      setSuccessMessage("Item Saved!");
      setFieldDetails(result.data);
      setChooseFieldsModal(true);
      fileUploadRef.current.value = "";
      fetchAllFilesTypes();
    } else {
      seterrorMessage("Something went wrong");
    }
  };

  const deleteFileType = async (id) => {
    if (id === "") {
      seterrorMessage("No item selected!!");
    } else {
      if (window.confirm("Delete " + fileDetails.file_type + "?")) {
        const deleted = await deletefileType(id);
        if (deleted === "success") {
          setFileDetails({
            id: "",
            file_type: "",
            file_details: "",
            to_email: "",
          });
          setSuccessMessage("Item deleted1");
          fetchAllFilesTypes();
          setSuccessMessage("Item deleted");
        } else {
          seterrorMessage(deleted);
        }
      } else {
        console.log("not deleted");
      }
    }
  };
  const AddEditFields = async () => {
    // console.log(fileDetails);
    const result = await retrieveFields(company[0]["id"], fileDetails.id);
    // console.log(result);
    if (result.status === 200) {
      setFieldDetails(result.data);
      setChooseFieldsModal(true);
    } else {
      console.log("errors", result);
      seterrorMessage(result);
    }
  };
  useEffect(() => {
    seterrorMessage("");
    setSuccessMessage("");
  }, [fileDetails.file_type, fileDetails.file_details]);

  useEffect(() => {
    is_admin && fetchAllFilesTypes();
  }, [showModal]);
  const isEven = (index) => index % 2 === 0;
  // console.log(allFileTypes);

  return (
    <>
      {/* <Button variant="primary" onClick={showModal}>
        Launch demo modal
      </Button> */}

      <Modal
        className="modal__file"
        size="lg"
        show={showModal}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit file type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal__div row">
            <div className="col-6 ">File Type (Click to edit or delete)</div>
            <div className="col-6 ">Details</div>
          </div>
          <div className="row">
            <div className="col-6 ">
              <ol>
                {showModal &&
                  allFileTypes?.map((d, index) => (
                    <li
                      key={d.id}
                      onClick={() =>
                        setFileDetails({
                          id: d.id,
                          file_type: d.file_type,
                          file_details: d.file_details,
                          to_email: d.to_email ?? "",
                          file: d?.file,
                          fileMatched: d.field_matched,
                        })
                      }
                      style={{ cursor: "pointer" }}
                      className={isEven(index) ? "bg__even" : "bg__odd"}
                    >
                      {d.file_type}{" "}
                    </li>
                  ))}
              </ol>
            </div>
            <div className="col-6 ">
              <form onSubmit={submitHandler}>
                <label>
                  File Name:
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={fileDetails.file_type}
                    required
                    onChange={(e) =>
                      setFileDetails({
                        ...fileDetails,
                        file_type: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Description:
                  <input
                    className="form-control"
                    type="text"
                    name="desc"
                    value={fileDetails.file_details}
                    required
                    onChange={(e) =>
                      setFileDetails({
                        ...fileDetails,
                        file_details: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  To email(Email to send this file): Write "Customer" if it's to
                  customer. Multiple email seperate by "comma(,)".
                  <input
                    className="form-control"
                    type="text"
                    name="desc"
                    value={fileDetails.to_email}
                    required
                    onChange={(e) =>
                      setFileDetails({
                        ...fileDetails,
                        to_email: e.target.value,
                      })
                    }
                  />
                </label>{" "}
                File:{" "}
                {fileDetails.file ? (
                  <div className="d-flex">
                    <span
                      className="file__name"
                      onClick={() => viewFile(fileDetails.file)}
                    >
                      {fileDetails.file_type}
                    </span>
                    <span
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={AddEditFields}
                    >
                      {fileDetails.fileMatched ? "Edit" : "Add"} Fields
                    </span>
                  </div>
                ) : (
                  <span className="file__name">No file</span>
                )}
                <label>
                  <input
                    className="form-control"
                    ref={fileUploadRef}
                    type="file"
                    accept=".doc,.docx,application/pdf"
                    id="common-file"
                    name="common-file"
                    required={fileDetails.id === "" ? "required" : ""}
                    //
                    onChange={fileSelectHandler}
                  />
                </label>
                <div className="top__button">
                  <Button
                    type="Submit"
                    variant="primary"
                    // onClick={() => addEditFileType(fileDetails)}
                  >
                    {isLoading
                      ? "Saving..."
                      : (fileDetails.id && "Save") || "Add new"}
                  </Button>
                  <Button
                    variant="danger"
                    disabled={fileDetails.id ? false : true}
                    onClick={() => deleteFileType(fileDetails.id)}
                  >
                    Delete
                  </Button>
                </div>
              </form>
              <div
                className={errorMessage ? "error__message" : "success__message"}
              >
                {errorMessage ? errorMessage : successMessage}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer__button">
            <Button
              variant="primary"
              onClick={() => {
                fileUploadRef.current.value = "";
                setFileDetails({
                  id: "",
                  file_type: "",
                  file_details: "",
                  to_email: "",
                  file: "",
                });
              }}
            >
              Clear
              {/* {(fileDetails && "Clear") || "Add new"} */}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setFileDetails({
                  id: "",
                  file_type: "",
                  file_details: "",
                  to_email: "",
                });
                onHide();
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalChooseField
        showModal={showChooseFieldsModal}
        ohShow={() => setChooseFieldsModal(true)}
        onHide={() => setChooseFieldsModal(false)}
        fieldDetails={fieldDetails}
        fileName={selectedFile?.name}
        fetchAllFilesTypes={fetchAllFilesTypes}
      />
    </>
  );
}

export default AddFileType;
