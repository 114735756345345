import React from "react";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { allRetailersAPI } from "../../Constants";
import "./ModalAddRetailer.css";
import {
  createRetailer,
  deleteRetailer,
  editRetailer,
  getAllRetailers,
  getUser,
} from "../../Services";

const ModalAddRetailers = ({ showModalR, ohShow, onHide }) => {
  const [allRetailers, setAllRetailers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [retailerDetails, setRetailerDetails] = useState({
    id: "",
    retailer_name: "",
    phone: "",
    state: "",
    email: "",
    website: "",
  });
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUser = getUser();
  const isEven = (index) => index % 2 === 0;
  const user = getUser();
  const is_superuser = user ? user["is_superuser"] ?? false : false;

  const clearRetailerDetails = () => {
    setRetailerDetails({
      id: "",
      retailer_name: "",
      phone: "",
      state: "",
      email: "",
      website: "",
    });
  };
  const fetchAllRetailers = async () => {
    const allR = await getAllRetailers();
    if (allR === 500 || allR === 400) {
    } else {
      setAllRetailers(allR);
    }
  };
  setTimeout(() => {
    seterrorMessage("");
    setSuccessMessage("");
  }, 10000);
  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let result;
    if (retailerDetails.id === "") {
      //code for adding new file category
      // console.log(retailerDetails.file_type, "filetype");
      result = await createRetailer(retailerDetails);
      // console.log("added", result);
      clearRetailerDetails();
      setIsLoading(false);
    } else {
      //code for editing existing file category
      result = await editRetailer(retailerDetails);
      // console.log("edited", result);
      setIsLoading(false);
    }
    if (result === "success") {
      setSuccessMessage("Item Saved!");
      clearRetailerDetails();
      fetchAllRetailers();
    } else {
      seterrorMessage(result);
    }
  };
  const deleteRetailerHandler = async (id) => {
    if (id === "") {
      seterrorMessage("No item selected!!");
    } else {
      if (window.confirm("Delete " + retailerDetails.retailer_name + "?")) {
        console.log("con");
        const deleted = await deleteRetailer(id);
        console.log("ad");
        if (deleted === "success") {
          clearRetailerDetails();
          setSuccessMessage("Item deleted1");
          fetchAllRetailers();
          setSuccessMessage("Item deleted");
        } else {
          seterrorMessage(deleted);
        }
      } else {
        console.log("else");
      }
    }
  };
  useEffect(() => {
    is_superuser && fetchAllRetailers();
  }, [showModalR]);

  return (
    <>
      <Modal
        className="retailer__modal"
        size="lg"
        show={showModalR}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/Edit Retailers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" row ">
            <div className="col-5  ml-2">
              Retailers (Click to edit or delete)
            </div>
            <div className="col-6 ">Details</div>
          </div>
          <input
            type="text"
            className="form-control mb-3"
            style={{ width: 200 }}
            id="floatingSearch"
            placeholder="Search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <div className="row">
            <div className="col-6 retailer__list ">
              <ol>
                {showModalR &&
                  allRetailers?.map(
                    (d, index) =>
                      d.retailer_name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) && (
                        <li
                          key={d.id}
                          onClick={() =>
                            setRetailerDetails({
                              id: d.id,
                              retailer_name: d.retailer_name,
                              phone: d.phone,
                              state: d.state,
                              website: d.website,
                              email: d.email ?? "",
                            })
                          }
                          style={{ cursor: "pointer" }}
                          className={isEven(index) ? "bg__even" : "bg__odd"}
                        >
                          {d.retailer_name}{" "}
                        </li>
                      )
                  )}
              </ol>
            </div>
            <div className="col-6 ">
              <form onSubmit={submitHandler}>
                <label>
                  Retailer Name:
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={retailerDetails.retailer_name}
                    required
                    onChange={(e) =>
                      setRetailerDetails({
                        ...retailerDetails,
                        retailer_name: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Phone:
                  <input
                    className="form-control"
                    type="text"
                    name="desc"
                    value={retailerDetails.phone}
                    required
                    onChange={(e) =>
                      setRetailerDetails({
                        ...retailerDetails,
                        phone: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Email(Email to send this file): Write "Customer" if it's to
                  customer. Multiple email seperate by "comma(,)".
                  <input
                    className="form-control"
                    type="text"
                    name="desc"
                    value={retailerDetails.email}
                    // required
                    onChange={(e) =>
                      setRetailerDetails({
                        ...retailerDetails,
                        email: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Website:
                  <input
                    className="form-control"
                    type="text"
                    name="desc"
                    value={retailerDetails.website}
                    required
                    onChange={(e) =>
                      setRetailerDetails({
                        ...retailerDetails,
                        website: e.target.value,
                      })
                    }
                  />
                </label>
                <div className="top__button">
                  <Button
                    type="Submit"
                    variant="primary"
                    // onClick={() => addEditFileType(retailerDetails)}
                  >
                    {(retailerDetails.id && "Save") || "Add new"}
                  </Button>
                  <Button
                    variant="danger"
                    disabled={retailerDetails.id ? false : true}
                    onClick={() => deleteRetailerHandler(retailerDetails.id)}
                  >
                    Delete
                  </Button>
                </div>
              </form>
              <div
                className={errorMessage ? "error__message" : "success__message"}
              >
                {errorMessage ? errorMessage : successMessage}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer__button">
            <Button variant="primary" onClick={() => clearRetailerDetails()}>
              Clear
              {/* {(retailerDetails && "Clear") || "Add new"} */}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                clearRetailerDetails();
                onHide();
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAddRetailers;
