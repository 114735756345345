import { Modal, Button } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import "./ModalApplyPV.css";
import {
  getAllCompanyCommonFiles,
  getAllFilesType,
  getIsAdminSession,
  getSelectedCompany,
  sendBpPvApplication,
} from "../../Services";
import { useEffect } from "react";

const ModalApplyPV = ({
  openModal,
  ohShow,
  onHide,
  files,
  customer_id,
  customer,
}) => {
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedCommonFiles, setSelectedCommonFiles] = useState([]);
  const [allCommonFile, setCommonFiles] = useState([]);
  const [allFileTypes, setAllFileTypes] = useState([]);
  const [emailVisible, setEmailVisible] = useState(false);
  const company = getSelectedCompany();
  const is_admin = getIsAdminSession();
  // console.log(allCommonFile);

  const company_id = is_admin
    ? company.length !== 0
      ? company[0]["id"]
      : 0
    : 0;
  const fetchAllFiles = async () => {
    const allFiles = await getAllCompanyCommonFiles(company_id);
    // console.log(allFiles);
    if (allFiles === 500 || allFiles === 400) {
    } else {
      setCommonFiles(allFiles);
    }
    const file_types = await getAllFilesType(company_id);
    if (file_types === 500 || file_types === 400) {
    } else {
      setAllFileTypes(file_types);
    }
  };

  const isEven = (index) => index % 2 === 0;
  const selectedFile = (e, selectedF) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedFiles([...selectedFiles, selectedF]);
    } else {
      setSelectedFiles((prevState) =>
        prevState.filter((prevItem) => prevItem !== selectedF)
      );
    }
  };
  // console.log(selectedFiles, selectedCommonFiles);
  const selectedCommonFile = (e, selectedCF) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedCommonFiles([...selectedCommonFiles, selectedCF]);
    } else {
      setSelectedCommonFiles((prevState) =>
        prevState.filter((prevItem) => prevItem !== selectedCF)
      );
    }
  };
  const sendEmail = async () => {
    setIsLoading(true);
    if (selectedCommonFiles.length === 0 && selectedFiles.length === 0) {
      alert("Select at least one file to apply.");
    } else {
      const sentPV = await sendBpPvApplication(
        selectedFiles,
        selectedCommonFiles,
        company_id,
        customer_id,
        "PV"
      );
      if (sentPV.status !== 200) {
        seterrorMessage("Email can not be sent at this time.");
        setSuccessMessage("");
      } else {
        console.log(sentPV);
        setSuccessMessage(sentPV.data);
        seterrorMessage("");
      }
    }

    setTimeout(() => {
      setSuccessMessage("");
      seterrorMessage("");
    }, 10000);
    setIsLoading(false);
  };
  const clearAll = () => {
    setSelectedFiles([]);
    setSelectedCommonFiles([]);
    setAllFileTypes([]);
  };
  useEffect(() => {
    is_admin && fetchAllFiles();
  }, [openModal]);
  return (
    <div className="modal__admin">
      <Modal
        size="lg"
        show={openModal}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>PV Application</Modal.Title>
        </Modal.Header>{" "}
        <form>
          <Modal.Body>
            <div className="">
              Choose the file below to attach in application.
              <br /> Customer files
              <ol className="">
                {openModal &&
                  files?.map((d, index) => (
                    <div
                      key={index}
                      className={
                        isEven(index) ? "bg__even w-100" : "bg__odd w-100"
                      }
                    >
                      <div className="d-flex justify-content-between ">
                        {" "}
                        <div className=" w-25 customer__file__name ">
                          <li key={d.id}>{d.file_type}</li>
                        </div>
                        <div className=" file__checkbox">
                          <input
                            className="form-check-input p-2 "
                            type="checkbox"
                            id="flexCheckDefault"
                            //   defaultValue={isSameAddress}
                            onChange={(e) => selectedFile(e, d)}
                          />{" "}
                        </div>{" "}
                        <div
                          className={
                            emailVisible
                              ? "to__email__visible"
                              : "to__email__hidden"
                          }
                          onClick={() => setEmailVisible(!emailVisible)}
                        >
                          {
                            allFileTypes.filter(
                              (a) => a.id === d.file_type_id
                            )[0].to_email
                          }
                        </div>
                      </div>
                    </div>
                  ))}
              </ol>
              Common files
              <ol>
                {openModal &&
                  allCommonFile?.map((d, index) => (
                    <div
                      key={index}
                      className={
                        isEven(index) ? "bg__even w-50" : "bg__odd w-50"
                      }
                    >
                      <div className="d-flex justify-content-between ">
                        {" "}
                        <div className="w-50 ">
                          <li key={d.id}>{d.file_name} </li>
                        </div>
                        <div className="">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            //   defaultValue={isSameAddress}
                            onChange={(e) => selectedCommonFile(e, d)}
                          />{" "}
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          ></label>
                        </div>
                      </div>
                    </div>
                  ))}
              </ol>
              <div className={errorMessage ? "text-danger" : "text-success"}>
                {errorMessage ? errorMessage : successMessage}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer__button">
              <Button
                variant="primary"
                onClick={() => {
                  sendEmail();
                }}
              >
                {isLoading ? "Sending..." : "Send"}
              </Button>{" "}
              <Button
                variant="secondary"
                onClick={() => {
                  onHide();
                  clearAll();
                }}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ModalApplyPV;
