import { Modal, Button } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import "./ModalSendForSign.css";
// Core viewer
import { Viewer, Worker } from "@react-pdf-viewer/core";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";

import {
  getAllCompanyCommonFiles,
  getAllFilesType,
  getIsAdminSession,
  getSelectedCompany,
  sendForSign,
} from "../../Services";
import { useEffect } from "react";
import { DEVELOPMENT } from "../../Constants";

const ModalSendForSign = ({
  openModal,
  ohShow,
  onHide,
  fileDetails,
  customer_id,
  customer,
  user_id,
}) => {
  let herokucors;
  if (DEVELOPMENT) {
    herokucors = "https://cors-anywhere.herokuapp.com/";
  } else {
    herokucors = "";
  }
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const company = getSelectedCompany();
  const is_admin = getIsAdminSession();

  const company_id = is_admin
    ? company.length !== 0
      ? company[0]["id"]
      : 0
    : 0;
  const sendEmailForSign = async () => {
    setIsLoading(true);

    const sentEmail = await sendForSign({
      company_id: company_id,
      customer_id: customer_id,
      file_type_id: fileDetails.file_type_id,
      fileLink: fileDetails.file,
      file_name: fileDetails.filename,
      job_id: customer.customer_job[0].id,
      user_id: user_id,
    });
    if (sentEmail.status !== 200) {
      seterrorMessage("Email can not be sent at this time.");
      setSuccessMessage("");
    } else {
      setSuccessMessage(sentEmail.data);
      seterrorMessage("");
    }

    setTimeout(() => {
      setSuccessMessage("");
      seterrorMessage("");
    }, 10000);
    console.log("end");
    setIsLoading(false);
  };
  // console.log(fileDetails.file);
  return (
    <div className="modal__admin">
      <Modal
        size="lg"
        show={openModal}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Send for signature "{customer.Firstname} {customer.Lastname}"
          </Modal.Title>
        </Modal.Header>{" "}
        <form>
          <Modal.Body>
            <div className="">
              Filename : {fileDetails.filename}
              <br />{" "}
              {fileDetails && (
                <div className="">
                  File preview
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js"></Worker>
                  <Viewer fileUrl={herokucors + fileDetails.file} />
                </div>
              )}
              <div className={errorMessage ? "text-danger" : "text-success"}>
                {errorMessage ? errorMessage : successMessage}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer__button">
              <Button variant="primary" onClick={sendEmailForSign}>
                {isLoading ? "Sending..." : "Send"}
              </Button>{" "}
              <Button
                variant="secondary"
                onClick={() => {
                  onHide();
                }}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ModalSendForSign;
