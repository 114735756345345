import axios from "axios";
import {
  activateCompanyAPI,
  resendActivateCompanyAPI,
  addUser,
  changeAdmin,
  companyAddress,
  companyDetails,
  companyEdit,
  companyRegister,
  bppvApplication,
  regUserInvitation,
  companyUserList,
  sendForSignAPI,
  checkDocSignedAPI,
  saveSignedDocAPI,
} from "../Constants";
import { setUserToken } from "./CustomerAPI";

//REGISTER CODE
export const registerCompany = async (details) => {
  try {
    const response = await fetch(companyRegister, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(details),
    });
    const data = await response.json();
    if (response.status === 200) {
      return { result: data, status: response.status };
    } else {
      return { result: data, status: response.status };
    }
  } catch (error) {
    return { result: { Error: "Server error!" }, status: 100 };
  }
};

export const activateCompany = async (compy, token, em) => {
  const data = { compy: compy, token: token, em: em };
  try {
    const response = await axios.post(activateCompanyAPI, data);
    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    // console.log(error.response);
    return { data: "Something went wrong" };
  }
};
export const resendActivateCompany = async (compy, token, em) => {
  const data = { compy: compy, token: token, em: em, resend: true };
  try {
    const response = await axios.post(resendActivateCompanyAPI, data);
    if (response.status === 200) {
      console.log(response);
      return response;
    } else {
      return response;
    }
  } catch (error) {
    // console.log(error.response);
    return { data: "Something went wrong" };
  }
};

export const getCompanyDetails = async (company_id) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.get(companyDetails + company_id + "/", {
      headers: header,
    });
    if (response.status === 200) {
      const customerData = response.data;
      // console.log(customerData);
      return customerData;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return 500;
    } else {
      return 401;
    }
  }
};
export const getAllCompanyUsers = async (company_id) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.get(companyUserList + company_id + "/", {
      headers: header,
    });
    if (response.status === 200) {
      const customerData = response.data;
      // console.log(customerData);
      return customerData;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return 500;
    } else {
      return 401;
    }
  }
};
export const getCompanyAddress = async (company_id) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.get(companyAddress + company_id + "/", {
      headers: header,
    });
    if (response.status === 200) {
      const customerData = response.data;
      // console.log(customerData);
      return customerData;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return 500;
    } else {
      return 401;
    }
  }
};

export const changeCompanyUser = async (data) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.post(changeAdmin, data, {
      headers: header,
    });
    if (response.status === 200) {
      const responseData = response.data;
      // console.log(customerData);
      return response;
    }
    if (response.status !== 200) {
      return response;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return error.response;
    } else {
      return error.response;
    }
  }
};

export const AddCompanyUser = async (data) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.post(addUser, data, {
      headers: header,
    });
    if (response.status === 200) {
      const responseData = response.data;
      //   console.log(responseData);
      return response;
    }
    if (response.status !== 200) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return error.response;
    } else {
      return error.response;
    }
  }
};
export const RegisterUserInvitation = async (data) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.post(regUserInvitation, data, {
      //   headers: header,
    });
    if (response.status === 200) {
      const responseData = response.data;
      return response;
    }
    if (response.status !== 200) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return error.response;
    } else {
      return error.response;
    }
  }
};
export const CompanyEdit = async (data, company_id) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.patch(companyEdit + company_id + "/", data, {
      headers: header,
    });
    if (response.status === 200) {
      const responseData = response.data;
      return response;
    }
    if (response.status !== 200) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return error.response;
    } else {
      return error.response;
    }
  }
};

export const sendBpPvApplication = async (
  selectedFiles,
  selectedCommonFiles,
  company_id,
  customer_id,
  bp_pv
) => {
  const [header, sessionUser] = setUserToken();

  const data = {
    selectedFiles: selectedFiles,
    selectedCommonFiles: selectedCommonFiles,
    customer_id: customer_id,
    bp_pv: bp_pv,
  };
  try {
    const appResonse = await axios.post(
      bppvApplication + company_id + "/",
      data,
      { headers: header }
    );
    if (appResonse.status === 200) {
      return appResonse;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return error.response;
    } else {
      return error.response;
    }
  }
};

export const sendForSign = async (data) => {
  const [header] = setUserToken();
  try {
    const emailSentResponse = await axios.post(sendForSignAPI, data, {
      headers: header,
    });
    return emailSentResponse;
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return error.response;
    } else {
      return error.response;
    }
  }
};
export const checkDocSigned = async (data) => {
  const [header] = setUserToken();
  try {
    const checkResponse = await axios.post(checkDocSignedAPI, data);
    return checkResponse;
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return error.response;
    } else {
      return error.response;
    }
  }
};
export const saveSignedDoc = async (data) => {
  const [header] = setUserToken();
  try {
    const saveResponse = await axios.post(saveSignedDocAPI, data);
    return saveResponse;
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 500) {
      return error.response;
    } else {
      return error.response;
    }
  }
};
