import React, { useState, useEffect, useMemo } from "react";
import {
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { split_string } from "../../../../Utils";
import "./SettingUsers.css";
export function UserTable({ tableInstance }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  const isEven = (index) => index % 2 === 0;

  // console.log("table instance rows", rows.length);
  return (
    <>
      {rows.length > 0 ? (
        <>
          <table
            {...getTableProps()}
            style={{ border: "solid 1px blue" }}
            className="main__usertable"
          >
            <thead className="user__table__mainhead">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className="usertable__header"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {split_string(column.render("Header"))}

                      {column.isSorted ? (column.isSortedDesc ? "▼" : "▲") : ""}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="usertable_tbody" {...getTableBodyProps()}>
              {page.map((row, idx) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={
                      isEven(idx)
                        ? "bg__even usertable_row"
                        : "bg__odd usertable_row"
                    }
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td className="usertable__td" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/*  paginagion buttions*/}
          <div className="pagination">
            <div className="pagination__div__buttons">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <FaAngleDoubleLeft />
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <FaAngleLeft />
              </button>
              <span>
                Page
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                <FaAngleRight />
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <FaAngleDoubleRight />
              </button>
            </div>
            <div className="page__border"></div>
            <span>
              Go to page:{" "}
              <input
                className="page__textbox"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </span>{" "}
            <select
              className="data__count-options"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="text__results">
            Showing {rows.length > pageSize ? pageSize : rows.length} results of{" "}
            {rows.length} rows
          </div>
        </>
      ) : (
        <div>Nothing found</div>
      )}
    </>
  );
}
