import React, { useState, useEffect, useRef } from "react";
// import "../Login/LoginPage.css";
import "./ForgotPassword.css";
import "bootstrap/dist/css/bootstrap.css";
import { ForgotPasswordAuth } from "../../Services/";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const location = useLocation();
  const userType =
    location.state != null ? location.state["user"] : "INSTALLER"; //to check if user is customer or installer
  const [name, setName] = useState(userType);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loggedUser, setLoggedUser] = useState();
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userRef = useRef();
  const errRef = useRef();

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  useEffect(() => {
    seterrorMessage("");
  }, [email]);

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const det = await ForgotPasswordAuth(email, name).then((result) => {
      console.log(result);
      if (result.status != 200) {
        seterrorMessage(result.data.email);
        setsuccessMessage("");
        setIsLoading(false);
      } else {
        setsuccessMessage("Password reset link sent. Check you email!");
        seterrorMessage("");
        setIsLoading(false);
      }
    });
    setIsLoading(false);
  };
  return (
    <div className="login__form__main">
      <div className="login__form">
        <form className="app__form" onSubmit={submitHandler}>
          <h2>
            RESET PASSWORD for {name}
            <span
              className="user__type"
              onClick={() => (
                setName(name === "INSTALLER" ? "CUSTOMER" : "INSTALLER"),
                setsuccessMessage("")
              )}
            >
              <br />
              Change
            </span>
          </h2>
          {errorMessage != "" ? (
            <p className="error__message">{errorMessage}</p>
          ) : (
            ""
          )}
          {successMessage != "" ? (
            <p className="success__message">{successMessage}</p>
          ) : (
            ""
          )}
          <div className="form-group">
            <label htmlFor="Email">Email:</label>
            <input
              type="text"
              name="email"
              id="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div className="form-group">
            <input
              type="Submit"
              name="Request"
              id="submit"
              defaultValue={isLoading ? "Sending..." : "Request"}
            />
          </div>
        </form>

        <div />
      </div>
    </div>
  );
};

export default ForgotPassword;
