import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./HomePage.css";
import { Gallery, Header, Intro } from "./Container";
const HomePage = () => {
  return (
    <div className="main__homepage">
      <Header />
      <Intro />
      {/* <Gallery /> */}
    </div>
  );
};

export default HomePage;
