import { useState, useRef } from "react";
import SignaturePad from "react-signature-canvas";
import { PDFDocument } from "pdf-lib";
import { Modal } from "react-bootstrap";
import "./SignDocumentFromLink.css";
import { DEVELOPMENT, images, saveSignedDocAPI } from "../../Constants";
import { Base64 } from "../../Services/Base64Decode";
import { saveSignedDoc } from "../../Services";

const ModalSignaturePad = ({
  openModal,
  file_container_link,
  onHide,
  file_type_code,
  customer_file_id_code,
  checkDocumentSigned,
}) => {
  let herokucors;
  if (DEVELOPMENT) {
    herokucors = "https://cors-anywhere.herokuapp.com/";
  } else {
    herokucors = "";
  }
  const sigCanvas = useRef();
  const [imgUrl, setImgUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [is_loading, setIsLoading] = useState(false);
  const file_type_link = Base64.decode(file_type_code);
  const clearSignature = () => {
    sigCanvas.current.clear();
    setImgUrl(null);
  };
  const saveSignature = () => {
    // console.log(sigCanvas.current.isEmpty());
    if (sigCanvas.current.isEmpty()) {
      setErrorMessage("Please sign first!");
    } else {
      setErrorMessage("");
      // console.log(sigCanvas.current.getCanvas().toDataURL("image/png"));
      setImgUrl(sigCanvas.current.getCanvas().toDataURL("image/png"));
    }
  };

  const signAndSend = async () => {
    //check again if already signed
    setIsLoading(true);

    const is_signed = await checkDocumentSigned();
    if (is_signed === 200) {
      if (imgUrl) {
        const date = new Date();
        const datetime_signed = date.toLocaleDateString("en-AU", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        // get the fields of filetype to match with the filled customer file
        const existingPdfBytes = await fetch(
          herokucors + "https://" + file_type_link
        ).then((res) => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const form = pdfDoc.getForm();
        const date_signed = form.getTextField("date_signed");
        const client_signature = form.getTextField("client_signature");
        const date_widget = date_signed.acroField.getWidgets();
        const signature_widget = client_signature.acroField.getWidgets();

        // get the customerfile to write on this
        const filledpdf = await fetch(herokucors + file_container_link).then(
          (res) => res.arrayBuffer()
        );
        const fpdoc = await PDFDocument.load(filledpdf);
        const customer_file_pages = fpdoc.getPages();
        const filledPdf_form = fpdoc.getForm();
        const sgn = await fpdoc.embedPng(imgUrl);
        let date_pageIndex = pdfDoc
          .getPages()
          .findIndex((p) => p.ref === date_widget[0].P());
        let sign_pageIndex = pdfDoc
          .getPages()
          .findIndex((p) => p.ref === signature_widget[0].P());
        customer_file_pages[sign_pageIndex].drawImage(
          sgn,
          signature_widget[0].Rect().asRectangle()
        );
        // customer_file_pages[sign_pageIndex].drawText(
        //   datetime_signed,
        //   signature_widget[0].Rect().asRectangle()
        // );

        customer_file_pages[date_pageIndex].drawText(
          datetime_signed,
          date_widget[0].Rect().asRectangle()
        );

        filledPdf_form.flatten();

        const pdfBytes = await fpdoc.save();
        const file = new Blob([pdfBytes], {
          type: "application/pdf",
        });

        const fileURL = URL.createObjectURL(file);
        //to view pdf in browser
        window.open(fileURL);
        //IMPORTANT !!! SAVE THGIS FILE TO CUSTOMER FILE REPLACING UNSIGNED...
        // AND CHENGE IS_SIGNED TO TRUE!!!
        // AND CLOSE THIS MODAL

        const reader = new FileReader();
        reader.readAsDataURL(file);
        const dataUrl = await new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result);
        });

        const response = await saveSignedDoc({
          dataUrl: dataUrl,
          datetime_signed: datetime_signed,
          customer_file_id_code: customer_file_id_code,
        });
        console.log(response);
      } else {
        setErrorMessage("Please save signature before signing and sending.");
      }
    } else if (is_signed === 202) {
      console.log("already signed");
      setErrorMessage("You have already signed this form.");
    }
    setIsLoading(false);
  };
  const closeModal = () => {
    setImgUrl(null);
    onHide();
  };
  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => closeModal()}
        backdrop="static"
        keyboard={false}
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sign here</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div id="sig-pad"></div>
            <div className="signature__wrapper">
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{ className: "signatureCanvas" }}
              />
            </div>
            <div className="d-flex justify-content-between">
              <img
                src={imgUrl ? imgUrl : images.signatureLogo}
                alt="Signature"
                style={{
                  display: "block",
                  margin: "0, auto",
                  border: "1px solid black",
                  width: "300px",
                  aspectRatio: 135 / 76,
                }}
              />
              <img
                src={images.signHere}
                alt="Signature"
                style={{
                  display: "block",
                  margin: "0, auto",
                  border: "1px solid black",
                  height: "150px",
                  aspectRatio: 135 / 76,
                  // filter: "grayscale(50%)",
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-danger">{errorMessage}</div>
          <div>
            <div className="">
              <button className="btn btn-primary m-1" onClick={saveSignature}>
                Save
              </button>
              <button className="btn btn-danger m-1" onClick={clearSignature}>
                Clear
              </button>
              <button className="btn btn-success m-1" onClick={signAndSend}>
                {is_loading ? "Saving..." : "Sign & Send"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalSignaturePad;
