import React, { useState } from "react";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { Link } from "react-router-dom";
import "./SideBar.css";
import { SideBarData } from "./SideBarData";

const SideBar = ({ toggleTab, toggleState }) => {
  const [is_sideBarOpen, setActive] = useState(false);

  const activateNav = () => {
    setActive(!is_sideBarOpen);
  };
  return (
    <div
      className={is_sideBarOpen ? "header sidebar " : "header-mobile sidbar"}
    >
      <div className="menu-icon" onClick={activateNav}>
        {!is_sideBarOpen ? (
          <IoMdMenu className="menu" />
        ) : (
          <IoMdClose className="menu" />
        )}
      </div>

      <nav>
        <ul className={is_sideBarOpen ? "ul-item" : "ul-item oicon"}>
          {SideBarData.map((value, key) => {
            return (
              <li
                key={key}
                onClick={() => {
                  toggleTab(value.title);
                }}
                className={value.title === toggleState ? "active" : ""}
              >
                <div className="icon">{value.icon}</div>
                <Link to="" id="title">
                  {value.title}
                </Link>
              </li>
            );
          })}

          {/* <li
            onClick={() => {
              toggleTab("General");
            }}
            className="active"
          >
            <FaHome className="icon" />
            <Link to="">Home</Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
