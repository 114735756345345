import React, { useState, useEffect, useRef } from "react";

import "./ChangePassword.css";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useNavigate } from "react-router-dom";
import { ChangePasswordAuth, getUser } from "../../Services";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const user = getUser();
const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    oldPassword: "",
    newPassword: "",
    rePass: "",
  });
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [rePasswordShown, setRePasswordShown] = useState(false);
  const showHidePassword = (pass) => {
    if (pass == "old") {
      setOldPasswordShown(!oldPasswordShown);
    }
    if (pass == "new") {
      setNewPasswordShown(!newPasswordShown);
    }
    if (pass == "re") {
      setRePasswordShown(!rePasswordShown);
    }
  };

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);

  useEffect(() => {
    seterrorMessage("");
    setsuccessMessage("");
  }, [details.oldPassword, details.newPassword, details.rePass]);

  const submitHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    console.log(details.rePass, details.newPassword);
    if (details.rePass === details.newPassword) {
      // ChangePasswordAuth
      const changed = await ChangePasswordAuth(
        details,
        user["is_installer"]
      ).then((result) => {
        console.log("datachange password", result);
        if (result.status != 200) {
          seterrorMessage(result.data);
          setsuccessMessage("");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          console.log(result);
          setsuccessMessage(result.data);
          seterrorMessage("");
        }
      });
    } else {
      seterrorMessage("Password did not match!");
    }
    setIsLoading(false);
  };
  return (
    <div className="login__form__main">
      <div className="login__form">
        <form className="app__form" onSubmit={submitHandler}>
          <h2>CHANGE PASSWORD</h2>
          {errorMessage != "" ? (
            <p className="error__message">{errorMessage}</p>
          ) : (
            ""
          )}
          {successMessage != "" ? (
            <p className="success__message">{successMessage}</p>
          ) : (
            ""
          )}
          <div className="form-group">
            <label htmlFor="Old Password">Old Password:</label>
            <input
              type={oldPasswordShown ? "text" : "password"}
              name="oldpassword"
              id="oldpassword"
              required
              onChange={(e) =>
                setDetails({ ...details, oldPassword: e.target.value })
              }
              value={details.oldPassword}
            />
            <span className="eye__span" onClick={() => showHidePassword("old")}>
              {oldPasswordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
            <label htmlFor="New Password">New Password:</label>
            <input
              type={newPasswordShown ? "text" : "password"}
              name="newpassword"
              id="newpassword"
              required
              onChange={(e) =>
                setDetails({ ...details, newPassword: e.target.value })
              }
              value={details.newPassword}
            />
            <span className="eye__span" onClick={() => showHidePassword("new")}>
              {newPasswordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
            <label htmlFor="Re-Type Password">Re-Type Password:</label>
            <input
              type={rePasswordShown ? "text" : "password"}
              name="retypepassword"
              id="retypepassword"
              required
              onChange={(e) =>
                setDetails({ ...details, rePass: e.target.value })
              }
            />
            <span className="eye__span" onClick={() => showHidePassword("re")}>
              {rePasswordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
          </div>
          <div className="form-group">
            <input
              type="Submit"
              name="Request"
              id="submit"
              defaultValue={isLoading ? "Loading..." : "Change Password"}
            />
          </div>
        </form>

        <div />
      </div>
    </div>
  );
};

export default ChangePasswordPage;
