import { Modal, Button } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import "./ModalChooseField.css";
import { useEffect } from "react";
import { saveUploadedField } from "../../Services";

const ModalChooseField = ({
  showModal,
  ohShow,
  onHide,
  fieldDetails,
  fileName,
  fetchAllFilesTypes,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [company_details, setCompany_details] = useState();
  const [company_user, setCompany_user] = useState();
  const [jobDetails, setJobDetails] = useState();
  const [targetList, setTargetList] = useState({});
  const [databaseTable, setDatabaseTable] = useState({});

  useEffect(() => {
    setCustomerList(fieldDetails?.customer);
    setCompany_details(fieldDetails?.company_details);
    setCompany_user(fieldDetails?.company_user);
    setJobDetails(fieldDetails?.job_details);
    setTargetList(fieldDetails?.uploaded_fields);
    setDatabaseTable(fieldDetails?.field_table);
  }, [fieldDetails]);
  // const [selectedIndex, setSelectedIndex] = useState();
  const [selectedValue, setSelectedValue] = useState({
    index: null,
    list: null,
  });

  const selectItem = (item, index, list, table) => {
    // console.log("selecte index", index, list, item, table);
    setSelectedValue({ index: index, list: list, table: table });
  };
  // console.log(selectedValue);
  const matchItem = (key) => {
    if (selectedValue !== null) {
      removeItem(key);

      setTargetList({
        ...targetList,
        [key]: selectedValue.list[selectedValue.index],
      });
      setDatabaseTable({ ...databaseTable, [key]: selectedValue.table });
      // setTargetList(targetList);
      setSelectedValue(null);
      // selectedValue.list.splice(selectedValue.index, 1);
      // setCustomerList(selectedValue.list);
    }
  };
  const removeItem = (key) => {
    if (targetList[key] !== "") {
      // console.log(key, targetList[key]);
      // selectedValue.list.push(targetList[key]);
      setTargetList({ ...targetList, [key]: "" });
      setDatabaseTable({ ...databaseTable, [key]: "" });
      setSelectedValue(null);
    }
  };
  const saveFields = async (targetList) => {
    if (fieldDetails?.field_table === databaseTable) {
      console.log("pramesh");
      setErrorMessage("Make some changes to save fields!");
    } else {
      setIsLoading(true);
      console.log("shrestha");
      const result = await saveUploadedField(
        targetList,
        databaseTable,
        fieldDetails?.file_type
      );
      // console.log(result.status);
      setIsLoading(false);
      // console.log(result);
      if (result.status !== 200) {
        setErrorMessage("Something went wrong!");
        setSuccessMessage("");
      } else {
        setSuccessMessage("Fields Matched!");
        setErrorMessage("");
        fetchAllFilesTypes();
      }
    }
    setTimeout(() => {
      setErrorMessage("");
      setSuccessMessage("");
    }, 10000);

    // console.log(targetList);
    // console.log(databaseTable);
  };
  const isEven = (index) => index % 2 === 0;
  // dnd ends
  return (
    <div className="modal__admin">
      <Modal
        size="lg"
        show={showModal}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Match fields</Modal.Title>
        </Modal.Header>{" "}
        <form>
          <Modal.Body>
            <div className="main__box">
              <div className="leftColumn">
                <div className="leftItemList">
                  <span className="head"> Customer fields</span>

                  {customerList &&
                    customerList.map((item, index) => (
                      <div
                        className={
                          isEven(index)
                            ? "bg__even border leftItems"
                            : "bg__odd border leftItems"
                        }
                        key={index}
                        onClick={(e) =>
                          selectItem(item, index, customerList, "CustomUser")
                        }
                      >
                        <span
                          className={
                            selectedValue?.list === customerList &&
                            index === selectedValue?.index
                              ? "selected"
                              : ""
                          }
                          style={{
                            display: "block",
                          }}
                        >
                          {item}
                        </span>
                      </div>
                    ))}
                </div>
                <div className="leftItemList">
                  <span className="head">Company user fields</span>
                  {company_user &&
                    company_user.map((item, index) => (
                      <div
                        className={
                          isEven(index)
                            ? "bg__even border leftItems"
                            : "bg__odd border leftItems"
                        }
                        key={index}
                        onClick={(e) =>
                          selectItem(item, index, company_user, "InstallerUser")
                        }
                      >
                        <span
                          className={
                            selectedValue?.list === company_user &&
                            index === selectedValue?.index
                              ? "selected"
                              : ""
                          }
                          style={{
                            display: "block",
                          }}
                        >
                          {item}
                        </span>
                      </div>
                    ))}
                </div>
                <div className="leftItemList">
                  <span className="head">Company fields</span>
                  {company_details &&
                    company_details.map((item, index) => (
                      <div
                        className={
                          isEven(index)
                            ? "bg__even border leftItems"
                            : "bg__odd border leftItems"
                        }
                        key={index}
                        onClick={(e) =>
                          selectItem(item, index, company_details, "Company")
                        }
                      >
                        <span
                          className={
                            selectedValue?.list === company_details &&
                            index === selectedValue?.index
                              ? "selected"
                              : ""
                          }
                          style={{
                            display: "block",
                          }}
                        >
                          {item}
                        </span>
                      </div>
                    ))}
                </div>
                <div className="leftItemList">
                  <span className="head">Job Details fields</span>
                  {jobDetails &&
                    jobDetails.map((item, index) => (
                      <div
                        className={
                          isEven(index)
                            ? "bg__even border leftItems"
                            : "bg__odd border leftItems"
                        }
                        key={index}
                        onClick={(e) =>
                          selectItem(item, index, jobDetails, "JobDetails")
                        }
                      >
                        <span
                          className={
                            selectedValue?.list === jobDetails &&
                            index === selectedValue?.index
                              ? "selected"
                              : ""
                          }
                          style={{
                            display: "block",
                          }}
                        >
                          {item}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                Match name wih you uploaded field name {fileName}
                {targetList &&
                  Object.entries(targetList).map(([k, v], index) => (
                    <div
                      className={
                        isEven(index)
                          ? "bg__even border rightItemList"
                          : "bg__odd border rightItemList"
                      }
                      key={k}
                    >
                      <div className="rightItems" onClick={() => matchItem(k)}>
                        {k}
                      </div>
                      <div className="rightItemsRight" key={index}>
                        {v}
                        <span className="x" onClick={() => removeItem(k)}>
                          x
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer__button">
              <span className={errorMessage ? "text-danger" : "text-success"}>
                {errorMessage}
                {successMessage}
              </span>{" "}
              <Button
                variant="primary"
                onClick={() => {
                  saveFields(targetList);
                }}
              >
                {isLoading ? "Saving..." : "Save"}
              </Button>{" "}
              <Button
                variant="secondary"
                onClick={() => {
                  onHide();
                }}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ModalChooseField;
