import {
  allRetailersAPI,
  companyLogin,
  companyRegister,
  editDeleteRetailersAPI,
  getLoggedInUserAPI,
  loginApi,
} from "../Constants/constant";
import { setUserSession } from "./Utilities";
import axios from "axios";
import { setUserToken } from "./CustomerAPI";
export const Login = async (loginDetails, userType) => {
  //api call
  //   http://127.0.0.1:8000/api/accounts/login/
  //   const resp = await fetch(jobSearch + "1267551", {});
  //   const dt = await resp.json();
  //   console.log("jobsearch", dt);
  // const job = await axios.get(jobSearch + "1267551");
  //   console.log(job.data);
  //add try catch block
  try {
    const response = await fetch(
      userType === "INSTALLER" ? companyLogin : loginApi,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(loginDetails),
      }
    );
    const data = await response.json();
    // console.log("login data", data, response.status);
    //   setTasks([...tasks, data]);fd

    if (response.status === 200) {
      // setAuth(response.data);
      // console.log("login data", data, JSON.stringify(data));
      if (data["is_active"]) {
        setUserSession(data);
      }

      return { result: data, status: response.status };
    } else {
      return { result: data, status: response.status };
    }
  } catch (error) {
    return { result: { Error: "Server error!" }, status: 100 };
  }
};
export const getLoggedInUser = async (user_id) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.get(getLoggedInUserAPI + user_id + "/", {
      data: { a: "a" },
      headers: header,
    });
    if (response.status === 200) {
      const loggedinUser = response.data;
      // console.log(customerData);
      return loggedinUser;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      return 500;
    } else {
      return 401;
    }
  }
};
