import { useState } from "react";
import { useEffect } from "react";
import { mediaUrl } from "../../Constants";
import "./CustomerDetails.css";
import {
  getAllCommonFiles,
  getAllCompanyUsers,
  getIsAdminSession,
  getSelectedCompany,
  getUser,
} from "../../Services";
import { FaCaretDown } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";

const CustomerDetails = ({
  isLoading,
  customer,
  commonFiles,
  handleChange,
  handleDropdownChange,
  changes,
}) => {
  // let customerJob = "";
  // const customerJob = customer.customer_job[0];
  const sessionUser = getUser();
  const customerJob = customer.customer_job[0] || {};
  // customer.customer_job !== null
  //   ? (customerJob = customer.customer_job[0])
  //   : (customerJob = {});
  const split_string = (title) => title.split("_").join(" ");
  const [commonFile, setCommonFile] = useState();
  // const [changes, setChanges] = useState({});
  const [userList, setUserList] = useState([]);
  const company = getSelectedCompany();
  const company_id = company ? company[0]["id"] : 0;
  // console.log(changes);
  const filterCommonFile = async () => {
    const allCFile = await getAllCommonFiles(
      company_id,
      customerJob["solar_panel"] == "" ? "None" : customerJob["solar_panel"],
      customerJob["inverter"] == "" ? "None" : customerJob["inverter"],
      customerJob["hotwater"] == "" ? "None" : customerJob["hotwater"],
      customerJob["aircon"] == "" ? "None" : customerJob["aircon"]
    );
    const users = await getAllCompanyUsers(company_id);

    if ((users !== 401) & (users !== 500)) {
      setUserList(users);
    }

    // console.log(allCFile["solar_panel"]);
    setCommonFile(allCFile);
  };
  // console.log(userList);
  const selectedPerosn = (user_id) => {
    const a = userList.filter((a) => a.id === user_id);
    return userList.length > 0
      ? a[0]["first_name"] + " " + a[0]["last_name"]
      : "...loading";
  };
  const replace_string = (detail) => {
    return detail.replace("_id", "");
  };
  const is_admin = getIsAdminSession();
  const viewFile = (file_path) => {
    // const fileExt = file_path ? file_path.split(".").pop() : "";
    window.open(mediaUrl + file_path, "_blank");
  };
  useEffect(() => {
    company && filterCommonFile();
  }, []);
  return (
    <>
      {isLoading ? (
        <div>Content Loading...</div>
      ) : (
        <div className="main__div">
          <div className="headings__all">Job details</div>
          {Object.keys(customerJob).map((detail, i) => {
            return detail == "system_size" ||
              detail == "no_of_panels" ||
              detail == "no_of_battery" ||
              detail == "connection_type" ||
              detail == "switch_board" ||
              detail == "system_type" ||
              detail == "property_type" ? (
              <div key={i} className="customer__title__details">
                <span className="title">{split_string(detail)}</span>
                <div className="title__details">
                  {sessionUser["is_installer"] ? (
                    <input
                      className="input__field"
                      type="text"
                      defaultValue={
                        customerJob[detail] ? customerJob[detail] : ""
                      }
                      placeholder="---"
                      onChange={(e) => {
                        handleChange(e, detail);
                      }}
                    />
                  ) : (
                    <span>
                      {customerJob[detail] ? customerJob[detail] : "---"}
                    </span>
                  )}
                </div>
              </div>
            ) : null;
          })}
          {Object.keys(customerJob).map((detail, i) => {
            return detail == "designer_id" ||
              detail == "electrician_id" ||
              detail == "installer_id" ? (
              <div key={i} className="customer__title__details">
                <span className="title">
                  {split_string(detail.replace("_id", ""))}
                </span>
                <div className="title__details">
                  {is_admin ? (
                    <>
                      <Dropdown className="app_navbar__setting m-0">
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          className=""
                        >
                          <div className="">
                            {changes?.[replace_string(detail)]
                              ? selectedPerosn(
                                  changes?.[replace_string(detail)]
                                )
                              : customerJob[detail]
                              ? selectedPerosn(customerJob[detail])
                              : "---"}
                            <FaCaretDown size={25} />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {userList.map((x, y) => (
                            <Dropdown.Item
                              key={y}
                              eventKey={x.first_name}
                              onClick={() => {
                                handleDropdownChange(detail, x.id);
                              }}
                              defaultValue={x.id}
                              className=""
                            >
                              {x.first_name + " " + x.last_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <span>
                      {customerJob[detail]
                        ? selectedPerosn(customerJob[detail])
                        : "---"}
                    </span>
                  )}
                </div>
              </div>
            ) : null;
          })}
          {Object.keys(customerJob).map((detail, i) => {
            return detail == "inverter" ||
              detail == "solar_panel" ||
              detail == "aircon" ||
              detail == "hotwater" ? (
              <div key={i} className="customer__title__details">
                <span className="title">{split_string(detail)}</span>
                <div
                  className="title__details file__link"
                  onClick={() =>
                    commonFile[detail] && viewFile(commonFile[detail])
                  }
                >
                  {sessionUser["is_installer"] ? (
                    <input
                      className="input__field file__link"
                      type="text"
                      // style={}
                      defaultValue={
                        customerJob[detail] ? customerJob[detail] : ""
                      }
                      placeholder="---"
                      onChange={(e) => {
                        handleChange(e, detail);
                      }}
                    />
                  ) : (
                    <span>
                      {customerJob[detail] ? customerJob[detail] : "---"}
                    </span>
                  )}
                </div>
              </div>
            ) : null;
          })}
        </div>
      )}
    </>
  );
};

export default CustomerDetails;
