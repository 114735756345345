import React from "react";
import {
  getCompanyUsers,
  getIsAdminSession,
  getSelectedCompany,
  getUser,
} from "../../../../Services";
import { useState, useEffect, useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { Link, useNavigate } from "react-router-dom";
import { GlobalFilter } from "../../../../Components/Customer/globalFilter";
import { UserTable } from "./UserTable";
import { images } from "../../../../Constants";
import "../../../Customer/Customer.css";
import "./SettingUsers.css";
import ChangeAdminModal from "../Modals/ChangeAdminModal";
import AddUserModal from "../Modals/AddUserModal";

const SettingUsers = ({ toggleState }) => {
  const [companyUserList, setCompanyUsers] = useState([]);
  const [rAModal, setRAModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ user: "", action: "" });
  const company = getSelectedCompany();
  //   console.log(company[0]["id"]);
  const company_id = company[0]["id"];
  const fetchCompanyUsers = async () => {
    const companyUsers = await getCompanyUsers(company_id);
    if (companyUsers !== 401 && companyUsers !== 500) {
      setCompanyUsers(companyUsers);
    }
    return companyUsers;
  };
  const selectUser = (userId) => {
    const user = companyUserList.filter((a, ind) => a.id === userId);
    return user;
  };

  const confirmDialog = () => {
    // console.log("change user", selectedUser.user);
    fetchCompanyUsers(company_id);
  };

  const userHandler = (option, userId) => {
    setRAModal(true);
    if (option === "ra") {
      const a = selectUser(userId);
      // console.log(a[0]);
      setSelectedUser({ user: a[0], action: "Remove Admin" });
    }
    if (option === "ma") {
      const a = selectUser(userId);
      setSelectedUser({ user: a[0], action: "Make Admin" });
    }
    if (option === "ru") {
      const a = selectUser(userId);
      setSelectedUser({ user: a[0], action: "Remove User" });
    }
    if (option === "au") {
      const a = selectUser(userId);
      setSelectedUser({ user: a[0], action: "Add User" });
    }
  };

  const productData = useMemo(() => [...companyUserList], [companyUserList]);
  const productsColumns = useMemo(
    () =>
      companyUserList[0]
        ? Object.keys(companyUserList[0])
            .filter((a) => a != "password")
            .map((key, index) => {
              if (key === "role")
                // console.log(key, "here");
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value, row }) => (
                    <div className="user__role">
                      {value}
                      <br />
                      {value === "Admin" ? (
                        <span
                          title="Remove Admin"
                          className="role__span text-danger "
                          onClick={() => userHandler("ra", row.original.id)}
                        >
                          Remove Admin
                        </span>
                      ) : (
                        <span
                          title="Make Admin"
                          className="role__span text-primary"
                          onClick={() => userHandler("ma", row.original.id)}
                        >
                          Make Admin
                        </span>
                      )}

                      <br />
                      <span
                        title="Remove User"
                        className="role__span text-danger"
                        onClick={() => userHandler("ru", row.original.id)}
                      >
                        Remove User
                      </span>
                    </div>
                  ),
                };
              if ((key === "first_name") | (key === "last_name"))
                return {
                  Header: key,
                  accessor: key,
                  Cell: ({ value, row }) => (
                    <div className="text__wrap">
                      <Link
                        className="another__link "
                        to={{
                          pathname: `/user-details/${row.original.id}/`,
                          state: { companyUserList },
                        }}
                      >
                        {value}
                      </Link>
                    </div>
                  ),
                };

              return {
                Header: key,
                accessor: key,
                Cell: ({ value, row }) => (
                  <div className="">
                    {row.details}
                    {value}
                  </div>
                ),
              };
            })
        : [],
    [companyUserList]
  );

  const tableInstance = useTable(
    { columns: productsColumns, data: productData },
    useGlobalFilter,
    // tableHooks,
    useSortBy,
    usePagination
  );
  const { preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  useEffect(() => {
    fetchCompanyUsers();
  }, []);
  return (
    <div className="user__main__div">
      <ChangeAdminModal
        selectedUser={selectedUser}
        openModal={rAModal}
        ohShow={() => setRAModal(true)}
        onHide={() => setRAModal(false)}
        confirmDialog={confirmDialog}
        fetchCompanyUsers={fetchCompanyUsers}
      />

      {!isLoading ? (
        <>
          <div className="row ">
            <span className="col mt-3">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={state.globalFilter}
              />
            </span>
            <span
              className="btn btn-primary col-2 m-3"
              onClick={() => userHandler("au", 0)}
            >
              Add user
            </span>
          </div>

          <div className="usertable__details">
            <UserTable tableInstance={tableInstance} />
          </div>
        </>
      ) : (
        <div className="loading__img_div">
          {" "}
          <img
            className="loading__img"
            src={images.loadingCircle}
            width="100px"
            height="100px"
          ></img>
          <div>...Loading Customer List</div>
        </div>
      )}
    </div>
  );
};

export default SettingUsers;
