import axios from "axios";
import { getIsAdminSession } from "./Utilities";
import {
  allFilesTypeApi,
  changePasswordApi,
  customerJobApi,
  editDeleteFilesTypeApi,
  forgotPasswordApi,
  installerforgotPasswordApi,
  installerResetPwdApi,
  resetPasswordApi,
  userEditDeleteApi,
  userListApi,
  installerChangePwdApi,
  customerListApi,
  customerEditDeleteApi,
  allFilesTypeInstallerApi,
  allCommonfile,
  customerJobUserEditApi,
  updateCustomerAllDetails,
  sendDocumentsEmailAPI,
} from "../Constants";
import { getUser } from ".";
let header;
export const setUserToken = () => {
  const sessionUser = getUser("customer api");
  let user_token;
  if (sessionUser) {
    user_token = sessionUser["token"];
  }
  header = {
    Authorization: "token " + user_token,
    "Content-Type": "application/json",
  };
  return [header, sessionUser];
};

export const getSingleCustomer = async (customer_id, company_id) => {
  const [header, user] = setUserToken();
  // const is_admin = getIsAdminSession();
  const is_installer = user["is_installer"] ?? false;
  // console.log(user["is_installer"] ?? false);
  const customerResponse = await axios
    .get(
      (is_installer ? customerEditDeleteApi : userEditDeleteApi) +
        customer_id +
        "/" +
        company_id +
        "/",

      {
        // .get(customerEditDeleteApi + customer_id, {
        headers: header,
      }
    )
    .catch((error) => console.log(error.response, "cust api error"));
  if (customerResponse) {
    const customer = customerResponse.data;
    // console.log("customer", customer);
    return customer;
  }
};

export const getAllFilesType = async (company_id) => {
  const is_admin = getIsAdminSession();
  const [header, user] = setUserToken();
  const is_installer = user["is_installer"] ?? false;

  try {
    const fileResponse = await axios.get(
      // allFilesTypeInstallerApi + company_id + "/",
      (is_installer ? allFilesTypeInstallerApi : allFilesTypeApi) +
        company_id +
        "/",
      {
        headers: header,
      }
    );
    if (fileResponse.status === 200) {
      return fileResponse.data;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return 500;
    } else {
      return 400;
    }
  }
};
export const getAllCommonFiles = async (
  company_id,
  panel,
  inverter,
  hotwater,
  aircon
) => {
  const is_admin = getIsAdminSession();
  const [header, user] = setUserToken();
  const data = {
    panel: panel,
    inverter: inverter,
    hotwater: hotwater,
    aircon: aircon,
  };
  try {
    const fileResponse = await axios.post(
      allCommonfile + company_id + "/",
      data
    );
    if (fileResponse.status === 200) {
      return fileResponse.data;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    } else {
      return "Something went wrong";
    }
  }
};

export const editNMI = async (nmi, customer_id, job_number) => {
  const [header, sessionUser] = setUserToken();
  console.log(sessionUser);
  const data = { nmi: nmi };
  try {
    const nmiResponse = await axios.patch(
      (sessionUser["is_installer"] ? customerJobUserEditApi : customerJobApi) +
        customer_id +
        "/",
      data,
      { headers: header }
    );
    if (nmiResponse.status === 200) {
      return nmiResponse.data;
    }
  } catch (error) {
    if (error.response.status === 500) {
      return "Internal server errror";
    } else {
      return "Something went wrong";
    }
  }
};

export const editCustomerAllDetails = async (changes, is_finalize) => {
  const [header, sessionUser] = setUserToken();
  try {
    const response = await axios.patch(
      updateCustomerAllDetails + is_finalize + "/",
      changes,
      {
        headers: header,
      }
    );
    if (response.status === 200) {
      return 200;
    }
  } catch (error) {
    return 401;
  }
};

export const sendDocumentsEmail = async (data) => {
  const [header, sessionUser] = setUserToken();
  // changes["is_finalize"] = is_finalize;
  try {
    const response = await axios.post(sendDocumentsEmailAPI, data, {
      headers: header,
    });
    if (response.status === 200) {
      return 200;
    }
  } catch (error) {
    return 401;
  }
};

export const ChangePasswordAuth = async (passwords, is_installer) => {
  console.log(passwords.newPassword, is_installer);
  setUserToken();
  const data = {
    old_password: passwords.oldPassword,
    new_password: passwords.newPassword,
  };
  try {
    const changePasswordResponse = await axios.patch(
      is_installer ? installerChangePwdApi : changePasswordApi,
      data,
      {
        headers: header,
      }
    );
    if (changePasswordResponse.status === 200) {
      console.log("success", changePasswordResponse);
      return changePasswordResponse;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response.status === 500) {
      // console.log("Internal server errror", error.response.data);
      return "Internal server errror";
    } else {
      // console.log("Something went wrong", error.response.data);
      return error.response;
    }
  }
};
export const ForgotPasswordAuth = async (email, name) => {
  const data = { email: email };
  // console.log("reset password", data);
  try {
    const resetPasswordResponse = await axios.post(
      name === "CUSTOMER" ? forgotPasswordApi : installerforgotPasswordApi,
      data
    );

    if (resetPasswordResponse.status === 200) {
      // console.log("success", resetPasswordResponse);
      return resetPasswordResponse;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response.status === 500) {
      // console.log("Internal server errror", error.response.data);
      return "Internal server errror";
    } else {
      // console.log("Something went wrong", error.response.data);
      return error.response;
    }
  }

  return true;
};

export const ResetPasswordAuth = async (details, token, pk) => {
  // console.log("tok tok", details.newPassword, token);
  setUserToken();
  const data = { token: token, password: details.newPassword, pk: pk };
  // console.log("tok tok", data);
  try {
    const resetPasswordResponse = await axios.post(
      pk ? installerResetPwdApi : resetPasswordApi,
      data
    );
    if (resetPasswordResponse.status === 200) {
      // console.log("success", resetPasswordResponse);
      return resetPasswordResponse;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response.status === 500) {
      // console.log("Internal server errror", error.response.data);
      return "Internal server errror";
    } else {
      // console.log("Something went wrong", error.response.data);
      return error.response;
    }
  }
};
