import ReactDOM from "react-dom";
import FileViewer from "react-file-viewer";
import "./FileViewer.css";

// import "@react-pdf-viewer/core/lib/styles/index.css";

const FileViewerModal = ({ file, shown, onHide }) => {
  // const [shown, setShown] = useState(false);
  // console.log("modalfile", file);
  const fileExt = file ? file.file_path.split(".").pop() : "";
  // console.log("fileext", fileExt);

  const modalBody = () => (
    <div
      style={{
        backgroundColor: "#fff",
        flexDirection: "column",
        overflow: "hidden",

        /* Fixed position */
        left: 0,
        position: "fixed",
        top: 0,

        /* Take full size */
        height: "100%",
        width: "100%",

        /* Displayed on top of other elements */
        zIndex: 9999,
      }}
    >
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#000",
          color: "#fff",
          display: "flex",
          padding: ".5rem",
        }}
      >
        <div style={{ marginRight: "auto" }}>{file.filename}</div>
        <button
          style={{
            backgroundColor: "#357edd",
            border: "none",
            borderRadius: "4px",
            color: "#ffffff",
            cursor: "pointer",
            padding: "8px",
          }}
          // onClick={() => setShown(false)}
          onClick={onHide}
        >
          Close
        </button>
      </div>
      <div className="file__viewer">
        <FileViewer fileType={fileExt} filePath={file.file_path} />
        {/* <Viewer fileUrl={fileUrl} /> */}
      </div>
    </div>
  );

  return (
    <>
      {/* <button
        style={{
          backgroundColor: "#00449e",
          border: "none",
          borderRadius: ".25rem",
          color: "#fff",
          cursor: "pointer",
          padding: ".5rem",
        }}
        // onClick={() => setShown(true)}
      >
        Open modal
      </button> */}
      {shown && ReactDOM.createPortal(modalBody(), document.body)}
    </>
  );
};

export default FileViewerModal;
