//aws base url
// export const baseUrl = "https://api.darwin.solar/";
// export const baseUrl = "https://api.e4.solar/";
// export const baseUrl = "http://172.20.10.3/"; //without connection
// export const baseUrl = "http://192.168.1.106:8000/";
export const DEVELOPMENT = false;
export const baseUrl = "https://e4api.azurewebsites.net/";
const baseCustomerPath = "api/customer/";
const baseAccountPath = "api/accounts/";
const baseAppPath = "api/app/";
const baseCompanyPath = "api/company/";
const baseInstallerPath = "api/installer/";
const baseDsPath = "api/ds/";
//s3 bucket base url
// export const mediaUrl = "https://dsbuck.s3.amazonaws.com/";
//azure storage container url
export const mediaUrl = "https://dsbuck.blob.core.windows.net/e4container/";
export const imageUrl = baseUrl + "media/";
export const jobSearch = baseUrl + baseCustomerPath + "job-search/";
export const loginApi = baseUrl + baseAccountPath + "login/";
export const logoutApi = baseUrl + baseAccountPath + "logout/";
export const registerApi = baseUrl + baseAccountPath + "register/";
export const getLoggedInUserAPI =
  baseUrl + baseAccountPath + "get-loggedin-user/";
export const userListApi = baseUrl + baseAccountPath + "user-list/";
export const changePasswordApi = baseUrl + baseAccountPath + "change-password/";
export const forgotPasswordApi = baseUrl + baseAccountPath + "password_reset/";
export const resetPasswordApi =
  baseUrl + baseAccountPath + "password_reset/confirm/";
export const userEditDeleteApi =
  baseUrl + baseAccountPath + "user-edit-delete/";
export const TestPDF = baseUrl + baseAccountPath + "test-pdf/";
export const updateNmi = baseUrl + baseAccountPath + "update-nmi/";
export const allRetailersAPI = baseUrl + baseAccountPath + "all-retailers/";
export const editDeleteRetailersAPI =
  baseUrl + baseAccountPath + "edit-delete-retailers/"; //<int:pk>
export const allRetailerFormsAPI =
  baseUrl + baseAccountPath + "all-retailer-forms/";
export const editDeleteRetailerFormAPI =
  baseUrl + baseAccountPath + "edit-delete-retailer-form/"; //<int:pk>

export const allRetailerFormsIAPI =
  baseUrl + baseCustomerPath + "all-retailer-forms/";
export const allRetailersIAPI = baseUrl + baseCustomerPath + "all-retailers/";
export const customerJobApi = baseUrl + baseCustomerPath; //<int:customer_id>/customer-job/
export const customerJobUserEditApi = baseUrl + baseCustomerPath + "by-user/"; //<int:customer_id>/customer-job/
export const customerFilesApi = baseUrl + baseCustomerPath; //<int:customer_id>/customer-files/
export const allFilesTypeApi = baseUrl + baseCustomerPath + "file-types/";
export const commonFileList = baseUrl + baseCustomerPath + "common-file/"; //<int:company_id>
export const updateCustomerAllDetails =
  baseUrl + baseCustomerPath + "update-customer-all-details/";
export const bppvApplication =
  baseUrl + baseInstallerPath + "bp-pv-application/"; //<int:company_id>
export const sendForSignAPI =
  baseUrl + baseInstallerPath + "send-email-for-sign/";
export const checkDocSignedAPI =
  baseUrl + baseInstallerPath + "check-document-signed/";
export const saveSignedDocAPI =
  baseUrl + baseInstallerPath + "save-signed-document/";

export const sendDocumentsEmailAPI =
  baseUrl + baseInstallerPath + "send-documents-email/";
export const saveUploadeFileFieldAPI =
  baseUrl + baseInstallerPath + "save-uploaded-field/";
export const retrieveFileFieldAPI =
  baseUrl + baseInstallerPath + "retrieve-file-fields/"; //<int:company_id>/<int:file_id>

//company api
export const companyRegister = baseUrl + baseCompanyPath + "register-company/";
export const activateCompanyAPI =
  baseUrl + baseCompanyPath + "activate-company/";
export const resendActivateCompanyAPI =
  baseUrl + baseCompanyPath + "resend-activate-company/";
export const companyLogin = baseUrl + baseInstallerPath + "login/";
export const installerforgotPasswordApi =
  baseUrl + baseInstallerPath + "password_reset/";
export const installerResetPwdApi =
  baseUrl + baseInstallerPath + "password-reset/confirm/";
export const installerChangePwdApi =
  baseUrl + baseInstallerPath + "change-password/";
export const companyUserList = baseUrl + baseCompanyPath + "company-user-list/"; //<int:company_id>
export const companyListCreate =
  baseUrl + baseCompanyPath + "create-list-company/";
export const companyDetails = baseUrl + baseCompanyPath + "company-details/"; //<int:company_id>
export const companyAddress = baseUrl + baseCompanyPath + "company-address/"; //<int:company_id>
export const companyEdit = baseUrl + baseCompanyPath + "edit-delete/"; //<int:company_id>

export const changeAdmin = baseUrl + baseCompanyPath + "change-admin/";
export const addUser = baseUrl + baseCompanyPath + "add-user/";
export const regUserInvitation =
  baseUrl + baseCompanyPath + "register-user-invitation/";

//jobs app
export const companyJobList = baseUrl + baseCompanyPath + "company-job-list/";
export const companyCustomerList =
  baseUrl + baseCompanyPath + "company-customer-list/";
// export const customerListApi = baseUrl + baseInstallerPath + "customer-list/";
export const customerEditDeleteApi =
  baseUrl + baseInstallerPath + "customer-edit-delete/";
export const allFilesTypeInstallerApi =
  baseUrl + baseInstallerPath + "file-types/";
export const filesUpload = baseUrl + baseInstallerPath + "file-upload/";
export const commonfile = baseUrl + baseInstallerPath + "common-file/"; //<int:company_id>
export const allCommonfile = baseUrl + baseInstallerPath + "all-common-file/"; //<int:company_id>
