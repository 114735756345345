import { Modal, Button } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import "./Modals.css";
import {
  AddCompanyUser,
  changeCompanyUser,
} from "../../../../Services/CompanyAPI";
import { getSelectedCompany, getUser } from "../../../../Services";

const ChangeAdminModal = ({
  selectedUser,
  openModal,
  ohShow,
  onHide,
  confirmDialog,
  fetchCompanyUsers,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [userEmail, setUserEmail] = useState();
  const company = getSelectedCompany()[0];
  const sessionUser = getUser();
  const user_admin = selectedUser.user?.email == sessionUser.email;
  const onConfirm = async () => {
    setIsLoading(true);
    const data = {
      company_id: company.id,
      user_id: selectedUser.user.id,
      action: selectedUser.action,
    };

    const changedUser = await changeCompanyUser(data);
    if (changedUser.status !== 200) {
      seterrorMessage(changedUser.data);
      setMessage(changedUser.data);
    } else {
      setMessage(changedUser.data);
    }
    setIsLoading(false);
    confirmDialog();
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const userlist = await fetchCompanyUsers();
    const a = userlist.filter((x) => x.email === userEmail);
    if (a.length > 0) {
      setMessage("User already added!");
    } else {
      const data = { company_id: company.id, user_email: userEmail };
      const userAdded = await AddCompanyUser(data);
      if (userAdded.status !== 200) {
        seterrorMessage(userAdded.data);
        setMessage(userAdded.data);
      } else {
        setMessage(userAdded.data);
        await fetchCompanyUsers();
      }
    }
    setTimeout(() => {
      seterrorMessage("");
      setMessage("");
    }, 10000);
  };

  return (
    <div className="modal__admin">
      <Modal
        size="md"
        show={openModal}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {user_admin ? "Unable to remove" : selectedUser.action}{" "}
            <strong className="text-primary">
              {selectedUser.user ? selectedUser.user.first_name : ""}
            </strong>
          </Modal.Title>
        </Modal.Header>
        {/* start add user modal */}
        {
          user_admin ? (
            <div className="">
              <Modal.Body>Cannot remove this user.</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    onHide();
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </div>
          ) : selectedUser.action === "Add User" ? (
            <form onSubmit={submitHandler}>
              <Modal.Body>
                <div className="">
                  Add email.
                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Email"
                      required
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                    <label htmlFor="floatingInput">Email address</label>
                  </div>
                  <div
                    className={
                      errorMessage ? "text-danger m-1" : "text-success m-1"
                    }
                  >
                    {message}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="footer__button">
                  <Button type="submit" variant="primary">
                    {isLoading ? "loading..." : "Add"}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => {
                      onHide();
                    }}
                  >
                    Close
                  </Button>
                </div>
              </Modal.Footer>
              {/* end add user model */}
            </form>
          ) : (
            /* start change user modal */
            <div>
              <Modal.Body>
                <div className="">
                  Are you sure?
                  <br />
                  {selectedUser.action}
                  <strong className="text-primary">
                    {" "}
                    {selectedUser.user ? selectedUser.user.first_name : ""}
                  </strong>
                </div>
                <div className={errorMessage ? "text-danger" : "text-success"}>
                  {message}
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="footer__button">
                  <Button
                    variant="primary"
                    onClick={() => {
                      onConfirm(selectedUser.action);
                    }}
                  >
                    {isLoading ? "confirming" : "Confirm"}
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    onClick={() => {
                      onHide();
                    }}
                  >
                    Close
                  </Button>
                </div>
              </Modal.Footer>
            </div>
          )
          /* end change user modal */
        }
      </Modal>
    </div>
  );
};

export default ChangeAdminModal;
