import { useRef, useState } from "react";
import "./CustomerProfile.css";
import { FaRegEdit, FaSave } from "react-icons/fa";
// import { allFilesTypeApi, userEditDeleteApi, userListApi } from "../Constants";
import { editNMI, getSelectedCompany, getUser } from "../../Services";
import { split_string } from "../../Utils";

const CustomerProfile = ({
  customer,
  isLoading,
  handleChange,
  handleCheckboxChange,
}) => {
  // console.log("abc", customer);
  const nmiRef = useRef();
  const [readOnlyNmi, setReadOnlyNmi] = useState(true);
  // split_string(title);
  // const split_string = (title) => title.split("_").join(" ");

  const customerJob = customer.customer_job[0] || {};
  const selected_company = getSelectedCompany()[0];
  const sessionUser = getUser();
  const changeInputFocus = (action) => {
    //code to save NMI
    setReadOnlyNmi(!readOnlyNmi);
    if (readOnlyNmi) {
      nmiRef.current.focus();
    }
    if (action == "save") {
      editNMI(nmiRef.current.value, customer.id, customerJob.job_number);
    }
  };

  return (
    <div className="main__div__profile">
      {!isLoading ? (
        <>
          <div className="customer__head">
            <div className="customer__head__circle">
              {customer["Firstname"][0]}
              {customer["Lastname"] !== null ? customer["Lastname"][0] : ""}
            </div>
            <div className="customer__fullname">
              <span>
                {customer["Firstname"]} {customer["Lastname"]}
              </span>
              <br />
              <span className="c-email"> {customer["email"]}</span>
            </div>
          </div>
          <div className="headings__all">About</div>
          <div className="customer__title__details">
            <span className="title">Phone:</span>
            <div className="title__details">
              {sessionUser["is_installer"] ? (
                <input
                  className="input__field"
                  type="text"
                  defaultValue={customer["phone"] ? customer["phone"] : ""}
                  placeholder="---"
                  onChange={(e) => {
                    handleChange(e, "phone");
                  }}
                />
              ) : (
                <span>{customer["phone"] ? customer["phone"] : "---"}</span>
              )}
            </div>
          </div>

          {Object.keys(customerJob).map((detail, i) => {
            return detail === "street" ||
              detail === "city" ||
              detail === "postcode" ||
              detail === "state" ||
              detail === "job_number" ||
              detail === "installation_date" ? (
              <div key={i} className="customer__title__details">
                <span className="title">{split_string(detail)}</span>
                <div className="title__details">
                  {sessionUser["is_installer"] ? (
                    <input
                      className="input__field"
                      type="text"
                      defaultValue={
                        customerJob[detail] ? customerJob[detail] : ""
                      }
                      placeholder="---"
                      onChange={(e) => {
                        handleChange(e, detail);
                      }}
                    />
                  ) : (
                    <span>
                      {customerJob[detail] ? customerJob[detail] : "---"}
                    </span>
                  )}
                </div>
              </div>
            ) : null;
          })}
          <span className="title">NMI:</span>
          <div className="nmi__edit">
            <div className="nmi__detail">
              <input
                className="nmi__input"
                type="text"
                defaultValue={customerJob["nmi"] ? customerJob["nmi"] : ""}
                placeholder="---"
                readOnly={readOnlyNmi}
                ref={nmiRef}
                onClick={() => setReadOnlyNmi(false)}
                // onBlur={() => setReadOnlyNmi(true)}
              />
            </div>
            <div className="nmi__button">
              <button className="" data-toggle="tooltip" title="Edit">
                {readOnlyNmi ? (
                  <FaRegEdit
                    onClick={() => changeInputFocus("edit")}
                    className="button__upload__file"
                  />
                ) : (
                  <FaSave
                    onClick={() => changeInputFocus("save")}
                    className="button__upload__file"
                  />
                )}
              </button>
            </div>
          </div>
          {sessionUser["is_installer"] ? (
            <div>
              <div>
                {" "}
                {Object.keys(customerJob).map((detail, i) => {
                  return detail === "system_cost" ||
                    detail === "deposit_amount" ? (
                    <div key={i} className="customer__title__details">
                      <span className="title">{split_string(detail)}</span>
                      <div className="title__details">
                        {sessionUser["is_installer"] ? (
                          <input
                            className="input__field"
                            type="text"
                            defaultValue={
                              customerJob[detail]
                                ? "$" + customerJob[detail]
                                : ""
                            }
                            placeholder="---"
                            onChange={(e) => {
                              handleChange(e, detail);
                            }}
                          />
                        ) : (
                          <span>
                            {customerJob[detail]
                              ? "$" + customerJob[detail]
                              : "---"}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
              <div>
                {" "}
                {Object.keys(customerJob).map((detail, i) => {
                  return detail === "deposit_paid" ||
                    detail === "is_financed" ||
                    detail === "quote_sent" ||
                    detail === "total_amount_paid" ||
                    detail === "is_open" ||
                    detail === "invoice_sent" ? (
                    <div key={i} className="customer__title__details">
                      <span className="title">{split_string(detail)}</span>
                      <div className="title__details">
                        {customerJob[detail] ? (
                          <div>
                            <input
                              className="form-check-input p-2 "
                              type="checkbox"
                              defaultChecked="checked"
                              // onChange={(e) => selectedFile(e, d.id)}
                              onChange={(e) => {
                                handleCheckboxChange(detail, e.target.checked);
                              }}
                            />{" "}
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            ></label>
                          </div>
                        ) : (
                          <div>
                            <input
                              className="form-check-input p-2 "
                              type="checkbox"
                              // id={customerJob[detail]}
                              defaultChecked=""
                              onChange={(e) => {
                                handleCheckboxChange(detail, e.target.checked);
                              }}
                            />{" "}
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            ></label>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <div>Loading content...</div>
        </>
      )}
    </div>
  );
};

export default CustomerProfile;
