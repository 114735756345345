import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./RegisterPage.css";
import { Login, registerCompany } from "../../Services/";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const RegisterPage = () => {
  const location = useLocation();
  // console.log("local", location);
  // console.log(location.state["userType"]);
  const userType = location.state["userType"]; //to check if user is customer or installer
  // const name = userType;
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    company_name: "",
    company_phone: "",
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    company_status: "trial",
  });
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  // Password toggle handler
  const showHidePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  // useEffect(() => {
  //   userRef.current.focus();
  // }, []);
  useEffect(() => {
    seterrorMessage("");
  }, [details.email, details.password]);

  // function validateForm() {
  //   return details.email.length > 0 && details.password.length > 0;
  // }

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    seterrorMessage(null);
    await registerCompany(details).then((res) => {
      if (res["status"] !== 200) {
        console.log(res);
        seterrorMessage(res["result"]["Error"]);
        setIsLoading(false);
      } else {
        console.log(res, "succcess");
        setsuccessMessage("Account created. Check your email for activation.");
        setIsLoading(false);
      }
    });
    setTimeout(() => {
      setsuccessMessage("");
      seterrorMessage("");
    }, 10000);
  };

  return (
    <div className="register__form__main">
      <div className="register__form">
        <form className="app__form__register" onSubmit={submitHandler}>
          <div className="form-group">
            <div className="row">
              {" "}
              <h2>COMPANY REGISTRATION</h2>{" "}
              {errorMessage ? (
                <p className="error__message">{errorMessage}</p>
              ) : (
                <p className="text-success">{successMessage}</p>
              )}
              <div className="col-sm">
                {" "}
                <label htmlFor="firstname">Firstname:</label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  required
                  onChange={(e) =>
                    setDetails({ ...details, first_name: e.target.value })
                  }
                />
              </div>
              <div className="col-sm">
                {" "}
                <label htmlFor="firstname">Lastname:</label>
                <input
                  type="text"
                  name="Lastname"
                  id="Lastname"
                  required
                  onChange={(e) =>
                    setDetails({ ...details, last_name: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                {" "}
                <label htmlFor="firstname">Company Name:</label>
                <input
                  type="text"
                  name="companyname"
                  id="companyname"
                  required
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      company_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                {" "}
                <label htmlFor="firstname">Phone:</label>
                <input
                  type="number"
                  name="Phone"
                  id="Phone"
                  required
                  onChange={(e) =>
                    setDetails({ ...details, company_phone: e.target.value })
                  }
                />
              </div>
            </div>
            <label htmlFor="Email">Email:</label>
            <input
              type="text"
              name="email"
              id="email"
              required
              onChange={(e) =>
                setDetails({
                  ...details,
                  email: e.target.value,
                })
              }
            />
            <label htmlFor="Password">Password:</label>
            <input
              className="wrapper"
              type={passwordShown ? "text" : "password"}
              name="password"
              id="password"
              required
              onChange={(e) =>
                setDetails({ ...details, password: e.target.value })
              }
            />
            <span className="eye__span" onClick={showHidePassword}>
              {passwordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
          </div>
          <div className="form-group">
            <input
              type="Submit"
              name="Register"
              id="submit"
              defaultValue={isLoading ? "Loading..." : "Register"}
            />
          </div>
        </form>

        <div />
      </div>
    </div>
  );
};

export default RegisterPage;
