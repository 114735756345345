import React, { useRef, useState } from "react";
import {
  FaUpload,
  FaRegFile,
  FaSignature,
  FaFileSignature,
} from "react-icons/fa";
import "./ButtonUpload.css";

const ButtonUpload = ({
  setSelected_file,
  selectedFile,
  uploadedFile,
  upoladHandle,
  sendForSignature,
}) => {
  const fileInputRef = useRef();
  //   console.log("fileselect", setSelected_file, upoladHandle);
  const fileSelectHandler = (e) => {
    setSelected_file(e.target.files[0]);
  };
  const upoladHandler = () => {
    upoladHandle();
  };

  return (
    <div className="upload__button">
      <input
        style={{ display: "none" }}
        ref={fileInputRef}
        className="form-control form-control-sm custom-file"
        id="formFileSm"
        type="file"
        defaultValue=""
        onChange={fileSelectHandler}
      />
      <button
        onClick={() => fileInputRef.current.click()}
        data-toggle="tooltip"
        title="Choose file"
      >
        <FaRegFile className="button__upload__file" />
      </button>

      <button onClick={upoladHandler} data-toggle="tooltip" title="Upload file">
        <FaUpload className="button__upload__file" />
      </button>
      <button
        onClick={sendForSignature}
        data-toggle="tooltip"
        title="Send for Singnature"
      >
        <FaSignature className="button__upload__file" />
      </button>
    </div>
  );
};

export default ButtonUpload;
