import React from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { activateCompany, resendActivateCompany } from "../../Services";

const ActivateUserCompany = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const compy = searchParams.get("compy");
  const token = searchParams.get("token");
  const em = searchParams.get("em");

  const activateHandler = async () => {
    const result = await activateCompany(compy, token, em);
    console.log(result.status);
    if (result.status !== 200) {
      seterrorMessage(result.data);
      setsuccessMessage("");
    } else {
      setsuccessMessage(result.data);
      seterrorMessage("");
    }
  };
  const resendHandler = async () => {
    const result = await resendActivateCompany(compy, token, em);
    if (result.status !== 200) {
      seterrorMessage(result.data);
      setsuccessMessage("");
    } else {
      setsuccessMessage(result.data);
      seterrorMessage("");
    }
  };
  return (
    <div
      className="p-2 border m-5"
      style={{
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        height: 350,
        width: 400,
        alignItems: "center",
      }}
    >
      <div
        className="mt-5 d-flex flex-column justify-content-between"
        style={{
          height: 200,
          width: 400,
          alignItems: "center",
        }}
      >
        {" "}
        {errorMessage ? (
          <p className="text-danger">{errorMessage}</p>
        ) : (
          <p className="text-success">{successMessage}</p>
        )}
        <button className="btn btn-primary" onClick={activateHandler}>
          Activate
        </button>
        <button className="btn btn-dark" onClick={resendHandler}>
          Request Activation Link
        </button>
      </div>
    </div>
  );
};

export default ActivateUserCompany;
