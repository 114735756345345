import Navbar from "../Navbar/Navbar";
import classes from "./Layout.module.scss";
import { useEffect, useState } from "react";

const Layout = ({ children }) => {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={classes.main__layout}>
      <Navbar window={window} />

      <div className={classes.container}>{children}</div>
    </div>
  );
};

export default Layout;
