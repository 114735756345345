import React from "react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Viewer, Worker, ProgressBar } from "@react-pdf-viewer/core";
import { checkDocSigned } from "../../Services";
import { Base64 } from "../../Services/Base64Decode";
import ModalSignaturePad from "./ModalSignaturePad";
import { DEVELOPMENT } from "../../Constants";

const SignDocumentFromLink = () => {
  let herokucors;
  if (DEVELOPMENT) {
    herokucors = "https://cors-anywhere.herokuapp.com/";
  } else {
    herokucors = "";
  }
  const [searchParams] = useSearchParams();
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [isAlreadySigned, setIsAlreadySigned] = useState(false);
  const [openSignaturePad, setOpenSignaturePad] = useState(false);

  const compy = searchParams.get("compy");
  const token = searchParams.get("token");
  const em = searchParams.get("em");
  const file = searchParams.get("elif");
  const file_type_code = searchParams.get("epytelif");
  const customer_file_id_code = searchParams.get("dielif");

  const file_container_link = Base64.decode(file);
  const checkDocumentSigned = async () => {
    // console.log(data);
    const result = await checkDocSigned({
      company_id: compy,
      token: token,
      email: em,
      file: file,
      customer_file_id_code: customer_file_id_code,
    });
    if (result.status !== 200) {
      if (result.status === 202) {
        setIsAlreadySigned(true);
      }
      seterrorMessage(result.data);
      setsuccessMessage("");
    } else {
      setsuccessMessage(result.data);
      seterrorMessage("");
    }
    return result.status;
  };
  useEffect(() => {
    //check documents already signed.
    checkDocumentSigned();
  }, []);
  return (
    <>
      <ModalSignaturePad
        openModal={openSignaturePad}
        onHide={() => setOpenSignaturePad(false)}
        backdrop="static"
        keyboard={false}
        file_container_link={file_container_link}
        file_type_code={file_type_code}
        customer_file_id_code={customer_file_id_code}
        checkDocumentSigned={checkDocumentSigned}
      />
      <div className="border" style={{ width: "100%", textAlign: "center" }}>
        {" "}
        {errorMessage ? (
          <p className="text-danger">{errorMessage}</p>
        ) : (
          <p className="text-success">{successMessage}</p>
        )}
        {isAlreadySigned ? (
          <div>This document is already signed.</div>
        ) : (
          <div className="" style={{ width: "100%", textAlign: "center" }}>
            File preview
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js"></Worker>
            <Viewer
              style={{ width: "500px" }}
              fileUrl={herokucors + file_container_link}
              renderLoader={(percentages) => (
                <div style={{ width: "500px" }}>
                  <ProgressBar progress={Math.round(percentages)} />
                </div>
              )}
            />
            <button
              onClick={() => {
                setOpenSignaturePad(true);
              }}
              className="btn btn-primary m-3 w-50"
            >
              Sign Document
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SignDocumentFromLink;
